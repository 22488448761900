import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import classNames from 'classnames';

import styles from './portal-select.module.css';

export type Option = {
  label: string;
  value: string;
  disable?: boolean;
};

export enum SelectSize {
  sm = 'sm',
  md = 'md',
}

export enum SelectStyle {
  default = 'default',
  bordered = 'bordered',
  ghost = 'ghost',
  primary = 'primary',
  secondary = 'secondary',
  accent = 'accent',
  info = 'info',
  success = 'success',
  warning = 'warning',
}
type RestMuiSelectProps = Omit<
  SelectProps,
  'value' | 'placeholder' | 'label' | 'onChange' | 'size' | 'variant'
>;
export type PortalSelectProps<T extends Option> = {
  options: T[];
  value: string;
  styleVariant?: keyof typeof SelectStyle;
  placeholder?: string;
  label?: string;
  errorMessage?: string;
  helperText?: string;
  onChange?: (value: T) => void;
  noOptionsText?: string;
  size?: keyof typeof SelectSize;
  paperClassName?: string;
  'data-testid'?: string;
} & RestMuiSelectProps;

export function PortalSelect<T extends Option>({
  styleVariant = 'bordered',
  placeholder = '',
  options = [],
  label,
  value,
  disabled = false,
  required = false,
  error = false,
  errorMessage,
  noOptionsText = '',
  helperText,
  onChange = () => {},
  className = '',
  size,
  'data-testid': dataTestId,
  paperClassName = '',
  ...restMuiProps
}: PortalSelectProps<T>) {
  const classesStyle = classNames({
    [styles['overwrite-default']]: styleVariant === SelectStyle.default,
    [styles['overwrite-bordered']]: styleVariant === SelectStyle.bordered,
    [styles['overwrite-ghost']]: styleVariant === SelectStyle.ghost,
    [styles['overwrite-primary']]: styleVariant === SelectStyle.primary,
    [styles['overwrite-secondary']]: styleVariant === SelectStyle.secondary,
    [styles['overwrite-info']]: styleVariant === SelectStyle.info,
    [styles['overwrite-accent']]: styleVariant === SelectStyle.accent,
    [styles['overwrite-success']]: styleVariant === SelectStyle.success,
    [styles['overwrite-warning']]: styleVariant === SelectStyle.warning,
  });

  const classesBase = classNames(
    styles['overwrite'],
    size === undefined
      ? styles['size-auto']
      : size === 'sm'
        ? styles['size-sm']
        : styles['size-md']
  );

  return (
    <section
      className={classNames(
        classesBase,
        'p-1',
        {
          [styles['overwrite-disabled']]: disabled,
          [styles['overwrite-error']]: error,
          [classesStyle]: !disabled && !error,
        },
        className
      )}
    >
      {Boolean(label) && (
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          {label}
          <span
            className={classNames(
              'text-vs-error-focus',
              required ? 'visible' : 'invisible'
            )}
          >
            *
          </span>
        </InputLabel>
      )}

      <Select
        {...restMuiProps}
        value={options.length > 0 && value ? value : ''}
        onChange={event => {
          onChange(options.find(o => o.value === event.target.value) as T);
        }}
        disabled={disabled}
        error={error}
        data-testid={dataTestId}
        IconComponent={props => {
          return props.className.includes('MuiSelect-iconOpen') ? (
            <ExpandLessIcon {...props} />
          ) : (
            <ExpandMoreIcon {...props} />
          );
        }}
        displayEmpty={Boolean(placeholder)}
        renderValue={selected =>
          options.find(option => option.value === selected)?.label || (
            <span>{placeholder}</span>
          )
        }
        autoFocus={false}
        MenuProps={{
          slotProps: {
            paper: {
              className: classNames(styles['overwrite-paper'], paperClassName),
            },
          },
        }}
      >
        {Boolean(noOptionsText) && (
          <MenuItem disabled value="default">
            {noOptionsText}
          </MenuItem>
        )}
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            data-testid={
              dataTestId ? `${dataTestId}-item-name-${index}` : undefined
            }
            disabled={option.disable}
            className="vs-body-xs-regular sm:vs-body-sm-regular whitespace-pre-wrap mb-2"
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>

      {error
        ? errorMessage && (
            <FormHelperText className="text-vs-error-focus">
              {errorMessage}
            </FormHelperText>
          )
        : helperText && (
            <FormHelperText className="text-vs-base-content-variant">
              {helperText}
            </FormHelperText>
          )}
    </section>
  );
}

export default PortalSelect;
