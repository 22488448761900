import { withSentryReactRouterV6Routing } from '@vs/sentry-react-router-integration';
import { PortalBackdrop } from '@vs/ui/portal/backdrop';
import { Suspense, memo, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { getPathConfigs } from '../../constants/pathConfigs';
import { useAppSelector } from '../../redux/hooks';

function AppContentRoutes() {
  const location = useLocation();
  const selectedManagement = useAppSelector(s => s.global.selectedManagement);
  const managementPaths = getPathConfigs(selectedManagement, {
    flat: true,
  });
  const defaultFallbackPath = managementPaths?.find(p => !p.hide);

  const SentryRoutes = useMemo(
    () => withSentryReactRouterV6Routing(Routes),
    []
  );

  return (
    <Suspense fallback={<PortalBackdrop open type="module_init" />}>
      <SentryRoutes>
        {managementPaths?.map(({ name, path, View }) => {
          if (!View) return null;
          return (
            <Route
              key={`/${selectedManagement}${path}${name}`}
              path={`/${selectedManagement}${path}`}
              element={<View />}
            />
          );
        })}
        {defaultFallbackPath !== undefined && (
          <Route
            path="*"
            element={
              <Navigate
                to={{
                  pathname: `/${selectedManagement}${defaultFallbackPath?.path}`,
                  search: location.search,
                }}
              />
            }
          />
        )}
      </SentryRoutes>
    </Suspense>
  );
}

export default memo(AppContentRoutes);
