import { PortalBackdrop } from '@vs/ui/portal/backdrop';
import { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AppContent from '.';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  ManagementTypeKeys,
  ManagementTypes,
  setSelectedManagement,
} from '../../redux/slices/global';

function AppContentInit() {
  const selectedManagement = useAppSelector(s => s.global.selectedManagement);

  useInitManagementType();

  if (selectedManagement === 'none') {
    return <PortalBackdrop open type="app_init" />;
  }
  return <AppContent />;
}

export default memo(AppContentInit);

function useInitManagementType() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const selectedManagement = useAppSelector(s => s.global.selectedManagement);

  useEffect(() => {
    if (selectedManagement === 'none') {
      const type = location.pathname.split('/').filter(Boolean)[0];
      dispatch(
        setSelectedManagement(
          (Object.values(ManagementTypeKeys).find(key => key === type)
            ? type
            : 'core') as ManagementTypes
        )
      );
    }
  }, [selectedManagement, location.pathname, dispatch]);
}
