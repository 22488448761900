import { OidcClientSettings } from 'oidc-client-ts';

import { env } from './env';

export const oidcConfig: OidcClientSettings = {
  post_logout_redirect_uri: `${window.location.origin}/oidc/logout`,
  redirect_uri: `${window.location.origin}/oidc/callback`,
  response_type: 'code',
  scope: 'openid profile email',
  client_id: '7d316658-9071-4314-8866-5853eb118521',
  authority: env.oidcAuthority.vsAdmin,
};
