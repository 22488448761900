import { api as dmAdminApiInjected } from './injectedApi';

export enum DmAdminSliceTags {
  DEVICE_MODEL = 'DEVICE_MODEL',
  CAPABILITY_KEY = 'CAPABILITY_KEY',
  CAPABILITY_VERSION = 'CAPABILITY_VERSION',
  DEVICE_CAPABILITY = 'DEVICE_CAPABILITY',
}

const enhancedRtkApi = dmAdminApiInjected.enhanceEndpoints({
  addTagTypes: Object.values(DmAdminSliceTags),
  endpoints: {
    DeviceModels: {
      providesTags: [DmAdminSliceTags.DEVICE_MODEL],
    },
    CustomDeviceModels: {
      providesTags: [DmAdminSliceTags.DEVICE_MODEL],
    },
    DeleteDeviceModel: {
      invalidatesTags: [DmAdminSliceTags.DEVICE_MODEL],
    },
    CreateDeviceModel: {
      invalidatesTags: [DmAdminSliceTags.DEVICE_MODEL],
    },
    UpdateDeviceModel: {
      invalidatesTags: [DmAdminSliceTags.DEVICE_MODEL],
    },
    CustomCapabilityKeys: {
      providesTags: [DmAdminSliceTags.CAPABILITY_KEY],
    },
    CustomCreateCapabilityKey: {
      invalidatesTags: [
        DmAdminSliceTags.CAPABILITY_KEY,
        DmAdminSliceTags.DEVICE_CAPABILITY,
      ],
    },
    UpdateCapabilityKey: {
      invalidatesTags: [
        DmAdminSliceTags.CAPABILITY_KEY,
        DmAdminSliceTags.DEVICE_CAPABILITY,
      ],
    },
    DeleteCapabilityKey: {
      invalidatesTags: [
        DmAdminSliceTags.CAPABILITY_KEY,
        DmAdminSliceTags.DEVICE_CAPABILITY,
      ],
    },
    CapabilityVersions: {
      providesTags: [DmAdminSliceTags.CAPABILITY_VERSION],
    },
    CustomCapabilityVersions: {
      providesTags: [DmAdminSliceTags.CAPABILITY_VERSION],
    },
    CreateCapabilityVersion: {
      invalidatesTags: [DmAdminSliceTags.CAPABILITY_VERSION],
    },
    DeleteCapabilityVersion: {
      invalidatesTags: [DmAdminSliceTags.CAPABILITY_VERSION],
    },
    CustomDeviceCapabilities: {
      providesTags: [DmAdminSliceTags.DEVICE_CAPABILITY],
    },
    CreateDeviceCapability: {
      invalidatesTags: [DmAdminSliceTags.DEVICE_CAPABILITY],
    },
    UpdateDeviceCapability: {
      invalidatesTags: [DmAdminSliceTags.DEVICE_CAPABILITY],
    },
    DeleteDeviceCapability: {
      invalidatesTags: [DmAdminSliceTags.DEVICE_CAPABILITY],
    },
  },
});

export { enhancedRtkApi as dmGraphqlApiSlice };
export * from './injectedApi';
