import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ArgsInitSliceMeta,
  ReferenceOfMeta,
  dynamicFetchBaseFactory,
} from '@vs/rtk/utils';
import { getEnvConfig } from '@vs/utils/envConfig';

const referenceOfMeta: ReferenceOfMeta = {
  _deployEnv: undefined,
  _baseUrl: undefined,
  _getTokenFunc: undefined,
};

export function initSliceMeta({ deployEnv, getTokenFunc }: ArgsInitSliceMeta) {
  referenceOfMeta._deployEnv = deployEnv;
  referenceOfMeta._baseUrl = getEnvConfig({ deployEnv }).apiEndpoint.vs;
  referenceOfMeta._getTokenFunc = getTokenFunc;
}

export const vsEntityApiBase = createApi({
  reducerPath: 'vsEntityGenApi',
  keepUnusedDataFor: 60,
  refetchOnMountOrArgChange: 60,
  baseQuery: dynamicFetchBaseFactory(referenceOfMeta, {
    credentials: 'include',
  }),
  endpoints: () => ({}),
});
