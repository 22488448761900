import { MenuItemInterface } from '@vs/ui/portal/menu';
import { useLocation } from 'react-router-dom';

import { Path, getPathConfigs } from '../../constants/pathConfigs';
import useAppNavigate from '../../hooks/useAppNavigate';
import { useAppSelector } from '../../redux/hooks';

export function useMenuItems(): MenuItemInterface[] {
  const selectedManagement = useAppSelector(s => s.global.selectedManagement);
  const navigate = useAppNavigate();
  const location = useLocation();
  const activePaths = getPathConfigs(selectedManagement);

  const getMenuItems = (
    paths: Path[],
    parentPath: Path['path']
  ): MenuItemInterface[] => {
    const menuItems = paths.map(
      ({ path, onClick, View, selected, childViews, ...rest }) => {
        const pathForNavigation = `${parentPath}${path}`;

        return {
          onClick: () => {
            if (!View && childViews?.length) {
              navigate(`${pathForNavigation}${childViews[0].path}`);
              return;
            }

            navigate(pathForNavigation);
          },
          selected: location.pathname.includes(pathForNavigation),
          childViews: childViews
            ? getMenuItems(childViews, pathForNavigation)
            : [],
          ...rest,
        };
      }
    );

    return menuItems;
  };

  const menuItemList: MenuItemInterface[] = getMenuItems(activePaths, '');

  return menuItemList;
}
