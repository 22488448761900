import { vsAdminApiBase as api } from './vsAdminApiBase';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    accountControllerUserPermissionInfo: build.query<
      AccountControllerUserPermissionInfoApiResponse,
      AccountControllerUserPermissionInfoApiArg
    >({
      query: () => ({ url: `/admin/api/v1/admin/account/permissionInfo` }),
    }),
    accountControllerCreate: build.mutation<
      AccountControllerCreateApiResponse,
      AccountControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/account`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    accountControllerGetAll: build.query<
      AccountControllerGetAllApiResponse,
      AccountControllerGetAllApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/account`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          email: queryArg.email,
        },
      }),
    }),
    accountControllerDelete: build.mutation<
      AccountControllerDeleteApiResponse,
      AccountControllerDeleteApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/account`,
        method: 'DELETE',
        body: queryArg.body,
      }),
    }),
    permissionActionControllerFindAll: build.query<
      PermissionActionControllerFindAllApiResponse,
      PermissionActionControllerFindAllApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/permission/action`,
        params: { cur_page: queryArg.curPage, page_size: queryArg.pageSize },
      }),
    }),
    permissionActionControllerCreate: build.mutation<
      PermissionActionControllerCreateApiResponse,
      PermissionActionControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/permission/action`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    permissionActionControllerDelete: build.mutation<
      PermissionActionControllerDeleteApiResponse,
      PermissionActionControllerDeleteApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/permission/action`,
        method: 'DELETE',
        body: queryArg.deletePermissionActionDto,
      }),
    }),
    permissionResourceControllerFindAll: build.query<
      PermissionResourceControllerFindAllApiResponse,
      PermissionResourceControllerFindAllApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/permission/resource`,
        params: { cur_page: queryArg.curPage, page_size: queryArg.pageSize },
      }),
    }),
    permissionResourceControllerCreate: build.mutation<
      PermissionResourceControllerCreateApiResponse,
      PermissionResourceControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/permission/resource`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    permissionResourceControllerDelete: build.mutation<
      PermissionResourceControllerDeleteApiResponse,
      PermissionResourceControllerDeleteApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/permission/resource`,
        method: 'DELETE',
        body: queryArg.deletePermissionResourceDto,
      }),
    }),
    accountPermissionControllerFindOne: build.query<
      AccountPermissionControllerFindOneApiResponse,
      AccountPermissionControllerFindOneApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/account/permission/${queryArg.accountId}`,
      }),
    }),
    accountPermissionControllerCreate: build.mutation<
      AccountPermissionControllerCreateApiResponse,
      AccountPermissionControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/account/permission/${queryArg.accountId}`,
        method: 'POST',
        body: queryArg.createAccountPermissionDto,
      }),
    }),
    accountPermissionControllerUpdate: build.mutation<
      AccountPermissionControllerUpdateApiResponse,
      AccountPermissionControllerUpdateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/account/permission/${queryArg.accountId}`,
        method: 'DELETE',
        body: queryArg.updateAccountPermissionDto,
      }),
    }),
    accountPermissionControllerDelete: build.mutation<
      AccountPermissionControllerDeleteApiResponse,
      AccountPermissionControllerDeleteApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/account/permission`,
        method: 'DELETE',
        body: queryArg.deleteAccountPermissionDto,
      }),
    }),
    serviceServiceControllerFindAll: build.query<
      ServiceServiceControllerFindAllApiResponse,
      ServiceServiceControllerFindAllApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/permission/service`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          code: queryArg.code,
        },
      }),
    }),
    serviceServiceControllerCreate: build.mutation<
      ServiceServiceControllerCreateApiResponse,
      ServiceServiceControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/permission/service`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    serviceServiceControllerDelete: build.mutation<
      ServiceServiceControllerDeleteApiResponse,
      ServiceServiceControllerDeleteApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/permission/service`,
        method: 'DELETE',
        body: queryArg.deleteServiceDto,
      }),
    }),
    permissionControllerFindAll: build.query<
      PermissionControllerFindAllApiResponse,
      PermissionControllerFindAllApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/permission`,
        params: { type: queryArg['type'], code: queryArg.code },
      }),
    }),
    permissionControllerCreate: build.mutation<
      PermissionControllerCreateApiResponse,
      PermissionControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/permission`,
        method: 'POST',
        body: queryArg.createPermissionDto,
      }),
    }),
    permissionControllerDelete: build.mutation<
      PermissionControllerDeleteApiResponse,
      PermissionControllerDeleteApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/permission/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    clientPermissionControllerFindAll: build.query<
      ClientPermissionControllerFindAllApiResponse,
      ClientPermissionControllerFindAllApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/client/permission`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          name: queryArg.name,
          client_id: queryArg.clientId,
        },
      }),
    }),
    clientPermissionControllerCreate: build.mutation<
      ClientPermissionControllerCreateApiResponse,
      ClientPermissionControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/client/permission`,
        method: 'POST',
        body: queryArg.createClientCredentialDto,
      }),
    }),
    clientPermissionControllerDelete: build.mutation<
      ClientPermissionControllerDeleteApiResponse,
      ClientPermissionControllerDeleteApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/client/permission`,
        method: 'DELETE',
        body: queryArg.deleteClientCredentialDto,
      }),
    }),
    clientPermissionControllerUpdate: build.mutation<
      ClientPermissionControllerUpdateApiResponse,
      ClientPermissionControllerUpdateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/client/permission/${queryArg.clientPermissionId}`,
        method: 'PATCH',
        body: queryArg.clientCredentialDto,
      }),
    }),
    mailTemplateControllerCreate: build.mutation<
      MailTemplateControllerCreateApiResponse,
      MailTemplateControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template`,
        method: 'POST',
        body: queryArg.createMailTemplateDto,
      }),
    }),
    mailTemplateControllerFindAll: build.query<
      MailTemplateControllerFindAllApiResponse,
      MailTemplateControllerFindAllApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          language_code: queryArg.languageCode,
          global_search: queryArg.globalSearch,
        },
      }),
    }),
    mailTemplateControllerBulkUpdate: build.mutation<
      MailTemplateControllerBulkUpdateApiResponse,
      MailTemplateControllerBulkUpdateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    mailTemplateControllerBulkDelete: build.mutation<
      MailTemplateControllerBulkDeleteApiResponse,
      MailTemplateControllerBulkDeleteApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template`,
        method: 'DELETE',
        body: queryArg.mailTemplateCodeBaseDto,
      }),
    }),
    mailTemplateControllerFindOne: build.query<
      MailTemplateControllerFindOneApiResponse,
      MailTemplateControllerFindOneApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/${queryArg.templateCode}`,
        params: {
          language_code: queryArg.languageCode,
          is_hidden: queryArg.isHidden,
        },
      }),
    }),
    mailTemplateControllerUpdate: build.mutation<
      MailTemplateControllerUpdateApiResponse,
      MailTemplateControllerUpdateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/${queryArg.templateCode}`,
        method: 'PATCH',
        body: queryArg.updateMailTemplateDto,
        params: { language_code: queryArg.languageCode },
      }),
    }),
    mailTemplateControllerDelete: build.mutation<
      MailTemplateControllerDeleteApiResponse,
      MailTemplateControllerDeleteApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/${queryArg.templateCode}`,
        method: 'DELETE',
        params: { language_code: queryArg.languageCode },
      }),
    }),
    mailTemplateControllerReset: build.mutation<
      MailTemplateControllerResetApiResponse,
      MailTemplateControllerResetApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/${queryArg.templateCode}/reset`,
        method: 'POST',
        params: { language_code: queryArg.languageCode },
      }),
    }),
    mailTemplateControllerArchived: build.mutation<
      MailTemplateControllerArchivedApiResponse,
      MailTemplateControllerArchivedApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/${queryArg.templateCode}/archived`,
        method: 'PATCH',
        params: { language_code: queryArg.languageCode },
      }),
    }),
    mailTemplateControllerRestored: build.mutation<
      MailTemplateControllerRestoredApiResponse,
      MailTemplateControllerRestoredApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/${queryArg.templateCode}/restored`,
        method: 'PATCH',
        params: { language_code: queryArg.languageCode },
      }),
    }),
    mailTemplateControllerPublished: build.mutation<
      MailTemplateControllerPublishedApiResponse,
      MailTemplateControllerPublishedApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/${queryArg.templateCode}/publish`,
        method: 'PATCH',
        params: { language_code: queryArg.languageCode },
      }),
    }),
    mailTemplateControllerUnpublish: build.mutation<
      MailTemplateControllerUnpublishApiResponse,
      MailTemplateControllerUnpublishApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/${queryArg.templateCode}/unpublish`,
        method: 'PATCH',
        params: { language_code: queryArg.languageCode },
      }),
    }),
    mailTemplateControllerSendEmail: build.mutation<
      MailTemplateControllerSendEmailApiResponse,
      MailTemplateControllerSendEmailApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/${queryArg.templateCode}/testing`,
        method: 'POST',
        body: queryArg.testingEmailDto,
        params: { language_code: queryArg.languageCode },
      }),
    }),
    mailTemplateControllerSyncTrigger: build.mutation<
      MailTemplateControllerSyncTriggerApiResponse,
      MailTemplateControllerSyncTriggerApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/sync-trigger`,
        method: 'POST',
        body: queryArg.mailTemplateCodesDto,
      }),
    }),
    mailTemplateComponentControllerCreate: build.mutation<
      MailTemplateComponentControllerCreateApiResponse,
      MailTemplateComponentControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/components`,
        method: 'POST',
        body: queryArg.createMailTemplateComponentDto,
        params: { language_code: queryArg.languageCode },
      }),
    }),
    mailTemplateComponentControllerFindAll: build.query<
      MailTemplateComponentControllerFindAllApiResponse,
      MailTemplateComponentControllerFindAllApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/components`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          language_code: queryArg.languageCode,
          type: queryArg['type'],
          name: queryArg.name,
        },
      }),
    }),
    mailTemplateComponentControllerFindOne: build.query<
      MailTemplateComponentControllerFindOneApiResponse,
      MailTemplateComponentControllerFindOneApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/components/${queryArg.id}`,
      }),
    }),
    mailTemplateComponentControllerUpdate: build.mutation<
      MailTemplateComponentControllerUpdateApiResponse,
      MailTemplateComponentControllerUpdateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/components/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateMailTemplateComponentDto,
      }),
    }),
    mailTemplateComponentControllerDelete: build.mutation<
      MailTemplateComponentControllerDeleteApiResponse,
      MailTemplateComponentControllerDeleteApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/components/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    mailTemplateComponentControllerInspectRelation: build.query<
      MailTemplateComponentControllerInspectRelationApiResponse,
      MailTemplateComponentControllerInspectRelationApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/components/${queryArg.id}/inspect-relation`,
      }),
    }),
    fileUploadControllerCreateFiles: build.mutation<
      FileUploadControllerCreateFilesApiResponse,
      FileUploadControllerCreateFilesApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/file-upload/${queryArg.templateCode}`,
        method: 'POST',
        body: queryArg.createFileDto,
        params: {
          folder: queryArg.folder,
          language_code: queryArg.languageCode,
        },
      }),
    }),
    fileUploadControllerGetFiles: build.query<
      FileUploadControllerGetFilesApiResponse,
      FileUploadControllerGetFilesApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/file-upload/${queryArg.templateCode}`,
        params: {
          folder: queryArg.folder,
          language_code: queryArg.languageCode,
        },
      }),
    }),
    fileUploadControllerDeleteFile: build.mutation<
      FileUploadControllerDeleteFileApiResponse,
      FileUploadControllerDeleteFileApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/file-upload/${queryArg.templateCode}`,
        method: 'DELETE',
        body: queryArg.deleteFileDto,
        params: {
          folder: queryArg.folder,
          language_code: queryArg.languageCode,
        },
      }),
    }),
    imageUploadControllerCreateFiles: build.mutation<
      ImageUploadControllerCreateFilesApiResponse,
      ImageUploadControllerCreateFilesApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/image`,
        method: 'POST',
        body: queryArg.createImageDto,
      }),
    }),
    imageUploadControllerGetFiles: build.query<
      ImageUploadControllerGetFilesApiResponse,
      ImageUploadControllerGetFilesApiArg
    >({
      query: () => ({ url: `/admin/api/v1/admin/mail-template/image` }),
    }),
    imageUploadControllerDeleteFile: build.mutation<
      ImageUploadControllerDeleteFileApiResponse,
      ImageUploadControllerDeleteFileApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/image`,
        method: 'DELETE',
        body: queryArg.deleteFileDto,
      }),
    }),
    sqsMailerControllerFindAllRegionOfLambda: build.query<
      SqsMailerControllerFindAllRegionOfLambdaApiResponse,
      SqsMailerControllerFindAllRegionOfLambdaApiArg
    >({
      query: () => ({ url: `/admin/api/v1/admin/mailer/region` }),
    }),
    sqsMailerControllerInspectAndGetFunctionName: build.query<
      SqsMailerControllerInspectAndGetFunctionNameApiResponse,
      SqsMailerControllerInspectAndGetFunctionNameApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mailer/lambda/list`,
        params: { region: queryArg.region },
      }),
    }),
    sqsMailerControllerFindSqsEventSourceMapping: build.query<
      SqsMailerControllerFindSqsEventSourceMappingApiResponse,
      SqsMailerControllerFindSqsEventSourceMappingApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mailer/binding/list`,
        params: { region: queryArg.region },
      }),
    }),
    sqsMailerControllerCreateEventSourceMapping: build.mutation<
      SqsMailerControllerCreateEventSourceMappingApiResponse,
      SqsMailerControllerCreateEventSourceMappingApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mailer/binding`,
        method: 'POST',
        body: queryArg.createSqsLambdaMappingDto,
      }),
    }),
    sqsMailerControllerListEventSourceMapping: build.query<
      SqsMailerControllerListEventSourceMappingApiResponse,
      SqsMailerControllerListEventSourceMappingApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mailer/binding`,
        params: {
          region: queryArg.region,
          function_name: queryArg.functionName,
        },
      }),
    }),
    sqsMailerControllerUpdateEventSourceMapping: build.mutation<
      SqsMailerControllerUpdateEventSourceMappingApiResponse,
      SqsMailerControllerUpdateEventSourceMappingApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mailer/binding`,
        method: 'PATCH',
        body: queryArg.updateEventSourceMappingDto,
        params: {
          region: queryArg.region,
          function_name: queryArg.functionName,
        },
      }),
    }),
    sqsMailerControllerDeleteEventSourceMapping: build.mutation<
      SqsMailerControllerDeleteEventSourceMappingApiResponse,
      SqsMailerControllerDeleteEventSourceMappingApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mailer/binding/${queryArg.uuid}`,
        method: 'DELETE',
        params: { region: queryArg.region },
      }),
    }),
    mailRecordControllerList: build.query<
      MailRecordControllerListApiResponse,
      MailRecordControllerListApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-system/record`,
        params: {
          status: queryArg.status,
          page_size: queryArg.pageSize,
          next_page_key: queryArg.nextPageKey,
          recipient_email: queryArg.recipientEmail,
          template_code: queryArg.templateCode,
          attempt_status: queryArg.attemptStatus,
          created_start_time: queryArg.createdStartTime,
          created_end_time: queryArg.createdEndTime,
        },
      }),
    }),
    mailRecordControllerResend: build.mutation<
      MailRecordControllerResendApiResponse,
      MailRecordControllerResendApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-system/resend/${queryArg.queueMessageId}`,
        method: 'POST',
      }),
    }),
    mailBlockControllerFindAll: build.query<
      MailBlockControllerFindAllApiResponse,
      MailBlockControllerFindAllApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-blocklist`,
        params: {
          page_size: queryArg.pageSize,
          next_page_key: queryArg.nextPageKey,
          email: queryArg.email,
        },
      }),
    }),
    mailBlockControllerBlock: build.mutation<
      MailBlockControllerBlockApiResponse,
      MailBlockControllerBlockApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-blocklist/block/${queryArg.email}`,
        method: 'POST',
      }),
    }),
    mailBlockControllerUnblock: build.mutation<
      MailBlockControllerUnblockApiResponse,
      MailBlockControllerUnblockApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-blocklist/unblock/${queryArg.email}`,
        method: 'DELETE',
      }),
    }),
    mailTemplateContributorControllerManage: build.mutation<
      MailTemplateContributorControllerManageApiResponse,
      MailTemplateContributorControllerManageApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/mail-template/contributor`,
        method: 'POST',
        body: queryArg.manageMailTemplateContributorDto,
      }),
    }),
    mailTemplateContributorControllerFindAll: build.query<
      MailTemplateContributorControllerFindAllApiResponse,
      MailTemplateContributorControllerFindAllApiArg
    >({
      query: () => ({ url: `/admin/api/v1/admin/mail-template/contributor` }),
    }),
    messageTemplateControllerGetMessageTemplates: build.query<
      MessageTemplateControllerGetMessageTemplatesApiResponse,
      MessageTemplateControllerGetMessageTemplatesApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/message/templates`,
        params: { cur_page: queryArg.curPage, page_size: queryArg.pageSize },
      }),
    }),
    messageTemplateControllerGetMessageTemplatesByCode: build.query<
      MessageTemplateControllerGetMessageTemplatesByCodeApiResponse,
      MessageTemplateControllerGetMessageTemplatesByCodeApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/message/templates/${queryArg.templateCode}`,
      }),
    }),
    messageTemplateControllerCreateMessageTemplate: build.mutation<
      MessageTemplateControllerCreateMessageTemplateApiResponse,
      MessageTemplateControllerCreateMessageTemplateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/message/templates/${queryArg.templateCode}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    messageTemplateControllerUpdateMessageTemplate: build.mutation<
      MessageTemplateControllerUpdateMessageTemplateApiResponse,
      MessageTemplateControllerUpdateMessageTemplateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/message/templates/${queryArg.templateCode}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    messageTemplateControllerDeleteMessageTemplate: build.mutation<
      MessageTemplateControllerDeleteMessageTemplateApiResponse,
      MessageTemplateControllerDeleteMessageTemplateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/message/templates/${queryArg.templateCode}`,
        method: 'DELETE',
      }),
    }),
    messageHistoryControllerGetMessageHistories: build.query<
      MessageHistoryControllerGetMessageHistoriesApiResponse,
      MessageHistoryControllerGetMessageHistoriesApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/message/history`,
        params: { cur_page: queryArg.curPage, page_size: queryArg.pageSize },
      }),
    }),
    messageHistoryControllerGetMessageHistory: build.query<
      MessageHistoryControllerGetMessageHistoryApiResponse,
      MessageHistoryControllerGetMessageHistoryApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/message/history/${queryArg.historyId}`,
      }),
    }),
    messageHistoryControllerSendMessage: build.mutation<
      MessageHistoryControllerSendMessageApiResponse,
      MessageHistoryControllerSendMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/message/history/${queryArg.historyId}`,
        method: 'POST',
      }),
    }),
    messageHistoryControllerUpdateMessageHistory: build.mutation<
      MessageHistoryControllerUpdateMessageHistoryApiResponse,
      MessageHistoryControllerUpdateMessageHistoryApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/message/history/${queryArg.historyId}`,
        method: 'PATCH',
        body: queryArg.messageInput,
      }),
    }),
    messageHistoryControllerDeleteMessage: build.mutation<
      MessageHistoryControllerDeleteMessageApiResponse,
      MessageHistoryControllerDeleteMessageApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/message/history/${queryArg.historyId}`,
        method: 'DELETE',
      }),
    }),
    appClientControllerRegister: build.mutation<
      AppClientControllerRegisterApiResponse,
      AppClientControllerRegisterApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/app-clients`,
        method: 'POST',
        body: queryArg.createAppClient,
      }),
    }),
    appClientControllerGetClients: build.query<
      AppClientControllerGetClientsApiResponse,
      AppClientControllerGetClientsApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/app-clients`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          filter: queryArg.filter,
          client_id: queryArg.clientId,
        },
      }),
    }),
    appClientControllerGetClientDetails: build.query<
      AppClientControllerGetClientDetailsApiResponse,
      AppClientControllerGetClientDetailsApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/app-clients/${queryArg.clientId}`,
      }),
    }),
    appClientControllerUpdateClient: build.mutation<
      AppClientControllerUpdateClientApiResponse,
      AppClientControllerUpdateClientApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/app-clients/${queryArg.clientId}`,
        method: 'PATCH',
        body: queryArg.updateAppClient,
      }),
    }),
    appClientControllerDeleteClient: build.mutation<
      AppClientControllerDeleteClientApiResponse,
      AppClientControllerDeleteClientApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/app-clients/${queryArg.clientId}`,
        method: 'DELETE',
      }),
    }),
    adminClientControllerRegister: build.mutation<
      AdminClientControllerRegisterApiResponse,
      AdminClientControllerRegisterApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/admin-clients`,
        method: 'POST',
        body: queryArg.createAdminClientDto,
      }),
    }),
    adminClientControllerGetClients: build.query<
      AdminClientControllerGetClientsApiResponse,
      AdminClientControllerGetClientsApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/admin-clients`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          filter: queryArg.filter,
          client_id: queryArg.clientId,
        },
      }),
    }),
    adminClientControllerCreateServiceAccount: build.mutation<
      AdminClientControllerCreateServiceAccountApiResponse,
      AdminClientControllerCreateServiceAccountApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/admin-clients/service-account`,
        method: 'POST',
        body: queryArg.createAdminClientServiceAccountDto,
      }),
    }),
    adminClientControllerGetClientDetails: build.query<
      AdminClientControllerGetClientDetailsApiResponse,
      AdminClientControllerGetClientDetailsApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/admin-clients/${queryArg.clientId}`,
      }),
    }),
    adminClientControllerUpdateClient: build.mutation<
      AdminClientControllerUpdateClientApiResponse,
      AdminClientControllerUpdateClientApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/admin-clients/${queryArg.clientId}`,
        method: 'PATCH',
        body: queryArg.updateAdminClientDto,
      }),
    }),
    adminClientControllerDeleteClient: build.mutation<
      AdminClientControllerDeleteClientApiResponse,
      AdminClientControllerDeleteClientApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/admin-clients/${queryArg.clientId}`,
        method: 'DELETE',
      }),
    }),
    federationSettingControllerRegister: build.mutation<
      FederationSettingControllerRegisterApiResponse,
      FederationSettingControllerRegisterApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/federation-setting`,
        method: 'POST',
        body: queryArg.createFederationSettingDto,
      }),
    }),
    federationSettingControllerGetClients: build.query<
      FederationSettingControllerGetClientsApiResponse,
      FederationSettingControllerGetClientsApiArg
    >({
      query: () => ({ url: `/admin/api/v1/admin/federation-setting` }),
    }),
    federationSettingControllerGetClientDetails: build.query<
      FederationSettingControllerGetClientDetailsApiResponse,
      FederationSettingControllerGetClientDetailsApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/federation-setting/${queryArg.id}`,
      }),
    }),
    federationSettingControllerUpdateClient: build.mutation<
      FederationSettingControllerUpdateClientApiResponse,
      FederationSettingControllerUpdateClientApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/federation-setting/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateFederationSettingDto,
      }),
    }),
    federationSettingControllerDeleteClient: build.mutation<
      FederationSettingControllerDeleteClientApiResponse,
      FederationSettingControllerDeleteClientApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/federation-setting/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    federationSettingControllerSorting: build.mutation<
      FederationSettingControllerSortingApiResponse,
      FederationSettingControllerSortingApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/federation-setting/sorting`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    federationSettingControllerResetSorting: build.mutation<
      FederationSettingControllerResetSortingApiResponse,
      FederationSettingControllerResetSortingApiArg
    >({
      query: () => ({
        url: `/admin/api/v1/admin/federation-setting/reset-sorting`,
        method: 'POST',
      }),
    }),
    appClientFederationControllerCreate: build.mutation<
      AppClientFederationControllerCreateApiResponse,
      AppClientFederationControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/app-clients-federation/bulk`,
        method: 'POST',
        body: queryArg.createAppClientFederationDto,
      }),
    }),
    appClientFederationControllerFindAll: build.query<
      AppClientFederationControllerFindAllApiResponse,
      AppClientFederationControllerFindAllApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/app-clients-federation/list`,
        params: { app_client_setting_id: queryArg.appClientSettingId },
      }),
    }),
    appClientFederationControllerUpdate: build.mutation<
      AppClientFederationControllerUpdateApiResponse,
      AppClientFederationControllerUpdateApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/app-clients-federation/${queryArg.appClientSettingId}`,
        method: 'PATCH',
        body: queryArg.updateAppClientFederationDto,
      }),
    }),
    appClientFederationControllerDelete: build.mutation<
      AppClientFederationControllerDeleteApiResponse,
      AppClientFederationControllerDeleteApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/app-clients-federation/${queryArg.appClientSettingId}`,
        method: 'DELETE',
        body: queryArg.deleteAppClientFederationDto,
      }),
    }),
    appClientFederationControllerSorting: build.mutation<
      AppClientFederationControllerSortingApiResponse,
      AppClientFederationControllerSortingApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/app-clients-federation/${queryArg.appClientSettingId}/sorting`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    appClientFederationControllerResetSorting: build.mutation<
      AppClientFederationControllerResetSortingApiResponse,
      AppClientFederationControllerResetSortingApiArg
    >({
      query: queryArg => ({
        url: `/admin/api/v1/admin/app-clients-federation/${queryArg.appClientSettingId}/reset-sorting`,
        method: 'POST',
      }),
    }),
    managementControllerGetOidcConfig: build.query<
      ManagementControllerGetOidcConfigApiResponse,
      ManagementControllerGetOidcConfigApiArg
    >({
      query: () => ({ url: `/admin/api/v1/admin/oidc/admin/config` }),
    }),
    managementControllerGetOidcJwks: build.query<
      ManagementControllerGetOidcJwksApiResponse,
      ManagementControllerGetOidcJwksApiArg
    >({
      query: () => ({ url: `/admin/api/v1/admin/oidc/admin/jwks` }),
    }),
    managementControllerSetOidcConfig: build.mutation<
      ManagementControllerSetOidcConfigApiResponse,
      ManagementControllerSetOidcConfigApiArg
    >({
      query: () => ({
        url: `/admin/api/v1/admin/oidc/admin/refresh`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as vsAdminApiInjected };
export type AccountControllerUserPermissionInfoApiResponse =
  /** status 200  */ AccountPermissionInfoVo;
export type AccountControllerUserPermissionInfoApiArg = void;
export type AccountControllerCreateApiResponse =
  /** status 200  */ SystemAccountVo[];
export type AccountControllerCreateApiArg = {
  body: SystemAccountDto[];
};
export type AccountControllerGetAllApiResponse =
  /** status 200  */ GetSystemAccountWithPaginationVo;
export type AccountControllerGetAllApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** email for searching */
  email?: string;
};
export type AccountControllerDeleteApiResponse = unknown;
export type AccountControllerDeleteApiArg = {
  body: DeleteSystemAccountDto;
};
export type PermissionActionControllerFindAllApiResponse =
  /** status 200  */ GetPermissionActionWithPaginationVo;
export type PermissionActionControllerFindAllApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
};
export type PermissionActionControllerCreateApiResponse =
  /** status 200  */ PermissionActionVo[];
export type PermissionActionControllerCreateApiArg = {
  body: PermissionActionDto[];
};
export type PermissionActionControllerDeleteApiResponse = unknown;
export type PermissionActionControllerDeleteApiArg = {
  deletePermissionActionDto: DeletePermissionActionDto;
};
export type PermissionResourceControllerFindAllApiResponse =
  /** status 200  */ GetPermissionResourceWithPaginationVo;
export type PermissionResourceControllerFindAllApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
};
export type PermissionResourceControllerCreateApiResponse =
  /** status 200  */ PermissionResourceVo[];
export type PermissionResourceControllerCreateApiArg = {
  body: PermissionResourceDto[];
};
export type PermissionResourceControllerDeleteApiResponse = unknown;
export type PermissionResourceControllerDeleteApiArg = {
  deletePermissionResourceDto: DeletePermissionResourceDto;
};
export type AccountPermissionControllerFindOneApiResponse =
  /** status 200  */ PermissionVo[];
export type AccountPermissionControllerFindOneApiArg = {
  accountId: string;
};
export type AccountPermissionControllerCreateApiResponse = unknown;
export type AccountPermissionControllerCreateApiArg = {
  accountId: string;
  createAccountPermissionDto: CreateAccountPermissionDto;
};
export type AccountPermissionControllerUpdateApiResponse = unknown;
export type AccountPermissionControllerUpdateApiArg = {
  accountId: string;
  updateAccountPermissionDto: UpdateAccountPermissionDto;
};
export type AccountPermissionControllerDeleteApiResponse = unknown;
export type AccountPermissionControllerDeleteApiArg = {
  deleteAccountPermissionDto: DeleteAccountPermissionDto;
};
export type ServiceServiceControllerFindAllApiResponse =
  /** status 200  */ GetServiceWithPaginationVo;
export type ServiceServiceControllerFindAllApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** service list of array. */
  code?: string;
};
export type ServiceServiceControllerCreateApiResponse =
  /** status 200  */ ServiceVo[];
export type ServiceServiceControllerCreateApiArg = {
  body: ServiceDto[];
};
export type ServiceServiceControllerDeleteApiResponse = unknown;
export type ServiceServiceControllerDeleteApiArg = {
  deleteServiceDto: DeleteServiceDto;
};
export type PermissionControllerFindAllApiResponse =
  /** status 200  */ PermissionVo[];
export type PermissionControllerFindAllApiArg = {
  type: string;
  code?: string;
};
export type PermissionControllerCreateApiResponse =
  /** status 200  */ PermissionVo;
export type PermissionControllerCreateApiArg = {
  createPermissionDto: CreatePermissionDto;
};
export type PermissionControllerDeleteApiResponse = unknown;
export type PermissionControllerDeleteApiArg = {
  id: string;
};
export type ClientPermissionControllerFindAllApiResponse =
  /** status 200  */ GetClientCredentialWithPaginationVo;
export type ClientPermissionControllerFindAllApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** client credential name for searching */
  name?: string;
  /** client id for searching */
  clientId?: string;
};
export type ClientPermissionControllerCreateApiResponse =
  /** status 200  */ ClientCredentialVo;
export type ClientPermissionControllerCreateApiArg = {
  createClientCredentialDto: CreateClientCredentialDto;
};
export type ClientPermissionControllerDeleteApiResponse = unknown;
export type ClientPermissionControllerDeleteApiArg = {
  deleteClientCredentialDto: DeleteClientCredentialDto;
};
export type ClientPermissionControllerUpdateApiResponse =
  /** status 200  */ ClientCredentialVo;
export type ClientPermissionControllerUpdateApiArg = {
  clientPermissionId: string;
  clientCredentialDto: ClientCredentialDto;
};
export type MailTemplateControllerCreateApiResponse = unknown;
export type MailTemplateControllerCreateApiArg = {
  createMailTemplateDto: CreateMailTemplateDto;
};
export type MailTemplateControllerFindAllApiResponse =
  /** status 200  */ FindAllMailTemplateVo;
export type MailTemplateControllerFindAllApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  languageCode: string;
  globalSearch?: string;
};
export type MailTemplateControllerBulkUpdateApiResponse = unknown;
export type MailTemplateControllerBulkUpdateApiArg = {
  body: BulkUpdateMailTemplateDto[];
};
export type MailTemplateControllerBulkDeleteApiResponse = unknown;
export type MailTemplateControllerBulkDeleteApiArg = {
  mailTemplateCodeBaseDto: MailTemplateCodeBaseDto;
};
export type MailTemplateControllerFindOneApiResponse =
  /** status 200  */ FindOneMailTemplateDetailsVo;
export type MailTemplateControllerFindOneApiArg = {
  languageCode: string;
  isHidden?: string;
  templateCode: string;
};
export type MailTemplateControllerUpdateApiResponse = unknown;
export type MailTemplateControllerUpdateApiArg = {
  languageCode: string;
  templateCode: string;
  updateMailTemplateDto: UpdateMailTemplateDto;
};
export type MailTemplateControllerDeleteApiResponse = unknown;
export type MailTemplateControllerDeleteApiArg = {
  languageCode: string;
  templateCode: string;
};
export type MailTemplateControllerResetApiResponse = unknown;
export type MailTemplateControllerResetApiArg = {
  languageCode: string;
  templateCode: string;
};
export type MailTemplateControllerArchivedApiResponse = unknown;
export type MailTemplateControllerArchivedApiArg = {
  languageCode: string;
  templateCode: string;
};
export type MailTemplateControllerRestoredApiResponse = unknown;
export type MailTemplateControllerRestoredApiArg = {
  languageCode: string;
  templateCode: string;
};
export type MailTemplateControllerPublishedApiResponse = unknown;
export type MailTemplateControllerPublishedApiArg = {
  languageCode: string;
  templateCode: string;
};
export type MailTemplateControllerUnpublishApiResponse = unknown;
export type MailTemplateControllerUnpublishApiArg = {
  languageCode: string;
  templateCode: string;
};
export type MailTemplateControllerSendEmailApiResponse = unknown;
export type MailTemplateControllerSendEmailApiArg = {
  languageCode: string;
  templateCode: string;
  testingEmailDto: TestingEmailDto;
};
export type MailTemplateControllerSyncTriggerApiResponse = unknown;
export type MailTemplateControllerSyncTriggerApiArg = {
  mailTemplateCodesDto: MailTemplateCodesDto;
};
export type MailTemplateComponentControllerCreateApiResponse = unknown;
export type MailTemplateComponentControllerCreateApiArg = {
  languageCode: string;
  createMailTemplateComponentDto: CreateMailTemplateComponentDto;
};
export type MailTemplateComponentControllerFindAllApiResponse =
  /** status 200  */ FindAllMailTemplateComponentVo;
export type MailTemplateComponentControllerFindAllApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  languageCode: string;
  type: 'header' | 'footer' | 'customize';
  /** Use fuzzy search. */
  name?: string;
};
export type MailTemplateComponentControllerFindOneApiResponse =
  /** status 200  */ FindOneMailTemplateComponentVo;
export type MailTemplateComponentControllerFindOneApiArg = {
  id: string;
};
export type MailTemplateComponentControllerUpdateApiResponse = unknown;
export type MailTemplateComponentControllerUpdateApiArg = {
  id: string;
  updateMailTemplateComponentDto: UpdateMailTemplateComponentDto;
};
export type MailTemplateComponentControllerDeleteApiResponse = unknown;
export type MailTemplateComponentControllerDeleteApiArg = {
  id: string;
};
export type MailTemplateComponentControllerInspectRelationApiResponse =
  /** status 200  */ InspectRelationMailTemplateComponentVo[];
export type MailTemplateComponentControllerInspectRelationApiArg = {
  id: string;
};
export type FileUploadControllerCreateFilesApiResponse =
  /** status 200 According to RESTful definitions, only respond with status codes */ MailTemplateFileVo[];
export type FileUploadControllerCreateFilesApiArg = {
  folder: 'images' | 'attachments' | 'mail-template';
  languageCode: string;
  templateCode: string;
  /** File upload */
  createFileDto: CreateFileDto;
};
export type FileUploadControllerGetFilesApiResponse =
  /** status 200  */ MailTemplateFileVo[];
export type FileUploadControllerGetFilesApiArg = {
  folder: 'images' | 'attachments' | 'mail-template';
  languageCode: string;
  templateCode: string;
};
export type FileUploadControllerDeleteFileApiResponse = unknown;
export type FileUploadControllerDeleteFileApiArg = {
  folder: 'images' | 'attachments' | 'mail-template';
  languageCode: string;
  templateCode: string;
  deleteFileDto: DeleteFileDto;
};
export type ImageUploadControllerCreateFilesApiResponse =
  /** status 201  */ string[];
export type ImageUploadControllerCreateFilesApiArg = {
  /** image upload */
  createImageDto: CreateImageDto;
};
export type ImageUploadControllerGetFilesApiResponse =
  /** status 200  */ string[];
export type ImageUploadControllerGetFilesApiArg = void;
export type ImageUploadControllerDeleteFileApiResponse = unknown;
export type ImageUploadControllerDeleteFileApiArg = {
  deleteFileDto: DeleteFileDto;
};
export type SqsMailerControllerFindAllRegionOfLambdaApiResponse =
  /** status 200  */ string[];
export type SqsMailerControllerFindAllRegionOfLambdaApiArg = void;
export type SqsMailerControllerInspectAndGetFunctionNameApiResponse =
  /** status 200  */ string[];
export type SqsMailerControllerInspectAndGetFunctionNameApiArg = {
  region: string;
};
export type SqsMailerControllerFindSqsEventSourceMappingApiResponse =
  /** status 200  */ SqsMappingInfoVo[];
export type SqsMailerControllerFindSqsEventSourceMappingApiArg = {
  region: string;
};
export type SqsMailerControllerCreateEventSourceMappingApiResponse = unknown;
export type SqsMailerControllerCreateEventSourceMappingApiArg = {
  createSqsLambdaMappingDto: CreateSqsLambdaMappingDto;
};
export type SqsMailerControllerListEventSourceMappingApiResponse =
  /** status 200  */ ListLambdaBindingDetailVo[];
export type SqsMailerControllerListEventSourceMappingApiArg = {
  region: string;
  functionName: string;
};
export type SqsMailerControllerUpdateEventSourceMappingApiResponse = unknown;
export type SqsMailerControllerUpdateEventSourceMappingApiArg = {
  region: string;
  functionName: string;
  updateEventSourceMappingDto: UpdateEventSourceMappingDto;
};
export type SqsMailerControllerDeleteEventSourceMappingApiResponse = unknown;
export type SqsMailerControllerDeleteEventSourceMappingApiArg = {
  uuid: string;
  region: string;
};
export type MailRecordControllerListApiResponse =
  /** status 200  */ ListMailRecordVo;
export type MailRecordControllerListApiArg = {
  status?: 'pending' | 'sent' | 'failed' | 'delivered' | 'blocked' | 'error';
  pageSize: number;
  nextPageKey?: string | null;
  recipientEmail?: string | null;
  templateCode?: string | null;
  attemptStatus?: ('success' | 'failure') | null;
  createdStartTime?: string | null;
  createdEndTime?: string | null;
};
export type MailRecordControllerResendApiResponse = unknown;
export type MailRecordControllerResendApiArg = {
  queueMessageId: string;
};
export type MailBlockControllerFindAllApiResponse =
  /** status 200  */ ListMailBlockVo;
export type MailBlockControllerFindAllApiArg = {
  pageSize: number;
  nextPageKey?: string | null;
  email?: string;
};
export type MailBlockControllerBlockApiResponse = unknown;
export type MailBlockControllerBlockApiArg = {
  email: string;
};
export type MailBlockControllerUnblockApiResponse = unknown;
export type MailBlockControllerUnblockApiArg = {
  email: string;
};
export type MailTemplateContributorControllerManageApiResponse = unknown;
export type MailTemplateContributorControllerManageApiArg = {
  manageMailTemplateContributorDto: ManageMailTemplateContributorDto;
};
export type MailTemplateContributorControllerFindAllApiResponse =
  /** status 200  */ GetMailTemplateContributorListDto[];
export type MailTemplateContributorControllerFindAllApiArg = void;
export type MessageTemplateControllerGetMessageTemplatesApiResponse =
  /** status 200  */ MessageTemplateListVo;
export type MessageTemplateControllerGetMessageTemplatesApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
};
export type MessageTemplateControllerGetMessageTemplatesByCodeApiResponse =
  /** status 200  */ MessageTemplateVo;
export type MessageTemplateControllerGetMessageTemplatesByCodeApiArg = {
  templateCode: string;
};
export type MessageTemplateControllerCreateMessageTemplateApiResponse =
  /** status 200  */ MessageTemplateVo;
export type MessageTemplateControllerCreateMessageTemplateApiArg = {
  templateCode: string;
  body: string;
};
export type MessageTemplateControllerUpdateMessageTemplateApiResponse =
  /** status 200  */ void;
export type MessageTemplateControllerUpdateMessageTemplateApiArg = {
  templateCode: string;
  body: string;
};
export type MessageTemplateControllerDeleteMessageTemplateApiResponse = unknown;
export type MessageTemplateControllerDeleteMessageTemplateApiArg = {
  templateCode: string;
};
export type MessageHistoryControllerGetMessageHistoriesApiResponse =
  /** status 200  */ MessageHistoryListVo;
export type MessageHistoryControllerGetMessageHistoriesApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
};
export type MessageHistoryControllerGetMessageHistoryApiResponse =
  /** status 200  */ MessageHistoryVo;
export type MessageHistoryControllerGetMessageHistoryApiArg = {
  historyId: string;
};
export type MessageHistoryControllerSendMessageApiResponse = unknown;
export type MessageHistoryControllerSendMessageApiArg = {
  historyId: string;
};
export type MessageHistoryControllerUpdateMessageHistoryApiResponse = unknown;
export type MessageHistoryControllerUpdateMessageHistoryApiArg = {
  historyId: string;
  messageInput: MessageInput;
};
export type MessageHistoryControllerDeleteMessageApiResponse = unknown;
export type MessageHistoryControllerDeleteMessageApiArg = {
  historyId: string;
};
export type AppClientControllerRegisterApiResponse =
  /** status 200  */ AppClientMetaVo;
export type AppClientControllerRegisterApiArg = {
  createAppClient: CreateAppClient;
};
export type AppClientControllerGetClientsApiResponse =
  /** status 200  */ GetAppClientSettingWithPaginationVo;
export type AppClientControllerGetClientsApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  filter?: 'client_credentials';
  clientId?: string;
};
export type AppClientControllerGetClientDetailsApiResponse =
  /** status 200  */ AppClientMetaVo;
export type AppClientControllerGetClientDetailsApiArg = {
  clientId: string;
};
export type AppClientControllerUpdateClientApiResponse = unknown;
export type AppClientControllerUpdateClientApiArg = {
  clientId: string;
  updateAppClient: UpdateAppClient;
};
export type AppClientControllerDeleteClientApiResponse = unknown;
export type AppClientControllerDeleteClientApiArg = {
  clientId: string;
};
export type AdminClientControllerRegisterApiResponse =
  /** status 200  */ AdminClientMetaVo;
export type AdminClientControllerRegisterApiArg = {
  createAdminClientDto: CreateAdminClientDto;
};
export type AdminClientControllerGetClientsApiResponse =
  /** status 200  */ GetAdminClientSettingWithPaginationVo;
export type AdminClientControllerGetClientsApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  filter?: 'client_credentials';
  clientId?: string;
};
export type AdminClientControllerCreateServiceAccountApiResponse =
  /** status 200  */ AdminClientMetaVo;
export type AdminClientControllerCreateServiceAccountApiArg = {
  createAdminClientServiceAccountDto: CreateAdminClientServiceAccountDto;
};
export type AdminClientControllerGetClientDetailsApiResponse =
  /** status 200  */ AdminClientMetaVo;
export type AdminClientControllerGetClientDetailsApiArg = {
  clientId: string;
};
export type AdminClientControllerUpdateClientApiResponse = unknown;
export type AdminClientControllerUpdateClientApiArg = {
  clientId: string;
  updateAdminClientDto: UpdateAdminClientDto;
};
export type AdminClientControllerDeleteClientApiResponse = unknown;
export type AdminClientControllerDeleteClientApiArg = {
  clientId: string;
};
export type FederationSettingControllerRegisterApiResponse = unknown;
export type FederationSettingControllerRegisterApiArg = {
  createFederationSettingDto: CreateFederationSettingDto;
};
export type FederationSettingControllerGetClientsApiResponse =
  /** status 200  */ ListFederationOidcSettingVo[];
export type FederationSettingControllerGetClientsApiArg = void;
export type FederationSettingControllerGetClientDetailsApiResponse =
  /** status 200  */ FederationSettingDetailsVo;
export type FederationSettingControllerGetClientDetailsApiArg = {
  id: string;
};
export type FederationSettingControllerUpdateClientApiResponse = unknown;
export type FederationSettingControllerUpdateClientApiArg = {
  id: string;
  updateFederationSettingDto: UpdateFederationSettingDto;
};
export type FederationSettingControllerDeleteClientApiResponse = unknown;
export type FederationSettingControllerDeleteClientApiArg = {
  id: string;
};
export type FederationSettingControllerSortingApiResponse = unknown;
export type FederationSettingControllerSortingApiArg = {
  body: SortingFederationSettingDto[];
};
export type FederationSettingControllerResetSortingApiResponse = unknown;
export type FederationSettingControllerResetSortingApiArg = void;
export type AppClientFederationControllerCreateApiResponse =
  /** status 201  */ CreateAppClientFederationVo[];
export type AppClientFederationControllerCreateApiArg = {
  createAppClientFederationDto: CreateAppClientFederationDto;
};
export type AppClientFederationControllerFindAllApiResponse =
  /** status 200  */ AppClientFederationVo[];
export type AppClientFederationControllerFindAllApiArg = {
  appClientSettingId?: string;
};
export type AppClientFederationControllerUpdateApiResponse = unknown;
export type AppClientFederationControllerUpdateApiArg = {
  appClientSettingId: string;
  updateAppClientFederationDto: UpdateAppClientFederationDto;
};
export type AppClientFederationControllerDeleteApiResponse = unknown;
export type AppClientFederationControllerDeleteApiArg = {
  appClientSettingId: string;
  deleteAppClientFederationDto: DeleteAppClientFederationDto;
};
export type AppClientFederationControllerSortingApiResponse = unknown;
export type AppClientFederationControllerSortingApiArg = {
  appClientSettingId: string;
  body: SortingFederationSettingDto[];
};
export type AppClientFederationControllerResetSortingApiResponse = unknown;
export type AppClientFederationControllerResetSortingApiArg = {
  appClientSettingId: string;
};
export type ManagementControllerGetOidcConfigApiResponse = unknown;
export type ManagementControllerGetOidcConfigApiArg = void;
export type ManagementControllerGetOidcJwksApiResponse = unknown;
export type ManagementControllerGetOidcJwksApiArg = void;
export type ManagementControllerSetOidcConfigApiResponse = unknown;
export type ManagementControllerSetOidcConfigApiArg = void;
export type PermissionVo = {
  id: string;
  code: string;
  name: string;
  description: string;
  type: 'user' | 'client';
  service_code: string;
  action_code: string;
  resource_code: string;
  attributes: string[];
  create_time: string;
  modify_time: string;
};
export type AccountPermissionInfoVo = {
  account_id: string;
  permissions: PermissionVo[];
};
export type SystemAccountVo = {
  id: string;
  email: string;
};
export type SystemAccountDto = {
  id: string;
  email: string;
};
export type PaginationMetaResponseDto = {
  current_page: number;
  per_page: number;
  total_items: number;
  total_pages: number;
};
export type GetSystemAccountWithPaginationVo = {
  meta: PaginationMetaResponseDto;
  data: SystemAccountVo[];
};
export type DeleteSystemAccountDto = {
  /** user id list. */
  ids: string[];
};
export type PermissionActionVo = {
  code: string;
  name: string;
};
export type GetPermissionActionWithPaginationVo = {
  meta: PaginationMetaResponseDto;
  data: PermissionActionVo[];
};
export type PermissionActionDto = {
  code: string;
  name: string;
};
export type DeletePermissionActionDto = {
  /** permission action code list. */
  codes: string[];
};
export type PermissionResourceVo = {
  code: string;
  name: string;
};
export type GetPermissionResourceWithPaginationVo = {
  meta: PaginationMetaResponseDto;
  data: PermissionResourceVo[];
};
export type PermissionResourceDto = {
  code: string;
  name: string;
  description: string;
};
export type DeletePermissionResourceDto = {
  /** permission resource code list. */
  codes: string[];
};
export type CreateAccountPermissionDto = {
  /** permission id list. */
  permission_ids: string[];
};
export type UpdateAccountPermissionDto = {
  /** Permission id list. Must pass final full permission ids */
  permission_ids: string[];
};
export type DeleteAccountPermissionDto = {
  /** Remove all of account permission by account id. */
  account_ids: string[];
};
export type ServiceVo = {
  code: string;
  name: string;
};
export type GetServiceWithPaginationVo = {
  meta: PaginationMetaResponseDto;
  data: ServiceVo[];
};
export type ServiceDto = {
  code: string;
  name: string;
};
export type DeleteServiceDto = {
  /** service code list. */
  codes: string[];
};
export type CreatePermissionDto = {
  name: string;
  description: string;
  type: 'user' | 'client';
  service_code: string;
  action_code: string;
  resource_code: string;
};
export type ClientCredentialScopeBo = {
  code: string;
  name?: string;
};
export type GetClientCredentialVo = {
  id: string;
  client_id: string;
  name: string;
  description: string;
  app_key: string;
  scopes: ClientCredentialScopeBo[];
};
export type GetClientCredentialWithPaginationVo = {
  meta: PaginationMetaResponseDto;
  data: GetClientCredentialVo[];
};
export type ClientCredentialVo = {
  id: string;
  client_id: string;
  name: string;
  description: string;
  app_key: string;
  scopes: string[];
};
export type CreateClientCredentialDto = {
  client_id: string;
  app_key: string;
  name: string;
  description: string;
};
export type DeleteClientCredentialDto = {
  /** client credential id. */
  ids: string[];
};
export type ClientCredentialDto = {
  id: string;
  client_id: string;
  name: string;
  description: string;
  app_key: string;
  scopes: string[];
};
export type CreateMailTemplateDto = {
  template_code: string;
  language_code: string;
  subject: string;
  html: string;
  attachments?: any[];
  header_component_id: string | null;
  footer_component_id: string | null;
  json_attributes: object;
  is_hidden: boolean;
  is_published: boolean;
};
export type PaginationBaseVo = {
  current_page: number;
  per_page: number;
  total_items: number;
  total_pages: number;
};
export type MailTemplateSimpleInformationBase = {
  subject: string;
  code: string;
  is_published: boolean;
  create_time: string;
  modify_time: string;
  is_translated: boolean;
};
export type FindAllMailTemplateVo = {
  meta: PaginationBaseVo;
  data: MailTemplateSimpleInformationBase[];
};
export type BulkUpdateMailTemplateDto = {
  language_code: string;
  template_code: string;
  subject: string;
  html: string;
  header_component_id: string | null;
  footer_component_id: string | null;
  json_attributes: object;
};
export type MailTemplateCodeBaseDto = {
  template_code: string;
};
export type AttachmentsBase = {
  filename: string;
  url: string;
};
export type FindOneMailTemplateDetailsVo = {
  language_code: string;
  code: string;
  header_component_id: string | null;
  footer_component_id: string | null;
  subject: string;
  json_attributes: object;
  header_component_json: object | null;
  footer_component_json: object | null;
  attachment_urls: AttachmentsBase[];
};
export type UpdateMailTemplateDto = {
  subject: string;
  html: string;
  attachments?: any[];
  header_component_id: string | null;
  footer_component_id: string | null;
  json_attributes: object;
  is_hidden: boolean;
  is_published: boolean;
};
export type TestingEmailDto = {
  receiver: string;
  variables: object;
};
export type MailTemplateCodesDto = {
  template_codes: string[];
};
export type CreateMailTemplateComponentDto = {
  type: 'header' | 'footer' | 'customize';
  name: string;
  json_attributes: object;
  thumbnail: string;
};
export type MailTemplateComponentBase = {
  language_code: string;
  type: 'header' | 'footer' | 'customize';
  json_attributes: object;
  id: string;
  name: string;
  thumbnail: string;
};
export type FindAllMailTemplateComponentVo = {
  meta: PaginationBaseVo;
  data: MailTemplateComponentBase[];
};
export type FindOneMailTemplateComponentVo = {
  language_code: string;
  type: 'header' | 'footer' | 'customize';
  json_attributes: object;
  id: string;
  name: string;
};
export type UpdateMailTemplateComponentDto = {
  type: 'header' | 'footer' | 'customize';
  name: string;
  json_attributes: object;
  thumbnail: string;
};
export type InspectRelationMailTemplateComponentVo = {
  language_code: string;
  code: string;
  header_component_id: string | null;
  footer_component_id: string | null;
  subject: string;
  json_attributes: object;
  header_component_json: object | null;
  footer_component_json: object | null;
};
export type MailTemplateFileVo = {
  /** The name of the file */
  filename: string;
  /** The s3 key name of the file */
  full_name: string;
  /** The URL of the file */
  url: string;
};
export type CreateFileDto = {
  attachments: Blob[];
};
export type DeleteFileDto = {
  /** The names of the files */
  urls: string[];
};
export type CreateImageDto = {
  images: Blob[];
};
export type SqsMappingInfoVo = {
  trigger_state: string;
  trigger_uuid: string;
  batch_size: number;
  sqs_name: string;
  function_name: string;
};
export type CreateSqsLambdaMappingDto = {
  region: string;
  function_name: string;
  batch_size: number;
  enabled: boolean;
};
export type ListLambdaBindingDetailVo = {
  batch_size: number;
  event_source_arn: string;
  function_arn: string;
  maximum_batching_window_in_seconds: number;
  state: string;
  state_transition_reason: string;
  uuid: string;
};
export type UpdateEventSourceMappingDto = {
  batch_size: number;
  enabled: boolean;
  uuid: string;
};
export type MailRecordVo = {
  updated_time: string;
  sender_email: string;
  click_by: string;
  mode: string;
  recipient_email: string;
  queue_message_id: string;
  status: string;
  variables: string;
  template_code: string;
  created_time: string;
  provider_message_id: string;
  provider_response: string;
  language_code: string;
  attempt_number: number;
  attempt_status: string;
  open_by: string;
};
export type ListMailRecordVo = {
  current_total_count: number;
  page_size: number;
  next_page_key: string | null;
  data: MailRecordVo[];
};
export type MailBlockVo = {
  email: string;
  blocked_time: string;
  created_time: string;
};
export type ListMailBlockVo = {
  current_total_count: number;
  page_size: number;
  next_page_key: string | null;
  data: MailBlockVo[];
};
export type ManageMailTemplateContributorDto = {
  /** account id */
  id: string;
  languages: string[];
};
export type GetMailTemplateContributorListDto = {
  /** account id */
  id: string;
  account: string;
  languages: string[];
};
export type MessageTemplatesVo = {
  code: string;
  create_time: string;
  modify_time: string;
};
export type MessageTemplateListVo = {
  data: MessageTemplatesVo[];
  meta: PaginationMetaResponseDto;
};
export type MessageTemplateVo = {
  code: string;
  content: string;
  create_time: string;
  modify_time: string;
};
export type MessageTemplateErrorVo = {
  request_id: string;
  code: string;
  status_code: number;
  message: string;
  path: string;
  timestamp: string;
};
export type MessageHistoriesVo = {
  id: string;
  app_name: string;
  status: 'processing' | 'successful' | 'fail';
  thread_url: string;
  create_time: string;
  modify_time: string;
  template_code?: string;
};
export type MessageHistoryListVo = {
  data: MessageHistoriesVo[];
  meta: PaginationMetaResponseDto;
};
export type MessageHistoryVo = {
  id: string;
  app_name: string;
  status: 'processing' | 'successful' | 'fail';
  body: object;
  response_code: string;
  message_id: string;
  thread_url: string;
  response_body: object;
  last_sent_time: string;
  create_time: string;
  modify_time: string;
};
export type MessageHistoryErrorVo = {
  request_id: string;
  code: string;
  status_code: number;
  message: string;
  path: string;
  timestamp: string;
};
export type MessageInput = {
  template_code?: string;
  variables?: object;
  cc?: string[];
};
export type AppClientMetaVo = {
  /** Unique Id */
  client_id: string;
  /** client name. */
  client_name: string;
  /** Random code */
  client_secret: string;
  token_endpoint_auth_method: string;
  /** grant_types list of array. */
  grant_types: string[];
  /** response_types list of array. */
  response_types: string[];
  /** redirect_uris list of array. */
  redirect_uris: string[];
  /** post_logout_redirect_uris list of array. */
  post_logout_redirect_uris: string[];
  backchannel_logout_uri: string;
  application_type: 'web' | 'native';
};
export type CreateAppClient = {
  client_name: string;
  /** token_endpoint_auth_method (client_secret_basic, client_secret_post, client_secret_jwt, private_key_jwt, tls_client_auth, self_signed_tls_client_auth, none) */
  token_endpoint_auth_method: object;
  /** grant_types list of array. (authorization_code, refresh_token, hybrid, implicit) */
  grant_types: string[];
  /** response_types list of array. (none, code, token, id_token) */
  response_types: string[];
  /** redirect_uris list of array. */
  redirect_uris: string[];
  /** post_logout_redirect_uris list of array. */
  post_logout_redirect_uris: string[];
  backchannel_logout_uri: string | null;
  /** If it is a webpage, select 'web'; if it is an app, select 'native'. */
  application_type: object;
};
export type AdminClientSettingVo = {
  id: string;
  client_id: string;
  client_name: string;
  client_secret: string;
};
export type GetAppClientSettingWithPaginationVo = {
  meta: PaginationMetaResponseDto;
  data: AdminClientSettingVo[];
};
export type UpdateAppClient = {
  client_name: string;
  /** token_endpoint_auth_method (client_secret_basic, client_secret_post, client_secret_jwt, private_key_jwt, tls_client_auth, self_signed_tls_client_auth, none) */
  token_endpoint_auth_method: object;
  /** grant_types list of array. (authorization_code, refresh_token, hybrid, implicit) */
  grant_types: string[];
  /** response_types list of array. (none, code, token, id_token) */
  response_types: string[];
  /** redirect_uris list of array. */
  redirect_uris: string[];
  /** post_logout_redirect_uris list of array. */
  post_logout_redirect_uris: string[];
  backchannel_logout_uri: string | null;
  /** If it is a webpage, select 'web'; if it is an app, select 'native'. */
  application_type: object;
};
export type AdminClientMetaVo = {
  /** Unique Id */
  client_id: string;
  /** client name. */
  client_name: string;
  /** Random code */
  client_secret: string;
  token_endpoint_auth_method: string;
  /** grant_types list of array. */
  grant_types: string[];
  /** response_types list of array. */
  response_types: string[];
  /** redirect_uris list of array. */
  redirect_uris: string[];
  /** post_logout_redirect_uris list of array. */
  post_logout_redirect_uris: string[];
  backchannel_logout_uri: string;
  application_type: 'web' | 'native';
};
export type CreateAdminClientDto = {
  client_name: string;
  /** token_endpoint_auth_method (client_secret_basic, client_secret_post, client_secret_jwt, private_key_jwt, tls_client_auth, self_signed_tls_client_auth, none) */
  token_endpoint_auth_method: object;
  /** grant_types list of array. (authorization_code, client_credentials, refresh_token, hybrid, implicit) */
  grant_types: string[];
  /** response_types list of array. (none, code, token, id_token) */
  response_types: string[];
  /** redirect_uris list of array. */
  redirect_uris: string[];
  /** post_logout_redirect_uris list of array. */
  post_logout_redirect_uris: string[];
  backchannel_logout_uri: string | null;
  /** If it is a webpage, select 'web'; if it is an app, select 'native'. */
  application_type: object;
};
export type GetAdminClientSettingWithPaginationVo = {
  meta: PaginationMetaResponseDto;
  data: AdminClientSettingVo[];
};
export type CreateAdminClientServiceAccountDto = {
  client_id: string;
};
export type UpdateAdminClientDto = {
  client_name: string;
  /** token_endpoint_auth_method (client_secret_basic, client_secret_post, client_secret_jwt, private_key_jwt, tls_client_auth, self_signed_tls_client_auth, none) */
  token_endpoint_auth_method: object;
  /** grant_types list of array. (authorization_code, client_credentials, refresh_token, hybrid, implicit) */
  grant_types: string[];
  /** response_types list of array. (none, code, token, id_token) */
  response_types: string[];
  /** redirect_uris list of array. */
  redirect_uris: string[];
  /** post_logout_redirect_uris list of array. */
  post_logout_redirect_uris: string[];
  backchannel_logout_uri: string | null;
  /** If it is a webpage, select 'web'; if it is an app, select 'native'. */
  application_type: object;
};
export type CreateFederationSettingDto = {
  name: string;
  provider: object;
  type: 'OIDC' | 'SCIM' | 'SAML';
  countries: any[];
  is_display: boolean;
  client_id: string;
  client_secret: string;
  issuer_discovery: string;
  grant_types: (
    | 'authorization_code'
    | 'client_credentials'
    | 'refresh_token'
    | 'hybrid'
    | 'implicit'
  )[];
  response_types: (
    | 'code'
    | 'id_token'
    | 'code id_token'
    | 'id_token token'
    | 'code token'
    | 'code id_token token'
    | 'none'
  )[];
};
export type FederationOidcSettingVo = {
  client_id: string;
  client_secret: string;
  issuer_discovery: string;
  grant_types: string[];
  response_types: string[];
};
export type ListFederationOidcSettingVo = {
  id: string;
  name: string;
  provider: string;
  type: string;
  countries: string[];
  is_display: boolean;
  display_order: number | null;
  oidc_setting: FederationOidcSettingVo;
};
export type FederationSettingDetailsVo = {
  id: string;
  name: string;
  provider: string;
  type: string;
  countries: string[];
  is_display: boolean;
  client_id: string;
  client_secret: string;
  issuer_discovery: string;
  grant_types: string[];
  response_types: string[];
};
export type UpdateFederationSettingDto = {
  name: string;
  provider: object;
  type: 'OIDC' | 'SCIM' | 'SAML';
  countries: any[];
  is_display: boolean;
  client_id: string;
  client_secret: string;
  issuer_discovery: string;
  grant_types: (
    | 'authorization_code'
    | 'client_credentials'
    | 'refresh_token'
    | 'hybrid'
    | 'implicit'
  )[];
  response_types: (
    | 'code'
    | 'id_token'
    | 'code id_token'
    | 'id_token token'
    | 'code token'
    | 'code id_token token'
    | 'none'
  )[];
};
export type SortingFederationSettingDto = {
  federation_setting_id: string;
  sort_position: number;
};
export type CreateAppClientFederationVo = {
  app_client_setting_id: string;
  federation_setting_id: string;
  federation_setting_name: string;
  countries: string[];
  display_order: number | null;
};
export type CreateAppClientFederationBaseDto = {
  federation_setting_id: string;
  countries: any[];
};
export type CreateAppClientFederationDto = {
  app_client_setting_id: string;
  data: CreateAppClientFederationBaseDto[];
};
export type AppClientFederationVo = {
  app_client_setting_id: string;
  federation_setting_id: string;
  federation_setting_name: string;
  countries: string[];
  display_order: number | null;
};
export type UpdateAppClientFederationDto = {
  federation_setting_id: string;
  countries: any[];
};
export type DeleteAppClientFederationDto = {
  federation_setting_id: string;
};
export const {
  useAccountControllerUserPermissionInfoQuery,
  useAccountControllerCreateMutation,
  useAccountControllerGetAllQuery,
  useAccountControllerDeleteMutation,
  usePermissionActionControllerFindAllQuery,
  usePermissionActionControllerCreateMutation,
  usePermissionActionControllerDeleteMutation,
  usePermissionResourceControllerFindAllQuery,
  usePermissionResourceControllerCreateMutation,
  usePermissionResourceControllerDeleteMutation,
  useAccountPermissionControllerFindOneQuery,
  useAccountPermissionControllerCreateMutation,
  useAccountPermissionControllerUpdateMutation,
  useAccountPermissionControllerDeleteMutation,
  useServiceServiceControllerFindAllQuery,
  useServiceServiceControllerCreateMutation,
  useServiceServiceControllerDeleteMutation,
  usePermissionControllerFindAllQuery,
  usePermissionControllerCreateMutation,
  usePermissionControllerDeleteMutation,
  useClientPermissionControllerFindAllQuery,
  useClientPermissionControllerCreateMutation,
  useClientPermissionControllerDeleteMutation,
  useClientPermissionControllerUpdateMutation,
  useMailTemplateControllerCreateMutation,
  useMailTemplateControllerFindAllQuery,
  useMailTemplateControllerBulkUpdateMutation,
  useMailTemplateControllerBulkDeleteMutation,
  useMailTemplateControllerFindOneQuery,
  useMailTemplateControllerUpdateMutation,
  useMailTemplateControllerDeleteMutation,
  useMailTemplateControllerResetMutation,
  useMailTemplateControllerArchivedMutation,
  useMailTemplateControllerRestoredMutation,
  useMailTemplateControllerPublishedMutation,
  useMailTemplateControllerUnpublishMutation,
  useMailTemplateControllerSendEmailMutation,
  useMailTemplateControllerSyncTriggerMutation,
  useMailTemplateComponentControllerCreateMutation,
  useMailTemplateComponentControllerFindAllQuery,
  useMailTemplateComponentControllerFindOneQuery,
  useMailTemplateComponentControllerUpdateMutation,
  useMailTemplateComponentControllerDeleteMutation,
  useMailTemplateComponentControllerInspectRelationQuery,
  useFileUploadControllerCreateFilesMutation,
  useFileUploadControllerGetFilesQuery,
  useFileUploadControllerDeleteFileMutation,
  useImageUploadControllerCreateFilesMutation,
  useImageUploadControllerGetFilesQuery,
  useImageUploadControllerDeleteFileMutation,
  useSqsMailerControllerFindAllRegionOfLambdaQuery,
  useSqsMailerControllerInspectAndGetFunctionNameQuery,
  useSqsMailerControllerFindSqsEventSourceMappingQuery,
  useSqsMailerControllerCreateEventSourceMappingMutation,
  useSqsMailerControllerListEventSourceMappingQuery,
  useSqsMailerControllerUpdateEventSourceMappingMutation,
  useSqsMailerControllerDeleteEventSourceMappingMutation,
  useMailRecordControllerListQuery,
  useMailRecordControllerResendMutation,
  useMailBlockControllerFindAllQuery,
  useMailBlockControllerBlockMutation,
  useMailBlockControllerUnblockMutation,
  useMailTemplateContributorControllerManageMutation,
  useMailTemplateContributorControllerFindAllQuery,
  useMessageTemplateControllerGetMessageTemplatesQuery,
  useMessageTemplateControllerGetMessageTemplatesByCodeQuery,
  useMessageTemplateControllerCreateMessageTemplateMutation,
  useMessageTemplateControllerUpdateMessageTemplateMutation,
  useMessageTemplateControllerDeleteMessageTemplateMutation,
  useMessageHistoryControllerGetMessageHistoriesQuery,
  useMessageHistoryControllerGetMessageHistoryQuery,
  useMessageHistoryControllerSendMessageMutation,
  useMessageHistoryControllerUpdateMessageHistoryMutation,
  useMessageHistoryControllerDeleteMessageMutation,
  useAppClientControllerRegisterMutation,
  useAppClientControllerGetClientsQuery,
  useAppClientControllerGetClientDetailsQuery,
  useAppClientControllerUpdateClientMutation,
  useAppClientControllerDeleteClientMutation,
  useAdminClientControllerRegisterMutation,
  useAdminClientControllerGetClientsQuery,
  useAdminClientControllerCreateServiceAccountMutation,
  useAdminClientControllerGetClientDetailsQuery,
  useAdminClientControllerUpdateClientMutation,
  useAdminClientControllerDeleteClientMutation,
  useFederationSettingControllerRegisterMutation,
  useFederationSettingControllerGetClientsQuery,
  useFederationSettingControllerGetClientDetailsQuery,
  useFederationSettingControllerUpdateClientMutation,
  useFederationSettingControllerDeleteClientMutation,
  useFederationSettingControllerSortingMutation,
  useFederationSettingControllerResetSortingMutation,
  useAppClientFederationControllerCreateMutation,
  useAppClientFederationControllerFindAllQuery,
  useAppClientFederationControllerUpdateMutation,
  useAppClientFederationControllerDeleteMutation,
  useAppClientFederationControllerSortingMutation,
  useAppClientFederationControllerResetSortingMutation,
  useManagementControllerGetOidcConfigQuery,
  useManagementControllerGetOidcJwksQuery,
  useManagementControllerSetOidcConfigMutation,
} = injectedRtkApi;
