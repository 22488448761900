import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import StoreIcon from '@mui/icons-material/Store';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { lazy } from 'react';

import { Path } from '.';

const systemPaths: Path[] = [
  {
    path: '/permission',
    name: 'Permission Management',
    icon: <LockPersonIcon />,
    View: lazy(
      () =>
        import(
          '../../views/permission-management' /* webpackChunkName: "view_permission_management" */
        )
    ),
  },
  {
    path: '/admin-users',
    name: 'Admin Users',
    icon: <ManageAccountsOutlinedIcon />,
    View: lazy(
      () =>
        import(
          '../../views/admin-users' /* webpackChunkName: "view_admin_users" */
        )
    ),
  },
  {
    path: '/federations',
    name: 'Federations Management',
    icon: <AssuredWorkloadIcon />,
    View: lazy(
      () =>
        import(
          '../../views/federations-management' /* webpackChunkName: "view_federations_management" */
        )
    ),
  },
  {
    path: '/admin-clients',
    name: 'Admin Clients',
    icon: <StoreIcon />,
    View: lazy(
      () =>
        import(
          '../../views/admin-clients' /* webpackChunkName: "view_admin_clients" */
        )
    ),
  },
  {
    path: '/app-clients',
    name: 'App Clients',
    icon: <StorefrontIcon />,
    View: lazy(
      () =>
        import(
          '../../views/app-clients' /* webpackChunkName: "view_app_clients" */
        )
    ),
  },
  {
    path: '/sqs-mailer',
    name: 'SQS Mailer Configuration',
    icon: <AlternateEmailIcon />,
    View: lazy(
      () =>
        import(
          '../../views/sqs-mailer-configuration' /* webpackChunkName: "view_sqs_mailer_management" */
        )
    ),
    activeEnv: ['dev'],
  },
];

export default systemPaths;
