import { vsAuthAdminApiInjected } from './vsAuthAdminApiInjected';

const vsAuthAdminApiEnhanced = vsAuthAdminApiInjected.enhanceEndpoints({
  endpoints: {
    getToken: {
      extraOptions: {
        isPublicEndpoint: true,
      },
    },
  },
});

export { vsAuthAdminApiEnhanced as vsAuthAdminApiSlice };
export * from './vsAuthAdminApiInjected';
