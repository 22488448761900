import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum ManagementTypeKeys {
  none = 'none',
  core = 'core',
  system = 'system',
  dm = 'dm',
}
export type ManagementTypes = keyof typeof ManagementTypeKeys;

const initialState: {
  selectedManagement: ManagementTypes;
  entitySignUpTabKey: string;
} = {
  selectedManagement: 'none',
  entitySignUpTabKey: '',
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setEntitySignUpTabKey: (state, action: PayloadAction<string>) => {
      state.entitySignUpTabKey = action.payload;
    },
    setSelectedManagement: (state, action: PayloadAction<ManagementTypes>) => {
      state.selectedManagement = action.payload;
    },
  },
});

export const { setEntitySignUpTabKey, setSelectedManagement } =
  globalSlice.actions;
