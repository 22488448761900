import { orderBy } from 'lodash';

import {
  DomainManagementControllerGetDomainApiResponse,
  EntityClass,
  SignupControllerGetDomainTypeCodeApiResponse,
  vsEntityApiInjected,
} from './vsEntityApiInjected';

export enum VsEntitySliceTags {
  USER = 'USER',
  POLICY = 'POLICY',
  ENTITY_SYNC = 'ENTITY_SYNC',
  ENTITY_INFO = 'ENTITY_INFO',
  ENTITY_LIST = 'ENTITY_LIST',
  DOMAIN_LIST = 'DOMAIN_LIST',
  DOMAIN_USER_LIST = 'DOMAIN_USER_LIST',
  ADMIN_ENTITY_LIST = 'ADMIN_ENTITY_LIST',
  ADMIN_ENTITY_DETAIL_BASIC_INFO = 'ADMIN_ENTITY_DETAIL_BASIC_INFO',
  ADMIN_ENTITY_DETAIL_PROFILE = 'ADMIN_ENTITY_DETAIL_PROFILE',
  ADMIN_ENTITY_DETAIL_DOMAIN = 'ADMIN_ENTITY_DETAIL_DOMAIN',
  ADMIN_ENTITY_DETAIL_USERS = 'ADMIN_ENTITY_DETAIL_USERS',
  ADMIN_ENTITY_SIGN_UP_LIST = 'ADMIN_ENTITY_SIGN_UP_LIST',
  ADMIN_ENTITY_SIGN_UP_DETAIL = 'ADMIN_ENTITY_SIGN_UP_DETAIL',
  CREATE_SIGNUP_ENTITY_LIST = 'CREATE_SIGNUP_ENTITY_LIST',
  SEND_SIGNUP_ENTITY_LIST = 'SEND_SIGNUP_ENTITY_LIST',
  ENTITY_AUTO_APPROVER_LIST = 'ENTITY_AUTO_APPROVER_LIST',
}

const vsEntityApiEnhanced = vsEntityApiInjected.enhanceEndpoints({
  addTagTypes: Object.values(VsEntitySliceTags),
  endpoints: {
    entityUserControllerGetUserByPolicyCode: {
      providesTags: [VsEntitySliceTags.USER, VsEntitySliceTags.POLICY],
    },
    policyControllerUpdateUserPolicy: {
      invalidatesTags: [VsEntitySliceTags.POLICY, VsEntitySliceTags.USER],
    },
    entityUserControllerGetUserPolicies: {
      providesTags: [VsEntitySliceTags.POLICY],
    },
    policyControllerFindAll: {
      providesTags: [VsEntitySliceTags.POLICY],
      transformResponse: transformPoliciesOrder,
    },
    policyControllerFindAllWithUser: {
      providesTags: [VsEntitySliceTags.POLICY],
      transformResponse: transformPoliciesOrder,
    },
    entityUserControllerFindAll: {
      providesTags: [VsEntitySliceTags.USER],
    },
    entityUserControllerRemoveUser: {
      invalidatesTags: [VsEntitySliceTags.USER],
    },
    entityUserControllerCreate: {
      invalidatesTags: [VsEntitySliceTags.USER],
    },
    userSyncControllerGetEntitySyncList: {
      providesTags: [VsEntitySliceTags.ENTITY_SYNC],
    },
    userSyncControllerGetSyncHistory: {
      providesTags: [VsEntitySliceTags.ENTITY_SYNC],
    },
    userSyncControllerSetSyncHistory: {
      invalidatesTags: [VsEntitySliceTags.ENTITY_SYNC],
    },
    entityControllerGetEntity: {
      providesTags: [VsEntitySliceTags.ENTITY_INFO],
    },
    profileControllerChangeOwner: {
      invalidatesTags: [VsEntitySliceTags.ENTITY_INFO],
    },
    entityControllerGetUserEntityList: {
      providesTags: [VsEntitySliceTags.ENTITY_LIST],
    },
    entityControllerSetEntityDefault: {
      invalidatesTags: [VsEntitySliceTags.ENTITY_LIST],
    },
    domainControllerFindAllDomain: {
      providesTags: [VsEntitySliceTags.DOMAIN_LIST],
    },
    domainControllerCreateDomain: {
      invalidatesTags: [VsEntitySliceTags.DOMAIN_LIST],
    },
    domainControllerDelete: {
      invalidatesTags: [VsEntitySliceTags.DOMAIN_LIST],
    },
    domainControllerVerifyDomain: {
      invalidatesTags: [VsEntitySliceTags.DOMAIN_LIST],
    },
    signupManagementControllerFindAllEntityRequest: {
      providesTags: [VsEntitySliceTags.ADMIN_ENTITY_SIGN_UP_LIST],
    },
    signupManagementControllerGetEntityRequestDetail: {
      providesTags: [VsEntitySliceTags.ADMIN_ENTITY_SIGN_UP_DETAIL],
    },
    signupManagementControllerFindAllInviteEntityRequest: {
      providesTags: [VsEntitySliceTags.ADMIN_ENTITY_SIGN_UP_DETAIL],
    },
    signupManagementControllerUpdateEntityRequest: {
      invalidatesTags: [
        VsEntitySliceTags.ADMIN_ENTITY_SIGN_UP_LIST,
        VsEntitySliceTags.ADMIN_ENTITY_SIGN_UP_DETAIL,
      ],
    },
    signupManagementControllerApprove: {
      invalidatesTags: [
        VsEntitySliceTags.ADMIN_ENTITY_SIGN_UP_LIST,
        VsEntitySliceTags.ADMIN_ENTITY_SIGN_UP_DETAIL,
      ],
    },
    signupManagementControllerReject: {
      invalidatesTags: [
        VsEntitySliceTags.ADMIN_ENTITY_SIGN_UP_LIST,
        VsEntitySliceTags.ADMIN_ENTITY_SIGN_UP_DETAIL,
      ],
    },
    signupManagementControllerPend: {
      invalidatesTags: [
        VsEntitySliceTags.ADMIN_ENTITY_SIGN_UP_LIST,
        VsEntitySliceTags.ADMIN_ENTITY_SIGN_UP_DETAIL,
      ],
    },
    signupControllerFindAllEntityRequest: {
      providesTags: [VsEntitySliceTags.CREATE_SIGNUP_ENTITY_LIST],
    },
    signupControllerCreate: {
      invalidatesTags: [VsEntitySliceTags.CREATE_SIGNUP_ENTITY_LIST],
    },
    signupControllerFindAllInviteEntityRequest: {
      providesTags: [VsEntitySliceTags.SEND_SIGNUP_ENTITY_LIST],
    },
    signupControllerCreateInviteEntityRequest: {
      invalidatesTags: [VsEntitySliceTags.SEND_SIGNUP_ENTITY_LIST],
    },
    signupControllerGetGenericsCode: {
      extraOptions: { isPublicEndpoint: true },
    },
    signupControllerGetDomainTypeCode: {
      extraOptions: { isPublicEndpoint: true },
      transformResponse(resp: SignupControllerGetDomainTypeCodeApiResponse) {
        const sortOrder = ['individual', 'shared', 'public'];
        return (resp ?? []).sort(
          (a, b) => sortOrder.indexOf(a.code) - sortOrder.indexOf(b.code)
        );
      },
    },
    signupControllerCheckInviteEntityRequestById: {
      extraOptions: { isPublicEndpoint: true },
    },
    entityManagementControllerGetEntityList: {
      providesTags: [VsEntitySliceTags.ADMIN_ENTITY_LIST],
    },
    entityManagementControllerDeleteEntity: {
      invalidatesTags: [VsEntitySliceTags.ADMIN_ENTITY_LIST],
    },
    entityManagementControllerGetEntityDetail: {
      providesTags: [VsEntitySliceTags.ADMIN_ENTITY_DETAIL_BASIC_INFO],
    },
    entityManagementControllerUpdateEntity: {
      invalidatesTags: [
        VsEntitySliceTags.ADMIN_ENTITY_DETAIL_BASIC_INFO,
        VsEntitySliceTags.ADMIN_ENTITY_DETAIL_DOMAIN,
      ],
    },
    entityManagementControllerUpdateEntityOwner: {
      invalidatesTags: [VsEntitySliceTags.ADMIN_ENTITY_DETAIL_BASIC_INFO],
    },
    profileManagementControllerGetEntityDetail: {
      providesTags: [VsEntitySliceTags.ADMIN_ENTITY_DETAIL_PROFILE],
    },
    profileManagementControllerUpdateEntityProfile: {
      invalidatesTags: [VsEntitySliceTags.ADMIN_ENTITY_DETAIL_PROFILE],
    },
    domainManagementControllerGetDomain: {
      providesTags: [VsEntitySliceTags.ADMIN_ENTITY_DETAIL_DOMAIN],
      transformResponse(resp: DomainManagementControllerGetDomainApiResponse) {
        return orderBy(resp, ['isMain', 'create_time'], ['desc', 'desc']);
      },
    },
    domainManagementControllerSetDomainVerifyType: {
      invalidatesTags: [VsEntitySliceTags.ADMIN_ENTITY_DETAIL_DOMAIN],
    },
    domainManagementControllerSetDomainverify: {
      invalidatesTags: [VsEntitySliceTags.ADMIN_ENTITY_DETAIL_DOMAIN],
    },
    domainManagementControllerAddDomain: {
      invalidatesTags: [VsEntitySliceTags.ADMIN_ENTITY_DETAIL_DOMAIN],
    },
    domainManagementControllerDelete: {
      invalidatesTags: [VsEntitySliceTags.ADMIN_ENTITY_DETAIL_DOMAIN],
    },
    entityUserManagementControllerFindAll: {
      providesTags: [VsEntitySliceTags.ADMIN_ENTITY_DETAIL_USERS],
    },
    entityUserManagementControllerAdd: {
      invalidatesTags: [VsEntitySliceTags.ADMIN_ENTITY_DETAIL_USERS],
    },
    entityUserManagementControllerRemoveUser: {
      invalidatesTags: [VsEntitySliceTags.ADMIN_ENTITY_DETAIL_USERS],
    },
    entityAutoApproverManagementControllerFindAllEntityRequest: {
      providesTags: [VsEntitySliceTags.ENTITY_AUTO_APPROVER_LIST],
    },
    entityAutoApproverManagementControllerAddApprover: {
      invalidatesTags: [VsEntitySliceTags.ENTITY_AUTO_APPROVER_LIST],
    },
    entityAutoApproverManagementControllerDeleteCountryManager: {
      invalidatesTags: [VsEntitySliceTags.ENTITY_AUTO_APPROVER_LIST],
    },
  },
});

function transformPoliciesOrder<T extends { code: string }>(resp: T[]): T[] {
  const orders = [
    'domain_administrator',
    'user_administrator',
    'permission_administrator',
  ];
  orders.reverse();
  resp.sort((a, b) => orders.indexOf(b.code) - orders.indexOf(a.code));
  return resp;
}

export { vsEntityApiEnhanced as vsEntityApiSlice };
export * from './vsEntityApiInjected';

export enum EntitySignupRequestStatus {
  NEW = 'new',
  SUCCESS = 'success',
  REJECT = 'reject',
}

export enum EntitySignupRequestSendStatus {
  NEW = 'new',
  EXPIRED = 'expired',
  SUBMITTED = 'submitted',
  REJECTED = 'rejected',
  SUCCESS = 'success',
  PENDING = 'pending',
}

export type DomainVerifyInfo = {
  mx_priority: number;
  mx_value: string;
  txt_value: string;
};

type AdminEntityProperty = {
  creator: string;
  entity: EntityClass;
  invite_entity_request_id: string;
  request_from: string;
  source: string;
};

export type AdminEntityInfo = {
  area: string;
  create_time: string;
  creator: string;
  domain: string;
  domain_type_code: string;
  entity_id: string;
  generics_code: string;
  id: string;
  is_auto_build: boolean;
  modify_time: string;
  name: string;
  property: AdminEntityProperty;
  request_from: string;
  source: string;
  status: string;
};

export type DomainVerifyType = 'mx' | 'txt';
