import { useTheme } from '@mui/material/styles';
import useMUIMediaQuery from '@mui/material/useMediaQuery';

type ReturnUseMediaQuery = Record<
  | 'isMobile'
  | 'isTablet'
  | 'isLaptop'
  | 'isDesktop'
  | 'isGreaterThanSm'
  | 'isGreaterThanMd'
  | 'isGreaterThanLg'
  | 'isGreaterThanXl',
  boolean
>;

/**
 * Use viewport size info based on Viewsonic portal design guidline's breakpoint
 *
 * @return {ReturnUseMediaQuery}
 */
export const useMediaQuery = (): ReturnUseMediaQuery => {
  const theme = useTheme();
  const isMobile = useMUIMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isTablet = useMUIMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLaptop = useMUIMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isDesktop = useMUIMediaQuery(theme.breakpoints.up('lg'));
  const isGreaterThanSm = useMUIMediaQuery(theme.breakpoints.up('sm'));
  const isGreaterThanMd = useMUIMediaQuery(theme.breakpoints.up('md'));
  const isGreaterThanLg = useMUIMediaQuery(theme.breakpoints.up('lg'));
  const isGreaterThanXl = useMUIMediaQuery(theme.breakpoints.up('xl'));
  return {
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    isGreaterThanSm,
    isGreaterThanMd,
    isGreaterThanLg,
    isGreaterThanXl,
  };
};
