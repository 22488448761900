import { PortalAppLoading } from '@vs/components/portal-app-loading';
import { PortalAppToast } from '@vs/components/portal-app-toast';
import { setOpenDrawer } from '@vs/rtk/slices/genericGlobal';
import { PortalLayout } from '@vs/ui/portal/layout';
import { memo } from 'react';

import Menu from '../../components/menu/menu';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import AppContentRoutes from './AppContentRoutes';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';

function AppContent() {
  const dispatch = useAppDispatch();
  const openDrawer = useAppSelector(s => s.general.openDrawer);

  return (
    <>
      <AppHeader />
      <PortalLayout
        sidebar={<Menu />}
        content={<AppContentRoutes />}
        size="full"
        openDrawer={openDrawer}
        onDrawerClickAway={() => dispatch(setOpenDrawer(false))}
        footer={<AppFooter />}
      />
      <PortalAppLoading />
      <PortalAppToast />
    </>
  );
}

export default memo(AppContent);
