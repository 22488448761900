import { vsEntityApiBase as api } from './vsEntityApiBase';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    entityControllerGetEntity: build.query<
      EntityControllerGetEntityApiResponse,
      EntityControllerGetEntityApiArg
    >({
      query: queryArg => ({ url: `/entity/api/v1/entity/${queryArg.id}` }),
    }),
    entityControllerGetUserEntityList: build.query<
      EntityControllerGetUserEntityListApiResponse,
      EntityControllerGetUserEntityListApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/list`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          sort_order: queryArg.sortOrder,
          sort_field: queryArg.sortField,
          is_default_priority: queryArg.isDefaultPriority,
          name: queryArg.name,
        },
      }),
    }),
    entityControllerSetEntityDefault: build.mutation<
      EntityControllerSetEntityDefaultApiResponse,
      EntityControllerSetEntityDefaultApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/default`,
        method: 'POST',
        body: queryArg.setEntityDefaultDto,
      }),
    }),
    entityUserControllerPolicyInfo: build.query<
      EntityUserControllerPolicyInfoApiResponse,
      EntityUserControllerPolicyInfoApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/user/policy-info`,
      }),
    }),
    entityUserControllerFindAll: build.query<
      EntityUserControllerFindAllApiResponse,
      EntityUserControllerFindAllApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/users`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          sort_order: queryArg.sortOrder,
          sort_field: queryArg.sortField,
          account: queryArg.account,
        },
      }),
    }),
    entityUserControllerRemoveUser: build.mutation<
      EntityUserControllerRemoveUserApiResponse,
      EntityUserControllerRemoveUserApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/users`,
        method: 'DELETE',
        body: queryArg.deleteEntityUserDto,
      }),
    }),
    entityUserControllerCreate: build.mutation<
      EntityUserControllerCreateApiResponse,
      EntityUserControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/users`,
        method: 'POST',
        body: queryArg.userDataArrayDto,
      }),
    }),
    entityUserControllerGetUserPolicies: build.query<
      EntityUserControllerGetUserPoliciesApiResponse,
      EntityUserControllerGetUserPoliciesApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/user/${queryArg.userId}/policy`,
      }),
    }),
    entityUserControllerInvite: build.query<
      EntityUserControllerInviteApiResponse,
      EntityUserControllerInviteApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/invite/user`,
      }),
    }),
    entityUserControllerGetUserByPolicyCode: build.query<
      EntityUserControllerGetUserByPolicyCodeApiResponse,
      EntityUserControllerGetUserByPolicyCodeApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/users/policy/${queryArg.policyCode}`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          sort_order: queryArg.sortOrder,
          account: queryArg.account,
          action: queryArg.action,
        },
      }),
    }),
    entityManagementControllerUpdateEntity: build.mutation<
      EntityManagementControllerUpdateEntityApiResponse,
      EntityManagementControllerUpdateEntityApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity/${queryArg.entityId}`,
        method: 'PUT',
        body: queryArg.updateEntityDto,
      }),
    }),
    entityManagementControllerDeleteEntity: build.mutation<
      EntityManagementControllerDeleteEntityApiResponse,
      EntityManagementControllerDeleteEntityApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity/${queryArg.entityId}`,
        method: 'DELETE',
      }),
    }),
    entityManagementControllerGetEntityDetail: build.query<
      EntityManagementControllerGetEntityDetailApiResponse,
      EntityManagementControllerGetEntityDetailApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity/${queryArg.entityId}`,
      }),
    }),
    entityManagementControllerUpdateEntityOwner: build.mutation<
      EntityManagementControllerUpdateEntityOwnerApiResponse,
      EntityManagementControllerUpdateEntityOwnerApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity/${queryArg.entityId}/change_owner`,
        method: 'POST',
        body: queryArg.setEntityOwnerDto,
      }),
    }),
    entityManagementControllerGetEntityList: build.query<
      EntityManagementControllerGetEntityListApiResponse,
      EntityManagementControllerGetEntityListApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity/list`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          sort_order: queryArg.sortOrder,
          sort_field: queryArg.sortField,
          search: queryArg.search,
        },
      }),
    }),
    signupControllerGetDomainTypeCode: build.query<
      SignupControllerGetDomainTypeCodeApiResponse,
      SignupControllerGetDomainTypeCodeApiArg
    >({
      query: () => ({ url: `/entity/api/v1/entity/signup/entity_domain_type` }),
    }),
    signupControllerGetGenericsCode: build.query<
      SignupControllerGetGenericsCodeApiResponse,
      SignupControllerGetGenericsCodeApiArg
    >({
      query: () => ({ url: `/entity/api/v1/entity/signup/entity_generics` }),
    }),
    signupControllerFindAllEntityRequest: build.query<
      SignupControllerFindAllEntityRequestApiResponse,
      SignupControllerFindAllEntityRequestApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/signup`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          sort_order: queryArg.sortOrder,
          sort_field: queryArg.sortField,
          search: queryArg.search,
        },
      }),
    }),
    signupControllerFindEntityRequest: build.query<
      SignupControllerFindEntityRequestApiResponse,
      SignupControllerFindEntityRequestApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/signup/${queryArg.id}`,
      }),
    }),
    signupControllerSendCreateEntityRequest: build.mutation<
      SignupControllerSendCreateEntityRequestApiResponse,
      SignupControllerSendCreateEntityRequestApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/signup/send`,
        method: 'POST',
        body: queryArg.entityRequestPropertyClass,
      }),
    }),
    signupControllerPublicCreateEntityRequest: build.mutation<
      SignupControllerPublicCreateEntityRequestApiResponse,
      SignupControllerPublicCreateEntityRequestApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/signup/public`,
        method: 'POST',
        body: queryArg.entityRequestPropertyClass,
      }),
    }),
    signupControllerCreate: build.mutation<
      SignupControllerCreateApiResponse,
      SignupControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/signup/create`,
        method: 'POST',
        body: queryArg.entityRequestPropertyClass,
      }),
    }),
    signupControllerFindAllInviteEntityRequest: build.query<
      SignupControllerFindAllInviteEntityRequestApiResponse,
      SignupControllerFindAllInviteEntityRequestApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/signup/invite`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          sort_order: queryArg.sortOrder,
          sort_field: queryArg.sortField,
          search: queryArg.search,
        },
      }),
    }),
    signupControllerCreateInviteEntityRequest: build.mutation<
      SignupControllerCreateInviteEntityRequestApiResponse,
      SignupControllerCreateInviteEntityRequestApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/signup/invite`,
        method: 'POST',
        body: queryArg.inviteEntityRequestBodyDto,
      }),
    }),
    signupControllerCheckInviteEntityRequestById: build.query<
      SignupControllerCheckInviteEntityRequestByIdApiResponse,
      SignupControllerCheckInviteEntityRequestByIdApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/signup/invite/check/${queryArg.id}`,
      }),
    }),
    signupManagementControllerUpdateEntityRequest: build.mutation<
      SignupManagementControllerUpdateEntityRequestApiResponse,
      SignupManagementControllerUpdateEntityRequestApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity_request/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateEntityRequestBodyDto,
      }),
    }),
    signupManagementControllerGetEntityRequestDetail: build.query<
      SignupManagementControllerGetEntityRequestDetailApiResponse,
      SignupManagementControllerGetEntityRequestDetailApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity_request/${queryArg.id}`,
      }),
    }),
    signupManagementControllerFindAllInviteEntityRequest: build.query<
      SignupManagementControllerFindAllInviteEntityRequestApiResponse,
      SignupManagementControllerFindAllInviteEntityRequestApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity_request/invite/${queryArg.id}`,
      }),
    }),
    signupManagementControllerFindAllEntityRequest: build.query<
      SignupManagementControllerFindAllEntityRequestApiResponse,
      SignupManagementControllerFindAllEntityRequestApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity_request`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          sort_order: queryArg.sortOrder,
          sort_field: queryArg.sortField,
          search: queryArg.search,
          kind: queryArg.kind,
        },
      }),
    }),
    signupManagementControllerResend: build.mutation<
      SignupManagementControllerResendApiResponse,
      SignupManagementControllerResendApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity_request/${queryArg.id}/resend`,
        method: 'POST',
      }),
    }),
    signupManagementControllerApprove: build.mutation<
      SignupManagementControllerApproveApiResponse,
      SignupManagementControllerApproveApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity_request/${queryArg.id}/approve`,
        method: 'POST',
      }),
    }),
    signupManagementControllerReject: build.mutation<
      SignupManagementControllerRejectApiResponse,
      SignupManagementControllerRejectApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity_request/${queryArg.id}/reject`,
        method: 'POST',
        body: queryArg.entityRequestReasonDto,
      }),
    }),
    signupManagementControllerPend: build.mutation<
      SignupManagementControllerPendApiResponse,
      SignupManagementControllerPendApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity_request/${queryArg.id}/pend`,
        method: 'POST',
        body: queryArg.entityRequestReasonDto,
      }),
    }),
    userSyncControllerGetEntitySyncList: build.query<
      UserSyncControllerGetEntitySyncListApiResponse,
      UserSyncControllerGetEntitySyncListApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/sync`,
        params: { return_uri: queryArg.returnUri },
      }),
    }),
    userSyncControllerSyncUsers: build.mutation<
      UserSyncControllerSyncUsersApiResponse,
      UserSyncControllerSyncUsersApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/sync/users`,
        method: 'POST',
        body: queryArg.entitySyncUserDto,
      }),
    }),
    userSyncControllerUpdateSyncHistory: build.mutation<
      UserSyncControllerUpdateSyncHistoryApiResponse,
      UserSyncControllerUpdateSyncHistoryApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/sync/history/${queryArg.historyId}/state`,
        method: 'PATCH',
        body: queryArg.entitySyncHistoryStateDto,
      }),
    }),
    userSyncControllerGetSyncHistory: build.query<
      UserSyncControllerGetSyncHistoryApiResponse,
      UserSyncControllerGetSyncHistoryApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/sync/history`,
        params: { state: queryArg.state },
      }),
    }),
    userSyncControllerSetSyncHistory: build.mutation<
      UserSyncControllerSetSyncHistoryApiResponse,
      UserSyncControllerSetSyncHistoryApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/sync/history`,
        method: 'POST',
        body: queryArg.entitySyncHistoryDto,
      }),
    }),
    csvControllerCsvChecker: build.mutation<
      CsvControllerCsvCheckerApiResponse,
      CsvControllerCsvCheckerApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/csv/checker`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    azureControllerGetTenantList: build.query<
      AzureControllerGetTenantListApiResponse,
      AzureControllerGetTenantListApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/azure/tenants`,
      }),
    }),
    azureControllerGetTenantGroups: build.query<
      AzureControllerGetTenantGroupsApiResponse,
      AzureControllerGetTenantGroupsApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/azure/tenant/${queryArg.tenantId}/groups`,
        params: { max_depth: queryArg.maxDepth },
      }),
    }),
    azureControllerGetTenantGroupUsers: build.query<
      AzureControllerGetTenantGroupUsersApiResponse,
      AzureControllerGetTenantGroupUsersApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/azure/tenant/${queryArg.tenantId}/group/${queryArg.groupId}/users`,
      }),
    }),
    azureControllerGetTenantUsers: build.query<
      AzureControllerGetTenantUsersApiResponse,
      AzureControllerGetTenantUsersApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/azure/tenant/${queryArg.tenantId}/users`,
      }),
    }),
    gSuiteControllerGetEntityList: build.query<
      GSuiteControllerGetEntityListApiResponse,
      GSuiteControllerGetEntityListApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/gsuite/domains`,
      }),
    }),
    gSuiteControllerGetDomainUnits: build.query<
      GSuiteControllerGetDomainUnitsApiResponse,
      GSuiteControllerGetDomainUnitsApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/gsuite/units`,
      }),
    }),
    gSuiteControllerGetDomainGroups: build.query<
      GSuiteControllerGetDomainGroupsApiResponse,
      GSuiteControllerGetDomainGroupsApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/gsuite/domain/${queryArg.domainName}/groups`,
      }),
    }),
    gSuiteControllerGetDomainGroupUsers: build.query<
      GSuiteControllerGetDomainGroupUsersApiResponse,
      GSuiteControllerGetDomainGroupUsersApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/gsuite/domain/${queryArg.domainName}/group/${queryArg.groupId}/users`,
      }),
    }),
    gSuiteControllerGetDomainUsers: build.query<
      GSuiteControllerGetDomainUsersApiResponse,
      GSuiteControllerGetDomainUsersApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/gsuite/domain/${queryArg.domainName}/users`,
        params: { orgUnitPath: queryArg.orgUnitPath },
      }),
    }),
    classLinkControllerGetTenant: build.query<
      ClassLinkControllerGetTenantApiResponse,
      ClassLinkControllerGetTenantApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/classlink/tenants/${queryArg.tenantId}`,
      }),
    }),
    classLinkControllerGetTenantList: build.query<
      ClassLinkControllerGetTenantListApiResponse,
      ClassLinkControllerGetTenantListApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/classlink/tenants`,
      }),
    }),
    classLinkControllerGetOrgTypeList: build.query<
      ClassLinkControllerGetOrgTypeListApiResponse,
      ClassLinkControllerGetOrgTypeListApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/classlink/tenant/${queryArg.tenantId}/orgs/types`,
      }),
    }),
    classLinkControllerGetOrgList: build.query<
      ClassLinkControllerGetOrgListApiResponse,
      ClassLinkControllerGetOrgListApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/classlink/tenant/${queryArg.tenantId}/orgs`,
        params: { type: queryArg['type'] },
      }),
    }),
    classLinkControllerGetRoleList: build.query<
      ClassLinkControllerGetRoleListApiResponse,
      ClassLinkControllerGetRoleListApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/classlink/tenant/${queryArg.tenantId}/roles`,
      }),
    }),
    classLinkControllerGetSchoolClassList: build.query<
      ClassLinkControllerGetSchoolClassListApiResponse,
      ClassLinkControllerGetSchoolClassListApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/classlink/tenant/${queryArg.tenantId}/schools/${queryArg.schoolId}/classes`,
      }),
    }),
    classLinkControllerGetSchoolClassUsersList: build.query<
      ClassLinkControllerGetSchoolClassUsersListApiResponse,
      ClassLinkControllerGetSchoolClassUsersListApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/classlink/tenant/${queryArg.tenantId}/schools/${queryArg.schoolId}/classes/${queryArg.classId}/users`,
        params: { type: queryArg['type'] },
      }),
    }),
    classLinkControllerGetAdministratorUsersList: build.query<
      ClassLinkControllerGetAdministratorUsersListApiResponse,
      ClassLinkControllerGetAdministratorUsersListApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/classlink/tenant/${queryArg.tenantId}/administrator/users`,
      }),
    }),
    entityGroupControllerCreateGroup: build.mutation<
      EntityGroupControllerCreateGroupApiResponse,
      EntityGroupControllerCreateGroupApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/group`,
        method: 'POST',
        body: queryArg.createGroupsByEntity,
      }),
    }),
    entityGroupControllerFindAllGroups: build.query<
      EntityGroupControllerFindAllGroupsApiResponse,
      EntityGroupControllerFindAllGroupsApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/group`,
        params: { cur_page: queryArg.curPage, page_size: queryArg.pageSize },
      }),
    }),
    entityGroupControllerDeleteGroup: build.mutation<
      EntityGroupControllerDeleteGroupApiResponse,
      EntityGroupControllerDeleteGroupApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/group`,
        method: 'DELETE',
        body: queryArg.deleteGroupsByEntity,
      }),
    }),
    entityGroupControllerUpdateGroup: build.mutation<
      EntityGroupControllerUpdateGroupApiResponse,
      EntityGroupControllerUpdateGroupApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/group`,
        method: 'PUT',
        body: queryArg.updateGroupsByEntity,
      }),
    }),
    entityGroupControllerFindUserByGroup: build.query<
      EntityGroupControllerFindUserByGroupApiResponse,
      EntityGroupControllerFindUserByGroupApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/group/${queryArg.groupId}/users`,
        params: { page: queryArg.page, per_page: queryArg.perPage },
      }),
    }),
    entityGroupControllerUpdateUserByGroup: build.mutation<
      EntityGroupControllerUpdateUserByGroupApiResponse,
      EntityGroupControllerUpdateUserByGroupApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/group/${queryArg.groupId}/users`,
        method: 'PUT',
        body: queryArg.updateUserByGroupsDto,
      }),
    }),
    entityGroupControllerGetGroupPolicies: build.query<
      EntityGroupControllerGetGroupPoliciesApiResponse,
      EntityGroupControllerGetGroupPoliciesApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/group/${queryArg.groupId}/policies`,
      }),
    }),
    policyControllerFindAll: build.query<
      PolicyControllerFindAllApiResponse,
      PolicyControllerFindAllApiArg
    >({
      query: () => ({ url: `/entity/api/v1/entity/policy/list` }),
    }),
    policyControllerFindAllWithUser: build.query<
      PolicyControllerFindAllWithUserApiResponse,
      PolicyControllerFindAllWithUserApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/policy`,
      }),
    }),
    policyControllerUpdateUserPolicy: build.mutation<
      PolicyControllerUpdateUserPolicyApiResponse,
      PolicyControllerUpdateUserPolicyApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/policy/user`,
        method: 'PUT',
        body: queryArg.updateTargetPolicyDto,
      }),
    }),
    policyControllerUpdateGroupPolicy: build.mutation<
      PolicyControllerUpdateGroupPolicyApiResponse,
      PolicyControllerUpdateGroupPolicyApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/policy/group`,
        method: 'PUT',
        body: queryArg.updateTargetPolicyDto,
      }),
    }),
    domainControllerFindAllDomain: build.query<
      DomainControllerFindAllDomainApiResponse,
      DomainControllerFindAllDomainApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/domain`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          sort_order: queryArg.sortOrder,
          sort_field: queryArg.sortField,
          search: queryArg.search,
          is_verified: queryArg.isVerified,
        },
      }),
    }),
    domainControllerCreateDomain: build.mutation<
      DomainControllerCreateDomainApiResponse,
      DomainControllerCreateDomainApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/domain`,
        method: 'POST',
        body: queryArg.entityDomainRequestDto,
      }),
    }),
    domainControllerVerifyDomain: build.mutation<
      DomainControllerVerifyDomainApiResponse,
      DomainControllerVerifyDomainApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/domain/${queryArg.domainId}/verify/${queryArg.verifyType}`,
        method: 'POST',
      }),
    }),
    domainControllerInspectDomain: build.mutation<
      DomainControllerInspectDomainApiResponse,
      DomainControllerInspectDomainApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/domain/inspect`,
        method: 'POST',
        body: queryArg.entityDomainInspectDto,
      }),
    }),
    domainControllerDelete: build.mutation<
      DomainControllerDeleteApiResponse,
      DomainControllerDeleteApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/domain/${queryArg.domainId}`,
        method: 'DELETE',
      }),
    }),
    domainControllerGetDomainUserList: build.query<
      DomainControllerGetDomainUserListApiResponse,
      DomainControllerGetDomainUserListApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.entityId}/domain/user`,
      }),
    }),
    domainManagementControllerGetDomain: build.query<
      DomainManagementControllerGetDomainApiResponse,
      DomainManagementControllerGetDomainApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/domain/${queryArg.entityId}`,
      }),
    }),
    domainManagementControllerSetDomainverify: build.mutation<
      DomainManagementControllerSetDomainverifyApiResponse,
      DomainManagementControllerSetDomainverifyApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/domain/${queryArg.domainId}/is_verified/${queryArg.isVerified}`,
        method: 'POST',
      }),
    }),
    domainManagementControllerSetDomainVerifyType: build.mutation<
      DomainManagementControllerSetDomainVerifyTypeApiResponse,
      DomainManagementControllerSetDomainVerifyTypeApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/domain/${queryArg.domainId}/verify_type/${queryArg.verifyType}`,
        method: 'POST',
      }),
    }),
    domainManagementControllerAddDomain: build.mutation<
      DomainManagementControllerAddDomainApiResponse,
      DomainManagementControllerAddDomainApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/domain/${queryArg.entityId}/${queryArg.domainName}/${queryArg.verifyType}`,
        method: 'POST',
      }),
    }),
    domainManagementControllerDelete: build.mutation<
      DomainManagementControllerDeleteApiResponse,
      DomainManagementControllerDeleteApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/domain/${queryArg.domainId}`,
        method: 'DELETE',
      }),
    }),
    domainManagementControllerGetDomainWhitelist: build.query<
      DomainManagementControllerGetDomainWhitelistApiResponse,
      DomainManagementControllerGetDomainWhitelistApiArg
    >({
      query: () => ({ url: `/entity/api/v1/management/domain/whitelist` }),
    }),
    domainManagementControllerCreateDomainInWhitelist: build.mutation<
      DomainManagementControllerCreateDomainInWhitelistApiResponse,
      DomainManagementControllerCreateDomainInWhitelistApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/domain/whitelist`,
        method: 'POST',
        body: queryArg.createDomainWhitelistDto,
      }),
    }),
    domainManagementControllerDeleteDomainInWhitelist: build.mutation<
      DomainManagementControllerDeleteDomainInWhitelistApiResponse,
      DomainManagementControllerDeleteDomainInWhitelistApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/domain/whitelist/${queryArg.domainName}`,
        method: 'DELETE',
      }),
    }),
    profileControllerChangeOwner: build.mutation<
      ProfileControllerChangeOwnerApiResponse,
      ProfileControllerChangeOwnerApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/entity/${queryArg.id}/change-owner`,
        method: 'POST',
        body: queryArg.setEntityOwnerDto,
      }),
    }),
    profileManagementControllerUpdateEntityProfile: build.mutation<
      ProfileManagementControllerUpdateEntityProfileApiResponse,
      ProfileManagementControllerUpdateEntityProfileApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/profile`,
        method: 'PUT',
        body: queryArg.updateEntityProfileDto,
      }),
    }),
    profileManagementControllerGetEntityDetail: build.query<
      ProfileManagementControllerGetEntityDetailApiResponse,
      ProfileManagementControllerGetEntityDetailApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/profile/${queryArg.entityId}`,
      }),
    }),
    policyManagementControllerGetPolices: build.query<
      PolicyManagementControllerGetPolicesApiResponse,
      PolicyManagementControllerGetPolicesApiArg
    >({
      query: () => ({ url: `/entity/api/v1/management/policy` }),
    }),
    policyManagementControllerCreatePolicies: build.mutation<
      PolicyManagementControllerCreatePoliciesApiResponse,
      PolicyManagementControllerCreatePoliciesApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/policy`,
        method: 'POST',
        body: queryArg.createPolicyDto,
      }),
    }),
    policyManagementControllerUpdatePolicies: build.mutation<
      PolicyManagementControllerUpdatePoliciesApiResponse,
      PolicyManagementControllerUpdatePoliciesApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/policy`,
        method: 'PATCH',
        body: queryArg.updatePolicyDto,
      }),
    }),
    policyManagementControllerGetPermissions: build.query<
      PolicyManagementControllerGetPermissionsApiResponse,
      PolicyManagementControllerGetPermissionsApiArg
    >({
      query: () => ({ url: `/entity/api/v1/management/policy/permission` }),
    }),
    policyManagementControllerCreatePermissions: build.mutation<
      PolicyManagementControllerCreatePermissionsApiResponse,
      PolicyManagementControllerCreatePermissionsApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/policy/permission`,
        method: 'POST',
        body: queryArg.createPermissionDto,
      }),
    }),
    policyManagementControllerUpdatePermissions: build.mutation<
      PolicyManagementControllerUpdatePermissionsApiResponse,
      PolicyManagementControllerUpdatePermissionsApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/policy/permission`,
        method: 'PATCH',
        body: queryArg.updatePermissionDto,
      }),
    }),
    policyManagementControllerGetActions: build.query<
      PolicyManagementControllerGetActionsApiResponse,
      PolicyManagementControllerGetActionsApiArg
    >({
      query: () => ({ url: `/entity/api/v1/management/policy/action` }),
    }),
    policyManagementControllerGetResources: build.query<
      PolicyManagementControllerGetResourcesApiResponse,
      PolicyManagementControllerGetResourcesApiArg
    >({
      query: () => ({ url: `/entity/api/v1/management/policy/resource` }),
    }),
    regionManagerManagementControllerCreateRegionManager: build.mutation<
      RegionManagerManagementControllerCreateRegionManagerApiResponse,
      RegionManagerManagementControllerCreateRegionManagerApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/region_manager`,
        method: 'POST',
        body: queryArg.regionManagerDto,
      }),
    }),
    regionManagerManagementControllerFindAllEntityRequest: build.query<
      RegionManagerManagementControllerFindAllEntityRequestApiResponse,
      RegionManagerManagementControllerFindAllEntityRequestApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/region_manager`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          sort_order: queryArg.sortOrder,
          sort_field: queryArg.sortField,
          search: queryArg.search,
        },
      }),
    }),
    regionManagerManagementControllerUpdateRegionManager: build.mutation<
      RegionManagerManagementControllerUpdateRegionManagerApiResponse,
      RegionManagerManagementControllerUpdateRegionManagerApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/region_manager/${queryArg.region}`,
        method: 'PUT',
        body: queryArg.updateRegionManagerDto,
      }),
    }),
    regionManagerManagementControllerDeleteCountryManager: build.mutation<
      RegionManagerManagementControllerDeleteCountryManagerApiResponse,
      RegionManagerManagementControllerDeleteCountryManagerApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/region_manager/${queryArg.region}`,
        method: 'DELETE',
      }),
    }),
    countryManagerManagementControllerCreateCountryManager: build.mutation<
      CountryManagerManagementControllerCreateCountryManagerApiResponse,
      CountryManagerManagementControllerCreateCountryManagerApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/country_manager`,
        method: 'POST',
        body: queryArg.countryManagerDto,
      }),
    }),
    countryManagerManagementControllerGetUserByPolicyCode: build.query<
      CountryManagerManagementControllerGetUserByPolicyCodeApiResponse,
      CountryManagerManagementControllerGetUserByPolicyCodeApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/country_manager`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          sort_order: queryArg.sortOrder,
          sort_field: queryArg.sortField,
          search: queryArg.search,
        },
      }),
    }),
    countryManagerManagementControllerUpdateCountryManager: build.mutation<
      CountryManagerManagementControllerUpdateCountryManagerApiResponse,
      CountryManagerManagementControllerUpdateCountryManagerApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/country_manager/${queryArg.country}`,
        method: 'PUT',
        body: queryArg.updateCountryManagerDto,
      }),
    }),
    countryManagerManagementControllerDeleteCountryManager: build.mutation<
      CountryManagerManagementControllerDeleteCountryManagerApiResponse,
      CountryManagerManagementControllerDeleteCountryManagerApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/country_manager/${queryArg.country}`,
        method: 'DELETE',
      }),
    }),
    entityAutoApproverManagementControllerAddApprover: build.mutation<
      EntityAutoApproverManagementControllerAddApproverApiResponse,
      EntityAutoApproverManagementControllerAddApproverApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/auto_approver`,
        method: 'POST',
        body: queryArg.entityAutoApproverDto,
      }),
    }),
    entityAutoApproverManagementControllerFindAllEntityRequest: build.query<
      EntityAutoApproverManagementControllerFindAllEntityRequestApiResponse,
      EntityAutoApproverManagementControllerFindAllEntityRequestApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/auto_approver`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          sort_order: queryArg.sortOrder,
          search: queryArg.search,
          sort_field: queryArg.sortField,
        },
      }),
    }),
    entityAutoApproverManagementControllerDeleteCountryManager: build.mutation<
      EntityAutoApproverManagementControllerDeleteCountryManagerApiResponse,
      EntityAutoApproverManagementControllerDeleteCountryManagerApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/auto_approver/${queryArg.userId}`,
        method: 'DELETE',
      }),
    }),
    entityUserManagementControllerFindAll: build.query<
      EntityUserManagementControllerFindAllApiResponse,
      EntityUserManagementControllerFindAllApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity/${queryArg.entityId}/users`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          sort_order: queryArg.sortOrder,
          sort_field: queryArg.sortField,
          account: queryArg.account,
        },
      }),
    }),
    entityUserManagementControllerRemoveUser: build.mutation<
      EntityUserManagementControllerRemoveUserApiResponse,
      EntityUserManagementControllerRemoveUserApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity/${queryArg.entityId}/users`,
        method: 'DELETE',
        body: queryArg.deleteEntityUserDto,
      }),
    }),
    entityUserManagementControllerAdd: build.mutation<
      EntityUserManagementControllerAddApiResponse,
      EntityUserManagementControllerAddApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity/${queryArg.entityId}/users`,
        method: 'POST',
        body: queryArg.addExistAccountToEntityDto,
      }),
    }),
    entityUserManagementControllerCreate: build.mutation<
      EntityUserManagementControllerCreateApiResponse,
      EntityUserManagementControllerCreateApiArg
    >({
      query: queryArg => ({
        url: `/entity/api/v1/management/entity/${queryArg.entityId}/users/import`,
        method: 'POST',
        body: queryArg.userDataArrayDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as vsEntityApiInjected };
export type EntityControllerGetEntityApiResponse =
  /** status 200  */ GetEntityVo;
export type EntityControllerGetEntityApiArg = {
  id: string;
};
export type EntityControllerGetUserEntityListApiResponse =
  /** status 200  */ GetUserEntityListWithPaginationVo;
export type EntityControllerGetUserEntityListApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** sort order */
  sortOrder?: 'ASC' | 'DESC';
  /** sort by create time */
  sortField?: string;
  /** is default entity priority or not */
  isDefaultPriority?: boolean;
  /** entity name for searching */
  name?: string;
};
export type EntityControllerSetEntityDefaultApiResponse =
  /** status 200  */ SetEntityDefaultResVo;
export type EntityControllerSetEntityDefaultApiArg = {
  setEntityDefaultDto: SetEntityDefaultDto;
};
export type EntityUserControllerPolicyInfoApiResponse =
  /** status 200  */ PolicyDetailVo[];
export type EntityUserControllerPolicyInfoApiArg = {
  entityId: string;
};
export type EntityUserControllerFindAllApiResponse =
  /** status 200  */ EntityUserListVo;
export type EntityUserControllerFindAllApiArg = {
  entityId: string;
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** sort order */
  sortOrder?: 'ASC' | 'DESC';
  /** sort by create time or account */
  sortField?: 'account' | 'create_time';
  /** search email */
  account?: string;
};
export type EntityUserControllerRemoveUserApiResponse = unknown;
export type EntityUserControllerRemoveUserApiArg = {
  entityId: string;
  deleteEntityUserDto: DeleteEntityUserDto;
};
export type EntityUserControllerCreateApiResponse =
  /** status 200  */ ImportResponseDto;
export type EntityUserControllerCreateApiArg = {
  entityId: string;
  userDataArrayDto: UserDataArrayDto;
};
export type EntityUserControllerGetUserPoliciesApiResponse =
  /** status 200  */ EntityUserPolicyVo;
export type EntityUserControllerGetUserPoliciesApiArg = {
  entityId: string;
  userId: string;
};
export type EntityUserControllerInviteApiResponse =
  /** status 200  */ ImportResponseDto;
export type EntityUserControllerInviteApiArg = {
  entityId: string;
};
export type EntityUserControllerGetUserByPolicyCodeApiResponse =
  /** status 200  */ GetEntityUserByPolicyVo;
export type EntityUserControllerGetUserByPolicyCodeApiArg = {
  entityId: string;
  policyCode: string;
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** sort order */
  sortOrder?: 'ASC' | 'DESC';
  /** email for searching */
  account?: string;
  /** if action is include, get user list with specific policy code, if action is exclude, get user list without specific policy code. */
  action?: 'include' | 'exclude';
};
export type EntityManagementControllerUpdateEntityApiResponse =
  /** status 200  */ StardardResVo;
export type EntityManagementControllerUpdateEntityApiArg = {
  entityId: string;
  updateEntityDto: UpdateEntityDto;
};
export type EntityManagementControllerDeleteEntityApiResponse =
  /** status 200  */ StardardResVo;
export type EntityManagementControllerDeleteEntityApiArg = {
  entityId: string;
};
export type EntityManagementControllerGetEntityDetailApiResponse =
  /** status 200  */ AdminGetUserEntityDetailVo;
export type EntityManagementControllerGetEntityDetailApiArg = {
  entityId: string;
};
export type EntityManagementControllerUpdateEntityOwnerApiResponse =
  /** status 200  */ StardardResVo;
export type EntityManagementControllerUpdateEntityOwnerApiArg = {
  entityId: string;
  setEntityOwnerDto: SetEntityOwnerDto;
};
export type EntityManagementControllerGetEntityListApiResponse =
  /** status 200  */ AdminGetUserEntityListWithPaginationVo;
export type EntityManagementControllerGetEntityListApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** sort order */
  sortOrder?: 'ASC' | 'DESC';
  /** sort by create time */
  sortField?: string;
  /** entity name or domain for searching */
  search?: string;
};
export type SignupControllerGetDomainTypeCodeApiResponse =
  /** status 200 use code, present with name */ CodeNameVo[];
export type SignupControllerGetDomainTypeCodeApiArg = void;
export type SignupControllerGetGenericsCodeApiResponse =
  /** status 200 use code, present with name */ CodeNameVo[];
export type SignupControllerGetGenericsCodeApiArg = void;
export type SignupControllerFindAllEntityRequestApiResponse =
  /** status 200  */ GetEntityRequestVo;
export type SignupControllerFindAllEntityRequestApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** sort order */
  sortOrder?: 'ASC' | 'DESC';
  /** sort by name */
  sortField?: string;
  /** search for searching */
  search?: string;
};
export type SignupControllerFindEntityRequestApiResponse =
  /** status 200  */ EntityRequestDto;
export type SignupControllerFindEntityRequestApiArg = {
  id: string;
};
export type SignupControllerSendCreateEntityRequestApiResponse =
  /** status 200  */ EntityRequestPropertyClass;
export type SignupControllerSendCreateEntityRequestApiArg = {
  entityRequestPropertyClass: EntityRequestPropertyClass;
};
export type SignupControllerPublicCreateEntityRequestApiResponse =
  /** status 200  */ EntityRequestPropertyClass;
export type SignupControllerPublicCreateEntityRequestApiArg = {
  entityRequestPropertyClass: EntityRequestPropertyClass;
};
export type SignupControllerCreateApiResponse =
  /** status 200  */ EntityRequestPropertyClass;
export type SignupControllerCreateApiArg = {
  entityRequestPropertyClass: EntityRequestPropertyClass;
};
export type SignupControllerFindAllInviteEntityRequestApiResponse =
  /** status 200  */ GetInviteEntityRequestVo;
export type SignupControllerFindAllInviteEntityRequestApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** sort order */
  sortOrder?: 'ASC' | 'DESC';
  /** sort by source */
  sortField?: string;
  /** search for searching */
  search?: string;
};
export type SignupControllerCreateInviteEntityRequestApiResponse =
  /** status 200  */ InviteEntityRequestBodyDto;
export type SignupControllerCreateInviteEntityRequestApiArg = {
  inviteEntityRequestBodyDto: InviteEntityRequestBodyDto;
};
export type SignupControllerCheckInviteEntityRequestByIdApiResponse =
  /** status 200  */ InviteEntityRequestBodyDto;
export type SignupControllerCheckInviteEntityRequestByIdApiArg = {
  id: string;
};
export type SignupManagementControllerUpdateEntityRequestApiResponse =
  /** status 200  */ EntityRequestDto;
export type SignupManagementControllerUpdateEntityRequestApiArg = {
  id: string;
  updateEntityRequestBodyDto: UpdateEntityRequestBodyDto;
};
export type SignupManagementControllerGetEntityRequestDetailApiResponse =
  /** status 200  */ EntityRequestDetailsDto;
export type SignupManagementControllerGetEntityRequestDetailApiArg = {
  id: string;
};
export type SignupManagementControllerFindAllInviteEntityRequestApiResponse =
  /** status 200  */ InviteEntityRequestDetailsDto;
export type SignupManagementControllerFindAllInviteEntityRequestApiArg = {
  id: string;
};
export type SignupManagementControllerFindAllEntityRequestApiResponse =
  /** status 200  */ GetEntityRequestVo;
export type SignupManagementControllerFindAllEntityRequestApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** sort order */
  sortOrder?: 'ASC' | 'DESC';
  /** sort by name */
  sortField?: string;
  /** search for searching */
  search?: string;
  /** internal or external when admin portal */
  kind?: string;
};
export type SignupManagementControllerResendApiResponse =
  /** status 200  */ StardardResVo;
export type SignupManagementControllerResendApiArg = {
  id: string;
};
export type SignupManagementControllerApproveApiResponse =
  /** status 200 entity onboard request approve by admin */ void;
export type SignupManagementControllerApproveApiArg = {
  id: string;
};
export type SignupManagementControllerRejectApiResponse =
  /** status 200 entity onboard request reject by admin */ void;
export type SignupManagementControllerRejectApiArg = {
  id: string;
  entityRequestReasonDto: EntityRequestReasonDto;
};
export type SignupManagementControllerPendApiResponse =
  /** status 200 pend entity onboard request by admin */ void;
export type SignupManagementControllerPendApiArg = {
  id: string;
  entityRequestReasonDto: EntityRequestReasonDto;
};
export type UserSyncControllerGetEntitySyncListApiResponse =
  /** status 200  */ EntitySyncTypeVo[];
export type UserSyncControllerGetEntitySyncListApiArg = {
  entityId: string;
  returnUri: string;
};
export type UserSyncControllerSyncUsersApiResponse = unknown;
export type UserSyncControllerSyncUsersApiArg = {
  entityId: string;
  entitySyncUserDto: EntitySyncUserDto;
};
export type UserSyncControllerUpdateSyncHistoryApiResponse = unknown;
export type UserSyncControllerUpdateSyncHistoryApiArg = {
  entityId: string;
  historyId: string;
  entitySyncHistoryStateDto: EntitySyncHistoryStateDto;
};
export type UserSyncControllerGetSyncHistoryApiResponse =
  /** status 200  */ EntitySyncHistoryVo[];
export type UserSyncControllerGetSyncHistoryApiArg = {
  entityId: string;
  state?: 'new' | 'processing' | 'complete' | 'canceled' | 'error';
};
export type UserSyncControllerSetSyncHistoryApiResponse =
  /** status 200  */ EntitySyncHistoryVo;
export type UserSyncControllerSetSyncHistoryApiArg = {
  entityId: string;
  entitySyncHistoryDto: EntitySyncHistoryDto;
};
export type CsvControllerCsvCheckerApiResponse = unknown;
export type CsvControllerCsvCheckerApiArg = {
  /** Entity ID */
  entityId: string;
  body: EntitySyncUserCsvDto[];
};
export type AzureControllerGetTenantListApiResponse =
  /** status 200  */ AzureTenantVo[];
export type AzureControllerGetTenantListApiArg = {
  entityId: string;
};
export type AzureControllerGetTenantGroupsApiResponse =
  /** status 200  */ AzureTenantGroupVo[];
export type AzureControllerGetTenantGroupsApiArg = {
  entityId: string;
  tenantId: string;
  /** Max Depth. Default: 3 */
  maxDepth?: number;
};
export type AzureControllerGetTenantGroupUsersApiResponse =
  /** status 200  */ AzureTenantUserVo[];
export type AzureControllerGetTenantGroupUsersApiArg = {
  entityId: string;
  tenantId: string;
  groupId: string;
};
export type AzureControllerGetTenantUsersApiResponse =
  /** status 200  */ AzureTenantUserVo[];
export type AzureControllerGetTenantUsersApiArg = {
  entityId: string;
  tenantId: string;
};
export type GSuiteControllerGetEntityListApiResponse =
  /** status 200  */ GsuiteDomainVo[];
export type GSuiteControllerGetEntityListApiArg = {
  entityId: string;
};
export type GSuiteControllerGetDomainUnitsApiResponse =
  /** status 200  */ GsuiteUnitVo[];
export type GSuiteControllerGetDomainUnitsApiArg = {
  entityId: string;
};
export type GSuiteControllerGetDomainGroupsApiResponse =
  /** status 200  */ GsuiteDomainGroupVo[];
export type GSuiteControllerGetDomainGroupsApiArg = {
  entityId: string;
  domainName: string;
};
export type GSuiteControllerGetDomainGroupUsersApiResponse =
  /** status 200  */ GsuiteDomainUserVo[];
export type GSuiteControllerGetDomainGroupUsersApiArg = {
  entityId: string;
  domainName: string;
  groupId: string;
};
export type GSuiteControllerGetDomainUsersApiResponse =
  /** status 200  */ GsuiteDomainUserVo[];
export type GSuiteControllerGetDomainUsersApiArg = {
  entityId: string;
  domainName: string;
  orgUnitPath?: string;
};
export type ClassLinkControllerGetTenantApiResponse =
  /** status 200  */ ClassLinkTenantVo;
export type ClassLinkControllerGetTenantApiArg = {
  entityId: string;
  tenantId: number;
};
export type ClassLinkControllerGetTenantListApiResponse =
  /** status 200  */ ClassLinkTenantVo[];
export type ClassLinkControllerGetTenantListApiArg = {
  entityId: string;
};
export type ClassLinkControllerGetOrgTypeListApiResponse =
  /** status 200  */ ClassLinkTenantOrgTypeVo[];
export type ClassLinkControllerGetOrgTypeListApiArg = {
  entityId: string;
  tenantId: number;
};
export type ClassLinkControllerGetOrgListApiResponse =
  /** status 200  */ ClassLinkTenantOrgVo[];
export type ClassLinkControllerGetOrgListApiArg = {
  entityId: string;
  tenantId: number;
  type?: string;
};
export type ClassLinkControllerGetRoleListApiResponse =
  /** status 200  */ ClassLinkTenantRoleVo[];
export type ClassLinkControllerGetRoleListApiArg = {
  entityId: string;
  tenantId: number;
};
export type ClassLinkControllerGetSchoolClassListApiResponse =
  /** status 200  */ ClassLinkTenantClassVo[];
export type ClassLinkControllerGetSchoolClassListApiArg = {
  entityId: string;
  tenantId: number;
  schoolId: string;
};
export type ClassLinkControllerGetSchoolClassUsersListApiResponse =
  /** status 200  */ ClassLinkUserVo[];
export type ClassLinkControllerGetSchoolClassUsersListApiArg = {
  entityId: string;
  tenantId: number;
  schoolId: string;
  classId: string;
  type: 'student' | 'teacher';
};
export type ClassLinkControllerGetAdministratorUsersListApiResponse =
  /** status 200  */ ClassLinkUserVo[];
export type ClassLinkControllerGetAdministratorUsersListApiArg = {
  entityId: string;
  tenantId: number;
};
export type EntityGroupControllerCreateGroupApiResponse =
  /** status 200  */ CreateGroupVo[];
export type EntityGroupControllerCreateGroupApiArg = {
  entityId: string;
  createGroupsByEntity: CreateGroupsByEntity;
};
export type EntityGroupControllerFindAllGroupsApiResponse =
  /** status 200  */ EntityGroupListVo;
export type EntityGroupControllerFindAllGroupsApiArg = {
  entityId: string;
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
};
export type EntityGroupControllerDeleteGroupApiResponse = unknown;
export type EntityGroupControllerDeleteGroupApiArg = {
  entityId: string;
  deleteGroupsByEntity: DeleteGroupsByEntity;
};
export type EntityGroupControllerUpdateGroupApiResponse = unknown;
export type EntityGroupControllerUpdateGroupApiArg = {
  entityId: string;
  updateGroupsByEntity: UpdateGroupsByEntity;
};
export type EntityGroupControllerFindUserByGroupApiResponse =
  /** status 200  */ EntityGroupUserListVo;
export type EntityGroupControllerFindUserByGroupApiArg = {
  entityId: string;
  groupId: string;
  page: number;
  perPage: number;
};
export type EntityGroupControllerUpdateUserByGroupApiResponse =
  /** status 200  */ EntityGroupUserListVo;
export type EntityGroupControllerUpdateUserByGroupApiArg = {
  entityId: string;
  groupId: string;
  updateUserByGroupsDto: UpdateUserByGroupsDto;
};
export type EntityGroupControllerGetGroupPoliciesApiResponse =
  /** status 200  */ EntityGroupPolicyVo;
export type EntityGroupControllerGetGroupPoliciesApiArg = {
  entityId: string;
  groupId: string;
};
export type PolicyControllerFindAllApiResponse =
  /** status 200  */ GetEntityPolicyVo[];
export type PolicyControllerFindAllApiArg = void;
export type PolicyControllerFindAllWithUserApiResponse =
  /** status 200  */ GetEntityPolicyWithUserVo[];
export type PolicyControllerFindAllWithUserApiArg = {
  entityId: string;
};
export type PolicyControllerUpdateUserPolicyApiResponse = unknown;
export type PolicyControllerUpdateUserPolicyApiArg = {
  entityId: string;
  updateTargetPolicyDto: UpdateTargetPolicyDto;
};
export type PolicyControllerUpdateGroupPolicyApiResponse = unknown;
export type PolicyControllerUpdateGroupPolicyApiArg = {
  entityId: string;
  updateTargetPolicyDto: UpdateTargetPolicyDto;
};
export type DomainControllerFindAllDomainApiResponse =
  /** status 200  */ PaginationEntityDomainResponseVo;
export type DomainControllerFindAllDomainApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** sort order */
  sortOrder?: 'ASC' | 'DESC';
  /** sort by create time */
  sortField?: string;
  /** search word */
  search?: string;
  /** true or false is_verified of domain */
  isVerified?: string;
  entityId: string;
};
export type DomainControllerCreateDomainApiResponse =
  /** status 200  */ EntityDomainVo;
export type DomainControllerCreateDomainApiArg = {
  entityId: string;
  entityDomainRequestDto: EntityDomainRequestDto;
};
export type DomainControllerVerifyDomainApiResponse =
  /** status 200  */ EntityDomainVo;
export type DomainControllerVerifyDomainApiArg = {
  entityId: string;
  domainId: string;
  verifyType: string;
};
export type DomainControllerInspectDomainApiResponse =
  /** status 200  */ boolean;
export type DomainControllerInspectDomainApiArg = {
  entityDomainInspectDto: EntityDomainInspectDto;
};
export type DomainControllerDeleteApiResponse =
  /** status 200  */ DeleteEntityDomainResponseVo;
export type DomainControllerDeleteApiArg = {
  entityId: string;
  domainId: string;
};
export type DomainControllerGetDomainUserListApiResponse =
  /** status 200  */ DomainUserListWithStatusVo[];
export type DomainControllerGetDomainUserListApiArg = {
  entityId: string;
};
export type DomainManagementControllerGetDomainApiResponse =
  /** status 200  */ AdminEntityDomainVo[];
export type DomainManagementControllerGetDomainApiArg = {
  entityId: string;
};
export type DomainManagementControllerSetDomainverifyApiResponse =
  /** status 200  */ StardardResVo;
export type DomainManagementControllerSetDomainverifyApiArg = {
  domainId: string;
  /** true or false */
  isVerified: string;
};
export type DomainManagementControllerSetDomainVerifyTypeApiResponse =
  /** status 200  */ StardardResVo;
export type DomainManagementControllerSetDomainVerifyTypeApiArg = {
  domainId: string;
  /** mx or txt */
  verifyType: string;
};
export type DomainManagementControllerAddDomainApiResponse =
  /** status 200  */ StardardResVo;
export type DomainManagementControllerAddDomainApiArg = {
  entityId: string;
  domainName: string;
  /** mx or txt */
  verifyType: string;
};
export type DomainManagementControllerDeleteApiResponse =
  /** status 200  */ StardardResVo;
export type DomainManagementControllerDeleteApiArg = {
  domainId: string;
};
export type DomainManagementControllerGetDomainWhitelistApiResponse =
  /** status 200  */ StardardResVo;
export type DomainManagementControllerGetDomainWhitelistApiArg = void;
export type DomainManagementControllerCreateDomainInWhitelistApiResponse =
  /** status 200  */ StardardResVo;
export type DomainManagementControllerCreateDomainInWhitelistApiArg = {
  createDomainWhitelistDto: CreateDomainWhitelistDto;
};
export type DomainManagementControllerDeleteDomainInWhitelistApiResponse =
  /** status 200  */ StardardResVo;
export type DomainManagementControllerDeleteDomainInWhitelistApiArg = {
  domainName: string;
};
export type ProfileControllerChangeOwnerApiResponse =
  /** status 200  */ StardardResVo;
export type ProfileControllerChangeOwnerApiArg = {
  id: string;
  setEntityOwnerDto: SetEntityOwnerDto;
};
export type ProfileManagementControllerUpdateEntityProfileApiResponse =
  /** status 200  */ StardardResVo;
export type ProfileManagementControllerUpdateEntityProfileApiArg = {
  updateEntityProfileDto: UpdateEntityProfileDto;
};
export type ProfileManagementControllerGetEntityDetailApiResponse =
  /** status 200  */ GetEntityProfileDetailVo;
export type ProfileManagementControllerGetEntityDetailApiArg = {
  entityId: string;
};
export type PolicyManagementControllerGetPolicesApiResponse =
  /** status 200  */ GetPoliciesDto[];
export type PolicyManagementControllerGetPolicesApiArg = void;
export type PolicyManagementControllerCreatePoliciesApiResponse = unknown;
export type PolicyManagementControllerCreatePoliciesApiArg = {
  createPolicyDto: CreatePolicyDto;
};
export type PolicyManagementControllerUpdatePoliciesApiResponse = unknown;
export type PolicyManagementControllerUpdatePoliciesApiArg = {
  updatePolicyDto: UpdatePolicyDto;
};
export type PolicyManagementControllerGetPermissionsApiResponse =
  /** status 200  */ GetPermissionsDto[];
export type PolicyManagementControllerGetPermissionsApiArg = void;
export type PolicyManagementControllerCreatePermissionsApiResponse = unknown;
export type PolicyManagementControllerCreatePermissionsApiArg = {
  createPermissionDto: CreatePermissionDto;
};
export type PolicyManagementControllerUpdatePermissionsApiResponse = unknown;
export type PolicyManagementControllerUpdatePermissionsApiArg = {
  updatePermissionDto: UpdatePermissionDto;
};
export type PolicyManagementControllerGetActionsApiResponse =
  /** status 200  */ GetActionsDto[];
export type PolicyManagementControllerGetActionsApiArg = void;
export type PolicyManagementControllerGetResourcesApiResponse =
  /** status 200  */ GetResourcesDto[];
export type PolicyManagementControllerGetResourcesApiArg = void;
export type RegionManagerManagementControllerCreateRegionManagerApiResponse =
  /** status 200  */ RegionManagerVo;
export type RegionManagerManagementControllerCreateRegionManagerApiArg = {
  regionManagerDto: RegionManagerDto;
};
export type RegionManagerManagementControllerFindAllEntityRequestApiResponse =
  /** status 200  */ GetRegionManagerVo;
export type RegionManagerManagementControllerFindAllEntityRequestApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** sort order */
  sortOrder?: 'ASC' | 'DESC';
  /** sort by region */
  sortField?: string;
  /** search for searching */
  search?: string;
};
export type RegionManagerManagementControllerUpdateRegionManagerApiResponse =
  /** status 200  */ RegionManagerVo;
export type RegionManagerManagementControllerUpdateRegionManagerApiArg = {
  region: string;
  updateRegionManagerDto: UpdateRegionManagerDto;
};
export type RegionManagerManagementControllerDeleteCountryManagerApiResponse =
  /** status 200  */ StardardResVo;
export type RegionManagerManagementControllerDeleteCountryManagerApiArg = {
  region: string;
};
export type CountryManagerManagementControllerCreateCountryManagerApiResponse =
  /** status 200  */ CountryManagerVo;
export type CountryManagerManagementControllerCreateCountryManagerApiArg = {
  countryManagerDto: CountryManagerDto;
};
export type CountryManagerManagementControllerGetUserByPolicyCodeApiResponse =
  /** status 200  */ GetCountryManagerVo;
export type CountryManagerManagementControllerGetUserByPolicyCodeApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** sort order */
  sortOrder?: 'ASC' | 'DESC';
  /** sort by country */
  sortField?: string;
  /** search for keywords */
  search?: string;
};
export type CountryManagerManagementControllerUpdateCountryManagerApiResponse =
  /** status 200  */ CountryManagerVo;
export type CountryManagerManagementControllerUpdateCountryManagerApiArg = {
  country: string;
  updateCountryManagerDto: UpdateCountryManagerDto;
};
export type CountryManagerManagementControllerDeleteCountryManagerApiResponse =
  /** status 200  */ StardardResVo;
export type CountryManagerManagementControllerDeleteCountryManagerApiArg = {
  country: string;
};
export type EntityAutoApproverManagementControllerAddApproverApiResponse =
  /** status 200  */ EntityAutoApproverVo;
export type EntityAutoApproverManagementControllerAddApproverApiArg = {
  entityAutoApproverDto: EntityAutoApproverDto;
};
export type EntityAutoApproverManagementControllerFindAllEntityRequestApiResponse =
  /** status 200  */ GetEntityAutoApproverVo;
export type EntityAutoApproverManagementControllerFindAllEntityRequestApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** sort order */
  sortOrder?: 'ASC' | 'DESC';
  /** search for searching */
  search?: string;
  /** sort by email */
  sortField?: string;
};
export type EntityAutoApproverManagementControllerDeleteCountryManagerApiResponse =
  /** status 200  */ StardardResVo;
export type EntityAutoApproverManagementControllerDeleteCountryManagerApiArg = {
  userId: string;
};
export type EntityUserManagementControllerFindAllApiResponse =
  /** status 200  */ EntityUserListVo;
export type EntityUserManagementControllerFindAllApiArg = {
  entityId: string;
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** sort order */
  sortOrder?: 'ASC' | 'DESC';
  /** sort by create time or account */
  sortField?: 'account' | 'create_time';
  /** search email */
  account?: string;
};
export type EntityUserManagementControllerRemoveUserApiResponse = unknown;
export type EntityUserManagementControllerRemoveUserApiArg = {
  entityId: string;
  deleteEntityUserDto: DeleteEntityUserDto;
};
export type EntityUserManagementControllerAddApiResponse =
  /** status 200  */ AddExistAccountToEntityVo[];
export type EntityUserManagementControllerAddApiArg = {
  entityId: string;
  addExistAccountToEntityDto: AddExistAccountToEntityDto;
};
export type EntityUserManagementControllerCreateApiResponse =
  /** status 200  */ ImportResponseDto;
export type EntityUserManagementControllerCreateApiArg = {
  entityId: string;
  userDataArrayDto: UserDataArrayDto;
};
export type GetEntityProfileVo = {
  contact_email: string;
  contact_phone: string;
  owner_id: string;
  zipcode: string;
  country_code: string;
  country: string;
  region: string;
  city: string;
  address: string;
  create_time: string;
  modify_time: string;
  given_name: string;
  family_name: string;
};
export type GetEntityVo = {
  id: string;
  name: string;
  main_domain: string;
  generics_code: string;
  domain_type_code: string;
  area: string;
  create_time: string;
  modify_time: string;
  profile: GetEntityProfileVo;
};
export type GetEntityErrorVo = {
  statusCode: number;
  message: string;
  error: string;
};
export type PaginationMetaResponseDto = {
  current_page: number;
  per_page: number;
  total_items: number;
  total_pages: number;
};
export type GetUserEntityListVo = {
  id: string;
  name: string;
  is_current: boolean;
  is_owner: boolean;
  is_su: boolean;
  policy_codes: any[];
};
export type GetUserEntityListWithPaginationVo = {
  meta: PaginationMetaResponseDto;
  data: GetUserEntityListVo[];
};
export type GetEntityListErrorVo = {
  statusCode: number;
  message: string;
  error: string;
};
export type SetEntityDefaultResVo = {
  statusCode: number;
  message: string;
};
export type SetEntityDefaultErrorVo = {
  statusCode: number;
  message: string;
  error: string;
};
export type SetEntityDefaultDto = {
  /** target entity id */
  entity_id: string;
};
export type PolicyDetailVo = {
  name: string;
  description: string;
  code: string;
};
export type EntityUserListDetail = {
  /** user id */
  id: string;
  account: string;
  email: string;
  display_name: string;
  create_time: string;
  policy_count: number;
  group_count: number;
  is_owner: boolean;
};
export type EntityUserListVo = {
  data: EntityUserListDetail[];
  meta: PaginationMetaResponseDto;
};
export type DeleteEntityUserDto = {
  /** Delete user from entity. (use user_id, not record id) */
  ids: string[];
  all: boolean;
};
export type CountDto = {
  invite: number;
  created: number;
  include: number;
  entity_account: number;
};
export type UserIdEmailVo = {
  id: string;
  email: string;
  account: string;
};
export type ImportResponseDto = {
  invite: boolean;
  count: CountDto;
  data: UserIdEmailVo[];
};
export type ImportError = {
  row: number;
  error: string;
};
export type ImportUserExceptionVo = {
  code:
    | 'IMPORT_USER_ERROR_ENTITY_NOT_FOUND'
    | 'IMPORT_USER_ERROR_PROFILE_NOT_FOUND'
    | 'IMPORT_USER_ERROR_IMPORT_FAILED'
    | 'IMPORT_USER_ERROR_SOMETHING_WRONG';
  message: ImportError;
};
export type UserDataDto = {
  email: string;
  first_name: string;
  last_name: string;
};
export type UserDataArrayDto = {
  userData: UserDataDto[];
};
export type EntityUserPolicyVo = {
  /** user id */
  id: string;
  given_name: string;
  family_name: string;
  email: string;
  account: string;
  policies: PolicyDetailVo[];
};
export type GetEntityUserByPolicyDetail = {
  /** user id */
  id: string;
  email: string;
  account: string;
  is_owner: boolean;
};
export type GetEntityUserByPolicyVo = {
  data: GetEntityUserByPolicyDetail[];
  meta: PaginationMetaResponseDto;
};
export type StardardResVo = {
  statusCode: number;
  message: string;
};
export type ResponseErrorVo = {
  statusCode: number;
  message: string;
  error: string;
};
export type UpdateEntityDto = {
  /** entity name */
  name: string;
  /** main_domain */
  main_domain: string;
  /** entity domain type */
  domain_type_code: string;
  /** entity generics code */
  generics_code: string;
  /** entity area(region) */
  area: string;
};
export type EntityDetailProfileVo = {
  entity_id: string;
  contact_email: string;
  owner_id: string;
};
export type EntityDetailEntityRequestVo = {
  id: string;
  request_from: string;
};
export type AdminGetUserEntityDetailVo = {
  id: string;
  name: string;
  main_domain: string;
  generics_code: string;
  domain_type_code: string;
  area: string;
  create_time: string;
  profile: EntityDetailProfileVo;
  entity_request: EntityDetailEntityRequestVo;
  entity_request_id: string;
  owner: string;
  owner_id: string;
};
export type SetEntityOwnerDto = {
  /** target owner account */
  account: string;
  /** target user account id */
  user_id: string;
};
export type AdminGetUserEntityListVo = {
  id: string;
  name: string;
  main_domain: string;
  generics_code: string;
  domain_type_code: string;
  area: string;
  create_time: string;
};
export type AdminGetUserEntityListWithPaginationVo = {
  meta: PaginationMetaResponseDto;
  data: AdminGetUserEntityListVo[];
};
export type CodeNameVo = {
  code: string;
  name: string;
};
export type ProfileClass = {
  contact_phone: string;
  contact_email: string;
  zip_code: string;
  country_code: string;
  country: string;
  region: string;
  city: string;
  address: string;
};
export type OwnerContact = {
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  contact_phone: string;
};
export type EntityClass = {
  name: string;
  generics_code: string;
  domain_type_code: string;
  area: string;
  profile: ProfileClass;
  /** Please use the email of owner or admin(aaa@domain.com, please use domain.com) */
  domain: string;
  owner_contact: OwnerContact;
  admin_contact: OwnerContact[];
};
export type EntityRequestPropertyClass = {
  /** User click the signup url from email. */
  invite_entity_request_id: string;
  /** create or send or public */
  request_from: string;
  /** organic or msp or admin_portal */
  source: string;
  creator: string;
  entity: EntityClass;
};
export type EntityRequestDto = {
  id: string;
  name: string;
  domain: string;
  property: EntityRequestPropertyClass;
  domain_type_code: string;
  generics_code: string;
  area: string;
  is_auto_build: boolean;
  creator: string;
  status: string;
  entity_id: string;
  request_form: string;
  source: string;
  create_time: string;
};
export type GetEntityRequestVo = {
  data: EntityRequestDto[];
  meta: PaginationMetaResponseDto;
};
export type EntityRequestExceptionVo = {
  code:
    | 'ENTITY_REQUEST_EXIST_SAME_DOMAIN_ENTITY_REQUEST'
    | 'ENTITY_REQUEST_EXIST_SAME_NAME_ENTITY_REQUEST'
    | 'ENTITY_REQUEST_INVITE_INVALID'
    | 'ENTITY_REQUEST_NO_AREA'
    | 'ENTITY_REQUEST_OTHER_ERROR'
    | 'ENTITY_REQUEST_OWNER_EMAIL_WRONG'
    | 'ENTITY_REQUEST_WRONG_PATH'
    | 'ENTITY_DOMAIN_DOMAIN_RECORD_ERROR'
    | 'ENTITY_DOMAIN_DUPLICATE_INDIVIDUAL_DOMAIN'
    | 'ENTITY_DOMAIN_DUPLICATE_SHARED_DOMAIN';
  message: string;
};
export type CombineResponseDto = {
  id?: string;
  creator: string;
  user_email: string;
  request_from: string;
  source: string;
  /** Can be empty when send mail to user */
  property?: EntityRequestPropertyClass;
  expire_date?: string;
  entity_request_id?: string;
  create_time?: string;
  /** the organization of figma */
  entityRequestName?: string;
  /** the status of entity request */
  entityRequestStatus?: string;
  /** the status of figma */
  sendUIStatus?: object;
};
export type GetInviteEntityRequestVo = {
  data: CombineResponseDto[];
  meta: PaginationMetaResponseDto;
};
export type InviteEntityRequestBodyDto = {
  id?: string;
  creator: string;
  user_email: string;
  request_from: string;
  source: string;
  /** Can be empty when send mail to user */
  property?: EntityRequestPropertyClass;
  expire_date?: string;
  entity_request_id?: string;
  create_time?: string;
};
export type EntitySignupActionErrorVo = {
  statusCode: number;
  message: string;
  error: string;
};
export type UpdateEntityRequestBodyDto = {
  name: string;
  domain: string;
  property: EntityRequestPropertyClass;
  domain_type_code: string;
  generics_code: string;
  area: string;
  is_auto_build: boolean;
  status: string;
  entity_id: string;
  request_form: string;
  source: string;
};
export type EntityRequestDetailsDto = {
  id: string;
  name: string;
  domain: string;
  property: EntityRequestPropertyClass;
  domain_type_code: string;
  generics_code: string;
  area: string;
  is_auto_build: boolean;
  creator: string;
  status: string;
  entity_id: string;
  request_form: string;
  source: string;
  create_time: string;
  creatorEmail: string;
};
export type InviteEntityRequestDetailsDto = {
  id?: string;
  creator: string;
  user_email: string;
  request_from: string;
  source: string;
  /** Can be empty when send mail to user */
  property?: EntityRequestPropertyClass;
  expire_date?: string;
  entity_request_id?: string;
  create_time?: string;
  /** the organization of figma */
  entityRequestName?: string;
  /** the status of entity request */
  entityRequestStatus?: string;
  /** the status of figma */
  sendUIStatus?: object;
  creatorEmail: string;
};
export type EntityRequestReasonDto = {
  /** fill reason when reject or pend entity request */
  reason: string;
};
export type EntitySyncTypeVo = {
  name: string;
  code: string;
  url?: string;
  token?: string;
};
export type Metadata = {
  sync_type_code:
    | 'regular'
    | 'system'
    | 'csv'
    | 'csv_admin'
    | 'gsuite'
    | 'azure'
    | 'azure_scim'
    | 'yaml'
    | 'classlink';
  history_id: string;
};
export type SyncUsers = {
  sso_id: string;
  email: string;
  display_name?: string;
  given_name: string;
  family_name: string;
  preferredLanguage: string;
};
export type EntitySyncUserDto = {
  metadata: Metadata;
  users: SyncUsers[];
};
export type EntitySyncHistoryStateDto = {
  state: 'new' | 'processing' | 'complete' | 'canceled' | 'error';
  detail?: string;
};
export type EntitySyncHistoryInfoVo = {
  id: string;
  name: string;
  verified_domains: string[];
  batch_count: number;
  total_batch_count: number;
  user_count: number;
  total_user: number;
};
export type EntitySyncHistoryVo = {
  id: string;
  info: EntitySyncHistoryInfoVo;
  state: string;
  keep_users: boolean;
  create_time: string;
  modify_time: string;
};
export type EntitySyncHistoryInfoDto = {
  id?: string;
  name?: string;
  verified_domains: string[];
  total_batch_count: number;
  total_user: number;
};
export type EntitySyncHistoryDto = {
  sync_type_code: string;
  info: EntitySyncHistoryInfoDto;
  keep_users: boolean;
};
export type EntitySyncUserCsvDto = {
  email: string;
  given_name: string;
  family_name: string;
  preferredLanguage: string;
};
export type AzureTenantVo = {
  id: string;
  name: string;
  verified_domains: any[];
};
export type AzureError = {
  statusCode?: number;
  message?: string;
};
export type AzureTenantGroupVo = {
  id: string;
  name: string;
  description: string;
  /** children is self object */
  children: string[];
};
export type AzureTenantUserVo = {
  sso_id: string;
  email: string;
  display_name?: string;
  given_name?: string;
  family_name?: string;
  preferredLanguage: string;
};
export type GsuiteVerifiedDomainsVo = {
  name: string;
  type?: string;
};
export type GsuiteDomainVo = {
  id: string;
  name: string;
  verified_domains: GsuiteVerifiedDomainsVo[];
};
export type GsuiteUnitVo = {
  id: string;
  path: string;
  name: string;
  description: string;
};
export type GsuiteDomainGroupVo = {
  id: string;
  name: string;
};
export type GsuiteDomainUserVo = {
  sso_id: string;
  email: string;
  display_name?: string;
  given_name: string;
  family_name: string;
  preferredLanguage: string;
};
export type ClassLinkTenantVo = {
  id: string;
  name: string;
  tenant_name: string;
  verified_domains: any[];
};
export type ClassLinkTenantOrgTypeVo = {
  id: string;
  name: string;
};
export type ClassLinkTenantOrgVo = {
  id: string;
  name: string;
  type: string;
};
export type ClassLinkTenantRoleVo = {
  id: string;
  name: string;
};
export type ClassLinkTenantClassVo = {
  id: string;
  name: string;
};
export type ClassLinkUserVo = {
  sso_id: string;
  email: string;
  display_name?: string;
  given_name?: string;
  family_name?: string;
  preferredLanguage: string;
};
export type CreateGroupDetail = {
  id: string;
  entity_id: string;
  name?: string;
};
export type CreateGroupVo = {
  data: CreateGroupDetail[];
};
export type CreateGroupsByEntity = {
  /** group name */
  data: string[];
};
export type EntityGroupListDetail = {
  /** group_id */
  id: string;
  name: string;
};
export type EntityGroupListVo = {
  data: EntityGroupListDetail[];
  meta: PaginationMetaResponseDto;
};
export type DeleteGroupsByEntity = {
  /** group id */
  data: string[];
};
export type UpdateGroupDetail = {
  /** entity group id */
  id: string;
  name: string;
};
export type UpdateGroupsByEntity = {
  /** group id */
  data: UpdateGroupDetail[];
};
export type EntityGroupUserListDetail = {
  /** user id */
  id: string;
  account: string;
  email: string;
};
export type EntityGroupUserListVo = {
  data: EntityGroupUserListDetail[];
  meta: PaginationMetaResponseDto;
};
export type UpdateUserByGroupsDto = {
  /** Add user into group. */
  add: string[];
  /** Remove user from group */
  remove: string[];
};
export type EntityGroupPolicyVo = {
  /** user id */
  id: string;
  name: string;
  policies: PolicyDetailVo[];
};
export type GetEntityPolicyVo = {
  name: string;
  description: string;
  permission_code: string[];
  code: string;
};
export type GetEntityPolicyWithUserVo = {
  name: string;
  description: string;
  permission_code: string[];
  code: string;
  count: number;
};
export type UpdateTargetPolicyDto = {
  /** user or group id list. */
  ids: string[];
  action: 'add' | 'remove';
  /** Policy code */
  policy_code: string;
};
export type EntityDomainVo = {
  id: string;
  entity_id: string;
  domain_name: string;
  verify_type: object;
  verify_info: object;
  is_verified: boolean;
  create_time: string;
  modify_time: string;
};
export type PaginationEntityDomainResponseVo = {
  count: number;
  rows: EntityDomainVo[];
};
export type EntityDomainRequestDto = {
  domain_name: string;
};
export type EntityDomainExceptionVo = {
  code:
    | 'ENTITY_DOMAIN_DOMAIN_RECORD_ERROR'
    | 'ENTITY_DOMAIN_DUPLICATE_INDIVIDUAL_DOMAIN'
    | 'ENTITY_DOMAIN_DUPLICATE_SHARED_DOMAIN';
  message: string;
};
export type EntityDomainInspectDto = {
  domain_name: string;
  domain_type_code: 'individual' | 'shared' | 'public';
  owner_email: string;
};
export type DeleteEntityDomainResponseVo = {
  success: boolean;
};
export type DomainUserListWithStatusVo = {
  id: string;
  email: string;
  status: 'active' | 'inactive' | 'locked' | 'suspended' | 'deleted';
};
export type DomainVerifyInfo = {
  mx_value: string;
  mx_priority: number;
  txt_value: string;
};
export type EntityVo = {
  id: string;
  main_domain: string;
};
export type AdminEntityDomainVo = {
  id: string;
  entity_id: string;
  domain_name: string;
  verify_type: string;
  verify_info: DomainVerifyInfo;
  is_verified: boolean;
  create_time: string;
  modify_time: string;
  isMain: boolean;
  entity: EntityVo;
};
export type CreateDomainWhitelistDto = {
  domain_name: string;
};
export type UpdateEntityProfileDto = {
  /** target entity id */
  entity_id: string;
  /** country_code */
  country_code: string;
  /** country */
  country: string;
  /** region */
  region: string;
  /** city */
  city: string;
  /** address */
  address: string;
  /** 831234 */
  zipcode: string;
};
export type GetEntityProfileDetailVo = {
  entity_id: string;
  contact_email: string;
  contact_phone: string;
  owner_id: string;
  zipcode: string;
  country_code: string;
  country: string;
  region: string;
  city: string;
  address: string;
  create_time: string;
};
export type GetPoliciesActionDetail = {
  id: string;
  code: string;
  name: string;
};
export type GetPoliciesResourceDetail = {
  id: string;
  code: string;
  description: string;
};
export type GetPoliciesDetail = {
  id: string;
  level: string;
  code: string;
  action: GetPoliciesActionDetail;
  resource: GetPoliciesResourceDetail;
};
export type GetPoliciesDto = {
  code: string;
  name: string;
  description: string;
  permission: GetPoliciesDetail[];
};
export type CreatePolicyDto = {
  code: string;
  name: string;
  description: string;
  permission_id: string;
};
export type UpdatePolicyDto = {
  code: string;
  name: string;
  description: string;
  permission_id: string;
};
export type GetPermissionsDto = {
  id: string;
  level: string;
  code: string;
};
export type CreatePermissionDto = {
  level: string;
  code: string;
  action_id: string;
  resource_id: string;
};
export type UpdatePermissionDto = {
  id: string;
  level: string;
  code: string;
  action_id: string;
  resource_id: string;
};
export type GetActionsDto = {
  id: string;
  code: string;
  name: string;
};
export type GetResourcesDto = {
  id: string;
  code: string;
  description: string;
};
export type ManagerUser = {
  email: string;
  user_id: string;
};
export type RegionManagerVo = {
  region: string;
  responsible_person: ManagerUser;
  ref_responsible_person: ManagerUser[];
  ref_super_admin: ManagerUser[];
  workplace_channel_id: string[];
  create_time?: string;
};
export type RegionManagerDto = {
  region: string;
  responsible_person: ManagerUser;
  ref_responsible_person: ManagerUser[];
  ref_super_admin: ManagerUser[];
  workplace_channel_id: string[];
};
export type GetRegionManagerVo = {
  data: RegionManagerVo[];
  meta: PaginationMetaResponseDto;
};
export type UpdateRegionManagerDto = {
  responsible_person: ManagerUser;
  ref_responsible_person: ManagerUser[];
  ref_super_admin: ManagerUser[];
  workplace_channel_id: string[];
};
export type CountryManagerVo = {
  country: string;
  responsible_person: ManagerUser;
  ref_responsible_person: ManagerUser[];
  ref_super_admin: ManagerUser[];
  create_time?: string;
};
export type CountryManagerDto = {
  country: string;
  responsible_person: ManagerUser;
  ref_responsible_person: ManagerUser[];
  ref_super_admin: ManagerUser[];
};
export type GetCountryManagerVo = {
  data: CountryManagerVo[];
  meta: PaginationMetaResponseDto;
};
export type UpdateCountryManagerDto = {
  responsible_person: ManagerUser;
  ref_responsible_person: ManagerUser[];
  ref_super_admin: ManagerUser[];
};
export type EntityAutoApproverVo = {
  email: string;
  user_id: string;
  create_time: string;
};
export type EntityAutoApproverDto = {
  email: string;
  user_id: string;
};
export type GetEntityAutoApproverVo = {
  data: EntityAutoApproverVo[];
  meta: PaginationMetaResponseDto;
};
export type AddExistAccountToEntityVo = {
  /** Entity user id */
  id: string;
  entity_id: string;
  /** Account id */
  user_id: string;
  account: string;
  sync_time: string;
  /** Synchronization code */
  sync_code:
    | 'regular'
    | 'system'
    | 'csv'
    | 'csv_admin'
    | 'gsuite'
    | 'azure'
    | 'azure_scim'
    | 'yaml'
    | 'classlink';
  client_id: string;
};
export type AddExistUserDataDto = {
  id: string;
  account: string;
};
export type AddExistAccountToEntityDto = {
  data: AddExistUserDataDto[];
};
export const {
  useEntityControllerGetEntityQuery,
  useEntityControllerGetUserEntityListQuery,
  useEntityControllerSetEntityDefaultMutation,
  useEntityUserControllerPolicyInfoQuery,
  useEntityUserControllerFindAllQuery,
  useEntityUserControllerRemoveUserMutation,
  useEntityUserControllerCreateMutation,
  useEntityUserControllerGetUserPoliciesQuery,
  useEntityUserControllerInviteQuery,
  useEntityUserControllerGetUserByPolicyCodeQuery,
  useEntityManagementControllerUpdateEntityMutation,
  useEntityManagementControllerDeleteEntityMutation,
  useEntityManagementControllerGetEntityDetailQuery,
  useEntityManagementControllerUpdateEntityOwnerMutation,
  useEntityManagementControllerGetEntityListQuery,
  useSignupControllerGetDomainTypeCodeQuery,
  useSignupControllerGetGenericsCodeQuery,
  useSignupControllerFindAllEntityRequestQuery,
  useSignupControllerFindEntityRequestQuery,
  useSignupControllerSendCreateEntityRequestMutation,
  useSignupControllerPublicCreateEntityRequestMutation,
  useSignupControllerCreateMutation,
  useSignupControllerFindAllInviteEntityRequestQuery,
  useSignupControllerCreateInviteEntityRequestMutation,
  useSignupControllerCheckInviteEntityRequestByIdQuery,
  useSignupManagementControllerUpdateEntityRequestMutation,
  useSignupManagementControllerGetEntityRequestDetailQuery,
  useSignupManagementControllerFindAllInviteEntityRequestQuery,
  useSignupManagementControllerFindAllEntityRequestQuery,
  useSignupManagementControllerResendMutation,
  useSignupManagementControllerApproveMutation,
  useSignupManagementControllerRejectMutation,
  useSignupManagementControllerPendMutation,
  useUserSyncControllerGetEntitySyncListQuery,
  useUserSyncControllerSyncUsersMutation,
  useUserSyncControllerUpdateSyncHistoryMutation,
  useUserSyncControllerGetSyncHistoryQuery,
  useUserSyncControllerSetSyncHistoryMutation,
  useCsvControllerCsvCheckerMutation,
  useAzureControllerGetTenantListQuery,
  useAzureControllerGetTenantGroupsQuery,
  useAzureControllerGetTenantGroupUsersQuery,
  useAzureControllerGetTenantUsersQuery,
  useGSuiteControllerGetEntityListQuery,
  useGSuiteControllerGetDomainUnitsQuery,
  useGSuiteControllerGetDomainGroupsQuery,
  useGSuiteControllerGetDomainGroupUsersQuery,
  useGSuiteControllerGetDomainUsersQuery,
  useClassLinkControllerGetTenantQuery,
  useClassLinkControllerGetTenantListQuery,
  useClassLinkControllerGetOrgTypeListQuery,
  useClassLinkControllerGetOrgListQuery,
  useClassLinkControllerGetRoleListQuery,
  useClassLinkControllerGetSchoolClassListQuery,
  useClassLinkControllerGetSchoolClassUsersListQuery,
  useClassLinkControllerGetAdministratorUsersListQuery,
  useEntityGroupControllerCreateGroupMutation,
  useEntityGroupControllerFindAllGroupsQuery,
  useEntityGroupControllerDeleteGroupMutation,
  useEntityGroupControllerUpdateGroupMutation,
  useEntityGroupControllerFindUserByGroupQuery,
  useEntityGroupControllerUpdateUserByGroupMutation,
  useEntityGroupControllerGetGroupPoliciesQuery,
  usePolicyControllerFindAllQuery,
  usePolicyControllerFindAllWithUserQuery,
  usePolicyControllerUpdateUserPolicyMutation,
  usePolicyControllerUpdateGroupPolicyMutation,
  useDomainControllerFindAllDomainQuery,
  useDomainControllerCreateDomainMutation,
  useDomainControllerVerifyDomainMutation,
  useDomainControllerInspectDomainMutation,
  useDomainControllerDeleteMutation,
  useDomainControllerGetDomainUserListQuery,
  useDomainManagementControllerGetDomainQuery,
  useDomainManagementControllerSetDomainverifyMutation,
  useDomainManagementControllerSetDomainVerifyTypeMutation,
  useDomainManagementControllerAddDomainMutation,
  useDomainManagementControllerDeleteMutation,
  useDomainManagementControllerGetDomainWhitelistQuery,
  useDomainManagementControllerCreateDomainInWhitelistMutation,
  useDomainManagementControllerDeleteDomainInWhitelistMutation,
  useProfileControllerChangeOwnerMutation,
  useProfileManagementControllerUpdateEntityProfileMutation,
  useProfileManagementControllerGetEntityDetailQuery,
  usePolicyManagementControllerGetPolicesQuery,
  usePolicyManagementControllerCreatePoliciesMutation,
  usePolicyManagementControllerUpdatePoliciesMutation,
  usePolicyManagementControllerGetPermissionsQuery,
  usePolicyManagementControllerCreatePermissionsMutation,
  usePolicyManagementControllerUpdatePermissionsMutation,
  usePolicyManagementControllerGetActionsQuery,
  usePolicyManagementControllerGetResourcesQuery,
  useRegionManagerManagementControllerCreateRegionManagerMutation,
  useRegionManagerManagementControllerFindAllEntityRequestQuery,
  useRegionManagerManagementControllerUpdateRegionManagerMutation,
  useRegionManagerManagementControllerDeleteCountryManagerMutation,
  useCountryManagerManagementControllerCreateCountryManagerMutation,
  useCountryManagerManagementControllerGetUserByPolicyCodeQuery,
  useCountryManagerManagementControllerUpdateCountryManagerMutation,
  useCountryManagerManagementControllerDeleteCountryManagerMutation,
  useEntityAutoApproverManagementControllerAddApproverMutation,
  useEntityAutoApproverManagementControllerFindAllEntityRequestQuery,
  useEntityAutoApproverManagementControllerDeleteCountryManagerMutation,
  useEntityUserManagementControllerFindAllQuery,
  useEntityUserManagementControllerRemoveUserMutation,
  useEntityUserManagementControllerAddMutation,
  useEntityUserManagementControllerCreateMutation,
} = injectedRtkApi;
