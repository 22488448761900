import { setOpenDrawer } from '@vs/rtk/slices/genericGlobal';
import { PortalBackButton } from '@vs/ui/portal/back-btn';
import { PortalMenu } from '@vs/ui/portal/menu';
import { useMediaQuery } from '@vs/utils/mui';
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useBackButton } from './useBackButton';
import { useMenuItems } from './useMenuItems';

function Menu() {
  const dispatch = useAppDispatch();
  const openDrawer = useAppSelector(s => s.general.openDrawer);
  const menuItemList = useMenuItems();
  const { isMobile, isTablet } = useMediaQuery();
  const { shouldShow, pathname, name } = useBackButton();
  const navigate = useNavigate();
  const { search } = useLocation();

  if (!shouldShow || openDrawer) {
    return (
      <PortalMenu
        menuItemList={menuItemList}
        open={openDrawer}
        onClick={() => {
          if (isMobile || isTablet) {
            dispatch(setOpenDrawer(false));
          }
        }}
      />
    );
  }

  return (
    <PortalBackButton
      onClick={() => navigate({ pathname: `${pathname}`, search })}
    >
      {name}
    </PortalBackButton>
  );
}

export default memo(Menu);
