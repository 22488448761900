import { useCallback } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../redux/hooks';

export default function useAppNavigate() {
  const selectedManagement = useAppSelector(s => s.global.selectedManagement);
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    ((args, options) => {
      if (typeof args === 'number') {
        return navigate(args);
      } else if (typeof args === 'string') {
        return navigate(`/${selectedManagement}${args}`);
      } else {
        return navigate(
          {
            ...args,
            pathname: `/${selectedManagement}${args.pathname}`,
          },
          options
        );
      }
    }) as NavigateFunction,
    [navigate, selectedManagement]
  );
}
