import { matchPath, useLocation } from 'react-router-dom';

import { Path, getPathConfigs } from '../../constants/pathConfigs';
import { useAppSelector } from '../../redux/hooks';

export function useBackButton(): {
  shouldShow: boolean;
  name?: string;
  pathname?: string;
} {
  const selectedManagement = useAppSelector(s => s.global.selectedManagement);
  const { pathname } = useLocation();

  const findActivePath = (
    paths: Path[],
    parentPath: Path['path'],
    level = 0
  ): { path: Path; parent: Path; level: number } | undefined => {
    let activePath: Path | undefined;
    let activeParentPath: Path | undefined;

    for (const path of paths) {
      const pathForNavigation = `${parentPath}${path.path}`;
      const match = matchPath(pathForNavigation, pathname);

      if (match) {
        // no use of back button if path is shown on menu
        if (level === 0 || !path.hide) break;

        activePath = { ...path, path: pathForNavigation };
        activeParentPath = { ...path, path: parentPath };
        break;
      }

      if (path.childViews) {
        const result = findActivePath(
          path.childViews,
          pathForNavigation,
          level + 1
        );

        if (result) {
          activePath = result.path;
          activeParentPath = result.parent;
          level = result.level;
          break;
        }
      }
    }

    return activePath
      ? {
          path: activePath,
          parent: activeParentPath as Path,
          level: level,
        }
      : undefined;
  };

  const activePath = findActivePath(
    getPathConfigs(selectedManagement),
    `/${selectedManagement}`
  );

  if (!activePath)
    return {
      shouldShow: false,
    };

  return {
    shouldShow: true,
    name: activePath.parent.name,
    pathname: activePath.parent.path,
  };
}
