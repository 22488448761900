import {
  AppClientFederationVo,
  ListFederationOidcSettingVo,
  vsAdminApiInjected,
} from './vsAdminApiInjected';

enum VsAdminSliceTags {
  ADMIN_USER_LIST = 'ADMIN_USER_LIST',
  ADMIN_CLIENT_LIST = 'ADMIN_CLIENT_LIST',
  ADMIN_CLIENT_DETAIL = 'ADMIN_CLIENT_DETAIL',
  APP_CLIENT_LIST = 'APP_CLIENT_LIST',
  APP_CLIENT_DETAIL = 'APP_CLIENT_DETAIL',
  PERMISSION_LIST = 'PERMISSION_LIST',
  PERMISSION_ACTION_LIST = 'PERMISSION_ACTION_LIST',
  PERMISSION_RESOURCE_LIST = 'PERMISSION_RESOURCE_LIST',
  PERMISSION_SERVICE_LIST = 'PERMISSION_SERVICE_LIST',
  ACCOUNT_PERMISSION_LIST = 'ACCOUNT_PERMISSION_LIST',
  ACCOUNT_USER_PERMISSION_LIST = 'ACCOUNT_USER_PERMISSION_LIST',
  CLIENT_PERMISSION_LIST = 'CLIENT_PERMISSION_LIST',
  MAIL_TEMPLATE_LIST = 'MAIL_TEMPLATE_LIST',
  MAIL_TEMPLATE_COMPONENT_DETAIL = 'MAIL_TEMPLATE_COMPONENT_DETAIL',
  MAIL_CONTRIBUTOR_LIST = 'MAIL_CONTRIBUTOR_LIST',
  MAIL_LOG_LIST = 'MAIL_LOG_LIST',
  MAIL_BLOCK_LIST = 'MAIL_BLOCK_LIST',
  FEDERATION_LIST = 'FEDERATION_LIST',
  APP_CLIENT_FEDERATION_LIST = 'APP_CLIENT_FEDERATION_LIST',
  MESSAGE_TEMPLATE_LIST = 'MESSAGE_TEMPLATE_LIST',
  MESSAGE_HISTORY_LIST = 'MESSAGE_HISTORY_LIST',
  MESSAGE_HISTORY = 'MESSAGE_HISTORY',
}

const vsAdminApiEnhanced = vsAdminApiInjected.enhanceEndpoints({
  addTagTypes: Object.values(VsAdminSliceTags),
  endpoints: {
    accountControllerGetAll: {
      providesTags: [VsAdminSliceTags.ADMIN_USER_LIST],
    },
    accountControllerCreate: {
      invalidatesTags: [VsAdminSliceTags.ADMIN_USER_LIST],
    },
    accountControllerDelete: {
      invalidatesTags: [VsAdminSliceTags.ADMIN_USER_LIST],
    },
    permissionActionControllerFindAll: {
      providesTags: [VsAdminSliceTags.PERMISSION_ACTION_LIST],
    },
    permissionActionControllerCreate: {
      invalidatesTags: [VsAdminSliceTags.PERMISSION_ACTION_LIST],
    },
    permissionActionControllerDelete: {
      invalidatesTags: [
        VsAdminSliceTags.PERMISSION_ACTION_LIST,
        VsAdminSliceTags.PERMISSION_LIST,
      ],
    },
    permissionResourceControllerFindAll: {
      providesTags: [VsAdminSliceTags.PERMISSION_RESOURCE_LIST],
    },
    permissionResourceControllerCreate: {
      invalidatesTags: [VsAdminSliceTags.PERMISSION_RESOURCE_LIST],
    },
    permissionResourceControllerDelete: {
      invalidatesTags: [
        VsAdminSliceTags.PERMISSION_RESOURCE_LIST,
        VsAdminSliceTags.PERMISSION_LIST,
      ],
    },
    serviceServiceControllerFindAll: {
      providesTags: [VsAdminSliceTags.PERMISSION_SERVICE_LIST],
    },
    serviceServiceControllerCreate: {
      invalidatesTags: [VsAdminSliceTags.PERMISSION_SERVICE_LIST],
    },
    serviceServiceControllerDelete: {
      invalidatesTags: [VsAdminSliceTags.PERMISSION_SERVICE_LIST],
    },
    permissionControllerFindAll: {
      providesTags: [VsAdminSliceTags.PERMISSION_LIST],
    },
    permissionControllerCreate: {
      invalidatesTags: [VsAdminSliceTags.PERMISSION_LIST],
    },
    permissionControllerDelete: {
      invalidatesTags: [VsAdminSliceTags.PERMISSION_LIST],
    },
    accountControllerUserPermissionInfo: {
      providesTags: [VsAdminSliceTags.ACCOUNT_USER_PERMISSION_LIST],
    },
    accountPermissionControllerFindOne: {
      providesTags: [VsAdminSliceTags.ACCOUNT_PERMISSION_LIST],
    },
    accountPermissionControllerCreate: {
      invalidatesTags: [
        VsAdminSliceTags.ACCOUNT_PERMISSION_LIST,
        VsAdminSliceTags.ACCOUNT_USER_PERMISSION_LIST,
      ],
    },
    accountPermissionControllerDelete: {
      invalidatesTags: [
        VsAdminSliceTags.ACCOUNT_PERMISSION_LIST,
        VsAdminSliceTags.ACCOUNT_USER_PERMISSION_LIST,
      ],
    },
    accountPermissionControllerUpdate: {
      invalidatesTags: [
        VsAdminSliceTags.ACCOUNT_PERMISSION_LIST,
        VsAdminSliceTags.ACCOUNT_USER_PERMISSION_LIST,
      ],
    },
    clientPermissionControllerFindAll: {
      providesTags: [VsAdminSliceTags.CLIENT_PERMISSION_LIST],
    },
    clientPermissionControllerCreate: {
      invalidatesTags: [VsAdminSliceTags.CLIENT_PERMISSION_LIST],
    },
    clientPermissionControllerDelete: {
      invalidatesTags: [VsAdminSliceTags.CLIENT_PERMISSION_LIST],
    },
    adminClientControllerGetClients: {
      providesTags: [VsAdminSliceTags.ADMIN_CLIENT_LIST],
    },
    adminClientControllerGetClientDetails: {
      providesTags: [VsAdminSliceTags.ADMIN_CLIENT_DETAIL],
    },
    adminClientControllerRegister: {
      invalidatesTags: [VsAdminSliceTags.ADMIN_CLIENT_LIST],
    },
    adminClientControllerUpdateClient: {
      invalidatesTags: [
        VsAdminSliceTags.ADMIN_CLIENT_LIST,
        VsAdminSliceTags.ADMIN_CLIENT_DETAIL,
      ],
    },
    adminClientControllerCreateServiceAccount: {
      invalidatesTags: [VsAdminSliceTags.ADMIN_CLIENT_LIST],
    },
    adminClientControllerDeleteClient: {
      invalidatesTags: [VsAdminSliceTags.ADMIN_CLIENT_LIST],
    },
    appClientControllerGetClients: {
      providesTags: [VsAdminSliceTags.APP_CLIENT_LIST],
    },
    appClientControllerGetClientDetails: {
      providesTags: [VsAdminSliceTags.APP_CLIENT_DETAIL],
    },
    appClientControllerRegister: {
      invalidatesTags: [VsAdminSliceTags.APP_CLIENT_LIST],
    },
    appClientControllerUpdateClient: {
      invalidatesTags: [
        VsAdminSliceTags.APP_CLIENT_LIST,
        VsAdminSliceTags.APP_CLIENT_DETAIL,
      ],
    },
    appClientControllerDeleteClient: {
      invalidatesTags: [VsAdminSliceTags.APP_CLIENT_LIST],
    },
    mailTemplateControllerFindAll: {
      providesTags: [VsAdminSliceTags.MAIL_TEMPLATE_LIST],
    },
    mailTemplateControllerFindOne: {
      providesTags: [VsAdminSliceTags.MAIL_TEMPLATE_LIST],
    },
    mailTemplateComponentControllerInspectRelation: {
      providesTags: [VsAdminSliceTags.MAIL_TEMPLATE_LIST],
    },
    mailTemplateControllerCreate: {
      invalidatesTags: [VsAdminSliceTags.MAIL_TEMPLATE_LIST],
    },
    mailTemplateControllerArchived: {
      invalidatesTags: [VsAdminSliceTags.MAIL_TEMPLATE_LIST],
    },
    mailTemplateControllerDelete: {
      invalidatesTags: [VsAdminSliceTags.MAIL_TEMPLATE_LIST],
    },
    mailTemplateControllerReset: {
      invalidatesTags: [VsAdminSliceTags.MAIL_TEMPLATE_LIST],
    },
    mailTemplateControllerUpdate: {
      invalidatesTags: [VsAdminSliceTags.MAIL_TEMPLATE_LIST],
    },
    mailTemplateControllerPublished: {
      invalidatesTags: [VsAdminSliceTags.MAIL_TEMPLATE_LIST],
    },
    mailTemplateControllerUnpublish: {
      invalidatesTags: [VsAdminSliceTags.MAIL_TEMPLATE_LIST],
    },
    mailTemplateComponentControllerFindAll: {
      serializeQueryArgs: ({
        endpointName,
        queryArgs: { curPage, ...restArgs },
      }) => {
        return { endpointName, queryArgs: restArgs };
      },
      forceRefetch({ currentArg, previousArg }) {
        if (!previousArg || !currentArg) return true;

        const { curPage: currentCurPage } = currentArg;
        const { curPage: previousCurPage } = previousArg;

        return currentCurPage !== previousCurPage;
      },
      merge: (currentCache, response) => {
        if (response.meta.current_page === 1) {
          return response;
        }
        currentCache.data.push(...response.data);
      },
    },
    mailTemplateComponentControllerFindOne: {
      providesTags: [VsAdminSliceTags.MAIL_TEMPLATE_COMPONENT_DETAIL],
    },
    mailTemplateComponentControllerUpdate: {
      invalidatesTags: [VsAdminSliceTags.MAIL_TEMPLATE_COMPONENT_DETAIL],
    },
    mailTemplateContributorControllerFindAll: {
      providesTags: [VsAdminSliceTags.MAIL_CONTRIBUTOR_LIST],
    },
    mailTemplateContributorControllerManage: {
      invalidatesTags: [
        VsAdminSliceTags.MAIL_CONTRIBUTOR_LIST,
        VsAdminSliceTags.MAIL_TEMPLATE_LIST,
        VsAdminSliceTags.ACCOUNT_USER_PERMISSION_LIST,
      ],
    },
    mailRecordControllerList: {
      providesTags: [VsAdminSliceTags.MAIL_LOG_LIST],
    },
    mailRecordControllerResend: {
      invalidatesTags: [VsAdminSliceTags.MAIL_LOG_LIST],
    },
    mailBlockControllerFindAll: {
      providesTags: [VsAdminSliceTags.MAIL_BLOCK_LIST],
    },
    mailBlockControllerBlock: {
      invalidatesTags: [VsAdminSliceTags.MAIL_BLOCK_LIST],
    },
    mailBlockControllerUnblock: {
      invalidatesTags: [VsAdminSliceTags.MAIL_BLOCK_LIST],
    },
    federationSettingControllerGetClients: {
      providesTags: [VsAdminSliceTags.FEDERATION_LIST],
    },
    federationSettingControllerRegister: {
      invalidatesTags: [VsAdminSliceTags.FEDERATION_LIST],
    },
    federationSettingControllerUpdateClient: {
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        dispatch(
          vsAdminApiEnhanced.util.updateQueryData(
            'federationSettingControllerGetClientDetails',
            { id: arg.id },
            draft => {
              Object.assign(draft, arg.updateFederationSettingDto);
            }
          )
        );
        dispatch(
          vsAdminApiEnhanced.util.updateQueryData(
            'federationSettingControllerGetClients',
            undefined,
            draft => {
              const item = draft.find(item => item.id === arg.id);
              if (item) {
                const updatePayload = arg.updateFederationSettingDto;
                item.countries = updatePayload.countries;
                item.name = updatePayload.name;
                item.is_display = updatePayload.is_display;
                item.provider = updatePayload.provider as unknown as string;
                item.type = updatePayload.type;
                item.oidc_setting = {
                  client_id: updatePayload.client_id,
                  client_secret: updatePayload.client_secret,
                  issuer_discovery: updatePayload.issuer_discovery,
                  grant_types: updatePayload.grant_types,
                  response_types: updatePayload.response_types,
                };
              }
            }
          )
        );
      },
    },
    federationSettingControllerDeleteClient: {
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        dispatch(
          vsAdminApiEnhanced.util.updateQueryData(
            'federationSettingControllerGetClients',
            undefined,
            draft => {
              const targetIndex = draft.findIndex(d => d.id === arg.id);
              if (targetIndex > 0) {
                draft.splice(targetIndex, 1);
              }
            }
          )
        );
      },
    },
    federationSettingControllerResetSorting: {
      invalidatesTags: [VsAdminSliceTags.FEDERATION_LIST],
    },
    federationSettingControllerSorting: {
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        dispatch(
          vsAdminApiEnhanced.util.updateQueryData(
            'federationSettingControllerGetClients',
            undefined,
            draft => {
              draft = arg.body
                .toSorted((a, b) => a.sort_position - b.sort_position)
                .map(sort =>
                  draft.find(fed => fed.id === sort.federation_setting_id)
                )
                .filter(
                  (e): e is ListFederationOidcSettingVo => e !== undefined
                );
            }
          )
        );
      },
    },
    appClientFederationControllerFindAll: {
      providesTags: [VsAdminSliceTags.APP_CLIENT_FEDERATION_LIST],
    },
    appClientFederationControllerCreate: {
      invalidatesTags: [VsAdminSliceTags.APP_CLIENT_FEDERATION_LIST],
    },
    appClientFederationControllerDelete: {
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        dispatch(
          vsAdminApiEnhanced.util.updateQueryData(
            'appClientFederationControllerFindAll',
            { appClientSettingId: arg.appClientSettingId },
            draft => {
              const targetIndex = draft.findIndex(
                d =>
                  d.federation_setting_id ===
                  arg.deleteAppClientFederationDto.federation_setting_id
              );
              if (targetIndex > 0) {
                draft.splice(targetIndex, 1);
              }
            }
          )
        );
      },
    },
    appClientFederationControllerUpdate: {
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        dispatch(
          vsAdminApiEnhanced.util.updateQueryData(
            'appClientFederationControllerFindAll',
            { appClientSettingId: arg.appClientSettingId },
            draft => {
              const updatePayload = arg.updateAppClientFederationDto;
              const item = draft.find(
                item =>
                  item.federation_setting_id ===
                  updatePayload.federation_setting_id
              );
              if (item) {
                item.countries = updatePayload.countries;
              }
            }
          )
        );
      },
    },
    appClientFederationControllerResetSorting: {
      invalidatesTags: [VsAdminSliceTags.APP_CLIENT_FEDERATION_LIST],
    },
    appClientFederationControllerSorting: {
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled;
        dispatch(
          vsAdminApiEnhanced.util.updateQueryData(
            'appClientFederationControllerFindAll',
            { appClientSettingId: arg.appClientSettingId },
            draft => {
              draft = arg.body
                .toSorted((a, b) => a.sort_position - b.sort_position)
                .map(sort =>
                  draft.find(
                    fed =>
                      fed.federation_setting_id === sort.federation_setting_id
                  )
                )
                .filter((e): e is AppClientFederationVo => e !== undefined);
            }
          )
        );
      },
    },
    messageTemplateControllerGetMessageTemplates: {
      providesTags: [VsAdminSliceTags.MESSAGE_TEMPLATE_LIST],
    },
    messageTemplateControllerGetMessageTemplatesByCode: {
      providesTags: [VsAdminSliceTags.MESSAGE_TEMPLATE_LIST],
    },
    messageTemplateControllerDeleteMessageTemplate: {
      invalidatesTags: [VsAdminSliceTags.MESSAGE_TEMPLATE_LIST],
    },
    messageTemplateControllerCreateMessageTemplate: {
      invalidatesTags: [VsAdminSliceTags.MESSAGE_TEMPLATE_LIST],
    },
    messageTemplateControllerUpdateMessageTemplate: {
      invalidatesTags: [VsAdminSliceTags.MESSAGE_TEMPLATE_LIST],
    },

    messageHistoryControllerGetMessageHistories: {
      providesTags: [VsAdminSliceTags.MESSAGE_HISTORY_LIST],
    },
    messageHistoryControllerSendMessage: {
      invalidatesTags: [
        VsAdminSliceTags.MESSAGE_HISTORY_LIST,
        VsAdminSliceTags.MESSAGE_HISTORY,
      ],
    },
    messageHistoryControllerDeleteMessage: {
      invalidatesTags: [VsAdminSliceTags.MESSAGE_HISTORY_LIST],
    },

    messageHistoryControllerGetMessageHistory: {
      providesTags: [VsAdminSliceTags.MESSAGE_HISTORY],
    },
    messageHistoryControllerUpdateMessageHistory: {
      invalidatesTags: [VsAdminSliceTags.MESSAGE_HISTORY],
    },
  },
});

export { vsAdminApiEnhanced as vsAdminApiSlice };
export * from './vsAdminApiInjected';
