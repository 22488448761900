import { vsAuthAdminApiBase as api } from './vsAuthAdminApiBase';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getToken: build.mutation<RespGetToken, ArgsGetToken>({
      query: ({ body }) => {
        const formBody = new URLSearchParams();
        formBody.append('grant_type', 'client_credentials');
        Object.entries(body).forEach(([key, val]) =>
          formBody.append(key, Array.isArray(val) ? val.join(' ') : val)
        );
        return {
          method: 'POST',
          url: `/auth/v1/oidc/token`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formBody,
        };
      },
    }),
  }),
});
export { injectedRtkApi as vsAuthAdminApiInjected };
export type ArgsGetToken = {
  body: {
    client_id: string;
    client_secret: string;
    scope: string[];
  };
};
export type RespGetToken = {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
};

export const { useGetTokenMutation } = injectedRtkApi;
