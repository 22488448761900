import { vsSubscriptionApiSlice } from '@react-mono/rtk/slices/api-vs-subscription';
import { combineSlices } from '@reduxjs/toolkit';
import { vsDMAdminApiSlice } from '@vs/rtk/slices/apiDmAdmin';
import { vsAccount2ApiSlice } from '@vs/rtk/slices/apiVsAccount2';
import { vsAdminApiSlice } from '@vs/rtk/slices/apiVsAdmin';
import { vsAuthApiSlice } from '@vs/rtk/slices/apiVsAuth';
import { vsAuthAdminApiSlice } from '@vs/rtk/slices/apiVsAuthAdmin';
import { vsCommonApiSlice } from '@vs/rtk/slices/apiVsCommon';
import { vsEntityApiSlice } from '@vs/rtk/slices/apiVsEntity';
import { generalStateSlice } from '@vs/rtk/slices/genericGlobal';

import { globalSlice } from './slices/global';

export const rootReducer = combineSlices(
  generalStateSlice,
  globalSlice,
  vsAccount2ApiSlice,
  vsEntityApiSlice,
  vsAdminApiSlice,
  vsCommonApiSlice,
  vsAuthAdminApiSlice,
  vsSubscriptionApiSlice,
  vsDMAdminApiSlice,
  vsAuthApiSlice
);
