import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ArgsInitSliceMeta,
  ReferenceOfMeta,
  dynamicGraphqlBaseQuery,
} from '@vs/rtk/utils';
import { getEnvConfig } from '@vs/utils/envConfig';

const referenceOfMeta: ReferenceOfMeta = {
  _deployEnv: undefined,
  _baseUrl: undefined,
  _getTokenFunc: undefined,
};

export function initGraphqlSliceMeta({
  deployEnv,
  getTokenFunc,
}: ArgsInitSliceMeta) {
  referenceOfMeta._deployEnv = deployEnv;
  referenceOfMeta._baseUrl = getEnvConfig({ deployEnv }).apiEndpoint.dmAdmin;
  referenceOfMeta._getTokenFunc = getTokenFunc as () => Promise<string | null>;
}

export const vsDMAdminApiSlice = createApi({
  reducerPath: 'vsDMAdminApiSlice',
  baseQuery: dynamicGraphqlBaseQuery(referenceOfMeta),
  endpoints: () => ({}),
  tagTypes: [],
});
