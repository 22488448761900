import Popover from '@mui/material/Popover';
import { useOidcUserProfile, useSignOut } from '@vs/oidc-client';
import { PortalAvatarUserProfileCard } from '@vs/ui/portal/avatar-user-profile-card';
import { PortalButton } from '@vs/ui/portal/button';
import { defaultAvatarUrl } from '@vs/utils/constants';
import { useState } from 'react';
import { Img } from 'react-image';

import { env } from '../constants/env';

export default function AvatarMenu() {
  const signout = useSignOut();
  const userProfile = useOidcUserProfile();
  const avatarUrl = `${userProfile?.picture?.replace('_48x48', `_${150}x${150}`)}`;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Img
        src={[avatarUrl, defaultAvatarUrl]}
        alt={userProfile?.name}
        width={38}
        height={38}
        className="cursor-pointer"
        onClick={handleClick}
      />
      <Popover
        disableAutoFocus
        className="avatar-menu-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="w-[320px] rounded-xl">
          <PortalAvatarUserProfileCard
            title={'Account'}
            name={userProfile?.name ?? ''}
            avatarUrl={avatarUrl}
            email={userProfile?.email}
            link={{ href: env.appUrl.accountPortal, label: 'Manage Account' }}
          />
          <div className="flex justify-end py-1 px-6">
            <PortalButton
              onClick={() => signout()}
              label="Sign out"
              icon="logout"
            />
          </div>
        </div>
      </Popover>
    </>
  );
}
