import { initSentry } from '@vs/sentry-lib';
import '@vs/utils/styles';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import App from './app/app';
import './styles.css';

initSentry({
  tracesSampleRate: 0,
  replaysSessionSampleRate: 0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
