import { PortalSelect } from '@vs/ui/portal/select';
import { memo } from 'react';

import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { ManagementTypes, setSelectedManagement } from '../redux/slices/global';

const options: { value: ManagementTypes; label: string }[] = [
  { value: 'dm', label: 'Device Management' },
  { value: 'core', label: 'Core services' },
  {
    value: 'system',
    label: 'System services',
  },
];

function ManagementSelector() {
  const dispatch = useAppDispatch();
  const selectedManagement = useAppSelector(s => s.global.selectedManagement);

  return (
    <PortalSelect
      className="me-4"
      options={options}
      styleVariant="default"
      required
      value={selectedManagement}
      onChange={option => dispatch(setSelectedManagement(option.value))}
    />
  );
}

export default memo(ManagementSelector);
