import type * as Types from './types/operations';
import { vsDMAdminApiSlice } from './vsApiBase';

export const CapabilityKeyFragmentDoc = `
    fragment CapabilityKey on CapabilityKey {
  id
  name
  description
  updatedAt
  createdAt
}
    `;
export const DeviceModelFragmentDoc = `
    fragment DeviceModel on DeviceModel {
  id
  modelName
  osVersion
  displayName
  regExp
  note
  updatedAt
  createdAt
}
    `;
export const CapabilityVersionFragmentDoc = `
    fragment CapabilityVersion on CapabilityVersion {
  id
  fwVersion
  updatedAt
  createdAt
}
    `;
export const CustomDeviceCapabilityFragmentDoc = `
    fragment CustomDeviceCapability on CustomDeviceCapability {
  id
  note
  capability
  capabilityKey
  capabilityVersion
  updatedAt
  createdAt
}
    `;
const CustomDeviceModelsDocument = `
    query CustomDeviceModels($page: Int, $limit: Int, $search: SearchCustomDeviceModels, $sort: String) {
  CustomDeviceModels(page: $page, limit: $limit, search: $search, sort: $sort) {
    docs {
      id
      modelName
      osVersion
      displayName
      regExp
      note
      updatedAt
      createdAt
    }
    totalDocs
    totalPages
    page
    limit
    hasNextPage
    hasPrevPage
    nextPage
    prevPage
  }
}
    `;
const DeviceModelsDocument = `
    query DeviceModels($page: Int, $limit: Int, $sort: String) {
  DeviceModels(page: $page, limit: $limit, sort: $sort) {
    docs {
      id
      modelName
      osVersion
      displayName
      regExp
      note
      updatedAt
      createdAt
    }
    totalDocs
    totalPages
    page
    limit
    hasNextPage
    hasPrevPage
    nextPage
    prevPage
  }
}
    `;
const DeviceModelDocument = `
    query DeviceModel($id: String!) {
  DeviceModel(id: $id) {
    id
    modelName
    osVersion
    displayName
    regExp
    note
    updatedAt
    createdAt
  }
}
    `;
const CreateDeviceModelDocument = `
    mutation CreateDeviceModel($data: mutationDeviceModelInput!) {
  createDeviceModel(data: $data) {
    id
    modelName
    osVersion
    displayName
    regExp
    note
    updatedAt
    createdAt
  }
}
    `;
const UpdateDeviceModelDocument = `
    mutation UpdateDeviceModel($id: String!, $data: mutationDeviceModelUpdateInput!) {
  updateDeviceModel(id: $id, data: $data) {
    id
    modelName
    osVersion
    displayName
    regExp
    note
    updatedAt
    createdAt
  }
}
    `;
const DeleteDeviceModelDocument = `
    mutation DeleteDeviceModel($id: String!) {
  deleteDeviceModel(id: $id) {
    id
  }
}
    `;
const CustomCapabilityKeysDocument = `
    query CustomCapabilityKeys($page: Int, $limit: Int, $search: SearchCustomCapabilityKeys) {
  CustomCapabilityKeys(page: $page, limit: $limit, search: $search) {
    docs {
      ...CapabilityKey
    }
    totalDocs
    totalPages
    page
    limit
    hasNextPage
    hasPrevPage
    nextPage
    prevPage
  }
}
    ${CapabilityKeyFragmentDoc}`;
const CreateCapabilityKeyDocument = `
    mutation CreateCapabilityKey($data: mutationCapabilityKeyInput!) {
  createCapabilityKey(data: $data) {
    ...CapabilityKey
  }
}
    ${CapabilityKeyFragmentDoc}`;
const UpdateCapabilityKeyDocument = `
    mutation UpdateCapabilityKey($id: String!, $data: mutationCapabilityKeyUpdateInput!) {
  updateCapabilityKey(id: $id, data: $data) {
    ...CapabilityKey
  }
}
    ${CapabilityKeyFragmentDoc}`;
const CustomCapabilityVersionsDocument = `
    query CustomCapabilityVersions($page: Int, $limit: Int, $search: SearchCustomCapabilityVersions, $filter: FilterCustomCapabilityVersions, $sort: String) {
  CustomCapabilityVersions(
    page: $page
    limit: $limit
    search: $search
    filter: $filter
    sort: $sort
  ) {
    docs {
      id
      deviceModel
      fwVersion
      updatedAt
      createdAt
    }
    totalDocs
    totalPages
    page
    limit
    hasNextPage
    hasPrevPage
    nextPage
    prevPage
  }
}
    `;
const CapabilityVersionsDocument = `
    query CapabilityVersions($page: Int, $limit: Int, $where: CapabilityVersion_where) {
  CapabilityVersions(page: $page, limit: $limit, where: $where) {
    docs {
      ...CapabilityVersion
    }
    totalDocs
    totalPages
    page
    limit
    hasNextPage
    hasPrevPage
    nextPage
    prevPage
  }
}
    ${CapabilityVersionFragmentDoc}
${DeviceModelFragmentDoc}`;
const DeleteCapabilityKeyDocument = `
    mutation DeleteCapabilityKey($id: String!) {
  deleteCapabilityKey(id: $id) {
    id
  }
}
    `;
const CustomDeviceCapabilitiesDocument = `
    query CustomDeviceCapabilities($page: Int, $limit: Int, $search: SearchCustomDeviceCapabilities, $filter: FilterCustomDeviceCapabilities, $sort: String) {
  CustomDeviceCapabilities(
    page: $page
    limit: $limit
    search: $search
    filter: $filter
    sort: $sort
  ) {
    docs {
      ...CustomDeviceCapability
    }
    totalDocs
    totalPages
    page
    limit
    hasNextPage
    hasPrevPage
    nextPage
    prevPage
  }
}
    ${CustomDeviceCapabilityFragmentDoc}`;
const UpdateDeviceCapabilityDocument = `
    mutation UpdateDeviceCapability($id: String!, $data: mutationDeviceCapabilityUpdateInput!) {
  updateDeviceCapability(id: $id, data: $data) {
    id
    note
    capability
    updatedAt
    createdAt
  }
}
    `;
const CreateDeviceCapabilityDocument = `
    mutation CreateDeviceCapability($data: mutationDeviceCapabilityInput!) {
  createDeviceCapability(data: $data) {
    id
    note
    capability
    updatedAt
    createdAt
    capabilityKey {
      ...CapabilityKey
    }
    capabilityVersion {
      ...CapabilityVersion
    }
  }
}
    ${CapabilityKeyFragmentDoc}
${CapabilityVersionFragmentDoc}
${DeviceModelFragmentDoc}`;
const DeleteDeviceCapabilityDocument = `
    mutation DeleteDeviceCapability($id: String!) {
  deleteDeviceCapability(id: $id) {
    id
  }
}
    `;
const CreateCapabilityVersionDocument = `
    mutation CreateCapabilityVersion($data: mutationCapabilityVersionInput!) {
  createCapabilityVersion(data: $data) {
    id
    fwVersion
    updatedAt
    createdAt
    deviceModel {
      ...DeviceModel
    }
  }
}
    ${DeviceModelFragmentDoc}`;
const DeleteCapabilityVersionDocument = `
    mutation DeleteCapabilityVersion($id: String!) {
  deleteCapabilityVersion(id: $id) {
    id
  }
}
    `;
const CustomCreateCapabilityKeyDocument = `
    mutation CustomCreateCapabilityKey($data: DataCustomCreateCapabilityKey!) {
  CustomCreateCapabilityKey(data: $data) {
    ...CapabilityKey
  }
}
    ${CapabilityKeyFragmentDoc}`;
const CustomCreateCapabilityVersionDocument = `
    mutation CustomCreateCapabilityVersion($data: DataCustomCreateCapabilityVersion!) {
  CustomCreateCapabilityVersion(data: $data) {
    ...CapabilityVersion
  }
}
    ${CapabilityVersionFragmentDoc}`;

const injectedRtkApi = vsDMAdminApiSlice.injectEndpoints({
  endpoints: build => ({
    CustomDeviceModels: build.query<
      Types.CustomDeviceModelsQuery,
      Types.CustomDeviceModelsQueryVariables | void
    >({
      query: variables => ({ document: CustomDeviceModelsDocument, variables }),
    }),
    DeviceModels: build.query<
      Types.DeviceModelsQuery,
      Types.DeviceModelsQueryVariables | void
    >({
      query: variables => ({ document: DeviceModelsDocument, variables }),
    }),
    DeviceModel: build.query<
      Types.DeviceModelQuery,
      Types.DeviceModelQueryVariables
    >({
      query: variables => ({ document: DeviceModelDocument, variables }),
    }),
    CreateDeviceModel: build.mutation<
      Types.CreateDeviceModelMutation,
      Types.CreateDeviceModelMutationVariables
    >({
      query: variables => ({ document: CreateDeviceModelDocument, variables }),
    }),
    UpdateDeviceModel: build.mutation<
      Types.UpdateDeviceModelMutation,
      Types.UpdateDeviceModelMutationVariables
    >({
      query: variables => ({ document: UpdateDeviceModelDocument, variables }),
    }),
    DeleteDeviceModel: build.mutation<
      Types.DeleteDeviceModelMutation,
      Types.DeleteDeviceModelMutationVariables
    >({
      query: variables => ({ document: DeleteDeviceModelDocument, variables }),
    }),
    CustomCapabilityKeys: build.query<
      Types.CustomCapabilityKeysQuery,
      Types.CustomCapabilityKeysQueryVariables | void
    >({
      query: variables => ({
        document: CustomCapabilityKeysDocument,
        variables,
      }),
    }),
    CreateCapabilityKey: build.mutation<
      Types.CreateCapabilityKeyMutation,
      Types.CreateCapabilityKeyMutationVariables
    >({
      query: variables => ({
        document: CreateCapabilityKeyDocument,
        variables,
      }),
    }),
    UpdateCapabilityKey: build.mutation<
      Types.UpdateCapabilityKeyMutation,
      Types.UpdateCapabilityKeyMutationVariables
    >({
      query: variables => ({
        document: UpdateCapabilityKeyDocument,
        variables,
      }),
    }),
    CustomCapabilityVersions: build.query<
      Types.CustomCapabilityVersionsQuery,
      Types.CustomCapabilityVersionsQueryVariables | void
    >({
      query: variables => ({
        document: CustomCapabilityVersionsDocument,
        variables,
      }),
    }),
    CapabilityVersions: build.query<
      Types.CapabilityVersionsQuery,
      Types.CapabilityVersionsQueryVariables | void
    >({
      query: variables => ({ document: CapabilityVersionsDocument, variables }),
    }),
    DeleteCapabilityKey: build.mutation<
      Types.DeleteCapabilityKeyMutation,
      Types.DeleteCapabilityKeyMutationVariables
    >({
      query: variables => ({
        document: DeleteCapabilityKeyDocument,
        variables,
      }),
    }),
    CustomDeviceCapabilities: build.query<
      Types.CustomDeviceCapabilitiesQuery,
      Types.CustomDeviceCapabilitiesQueryVariables | void
    >({
      query: variables => ({
        document: CustomDeviceCapabilitiesDocument,
        variables,
      }),
    }),
    UpdateDeviceCapability: build.mutation<
      Types.UpdateDeviceCapabilityMutation,
      Types.UpdateDeviceCapabilityMutationVariables
    >({
      query: variables => ({
        document: UpdateDeviceCapabilityDocument,
        variables,
      }),
    }),
    CreateDeviceCapability: build.mutation<
      Types.CreateDeviceCapabilityMutation,
      Types.CreateDeviceCapabilityMutationVariables
    >({
      query: variables => ({
        document: CreateDeviceCapabilityDocument,
        variables,
      }),
    }),
    DeleteDeviceCapability: build.mutation<
      Types.DeleteDeviceCapabilityMutation,
      Types.DeleteDeviceCapabilityMutationVariables
    >({
      query: variables => ({
        document: DeleteDeviceCapabilityDocument,
        variables,
      }),
    }),
    CreateCapabilityVersion: build.mutation<
      Types.CreateCapabilityVersionMutation,
      Types.CreateCapabilityVersionMutationVariables
    >({
      query: variables => ({
        document: CreateCapabilityVersionDocument,
        variables,
      }),
    }),
    DeleteCapabilityVersion: build.mutation<
      Types.DeleteCapabilityVersionMutation,
      Types.DeleteCapabilityVersionMutationVariables
    >({
      query: variables => ({
        document: DeleteCapabilityVersionDocument,
        variables,
      }),
    }),
    CustomCreateCapabilityKey: build.mutation<
      Types.CustomCreateCapabilityKeyMutation,
      Types.CustomCreateCapabilityKeyMutationVariables
    >({
      query: variables => ({
        document: CustomCreateCapabilityKeyDocument,
        variables,
      }),
    }),
    CustomCreateCapabilityVersion: build.mutation<
      Types.CustomCreateCapabilityVersionMutation,
      Types.CustomCreateCapabilityVersionMutationVariables
    >({
      query: variables => ({
        document: CustomCreateCapabilityVersionDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useCustomDeviceModelsQuery,
  useLazyCustomDeviceModelsQuery,
  useDeviceModelsQuery,
  useLazyDeviceModelsQuery,
  useDeviceModelQuery,
  useLazyDeviceModelQuery,
  useCreateDeviceModelMutation,
  useUpdateDeviceModelMutation,
  useDeleteDeviceModelMutation,
  useCustomCapabilityKeysQuery,
  useLazyCustomCapabilityKeysQuery,
  useCreateCapabilityKeyMutation,
  useUpdateCapabilityKeyMutation,
  useCustomCapabilityVersionsQuery,
  useLazyCustomCapabilityVersionsQuery,
  useCapabilityVersionsQuery,
  useLazyCapabilityVersionsQuery,
  useDeleteCapabilityKeyMutation,
  useCustomDeviceCapabilitiesQuery,
  useLazyCustomDeviceCapabilitiesQuery,
  useUpdateDeviceCapabilityMutation,
  useCreateDeviceCapabilityMutation,
  useDeleteDeviceCapabilityMutation,
  useCreateCapabilityVersionMutation,
  useDeleteCapabilityVersionMutation,
  useCustomCreateCapabilityKeyMutation,
  useCustomCreateCapabilityVersionMutation,
} = injectedRtkApi;
