import { setOpenDrawer } from '@vs/rtk/slices/genericGlobal';
import { PortalHeader, PortalHeaderAppSubTitle } from '@vs/ui/portal/header';
import { memo } from 'react';

import ManagementSelector from '../../components/ManagementSelector';
import AvatarMenu from '../../components/avatar-menu';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

function AppHeader() {
  const dispatch = useAppDispatch();
  const openDrawer = useAppSelector(s => s.general.openDrawer);

  return (
    <PortalHeader
      subLogo={<PortalHeaderAppSubTitle>{'Admin'}</PortalHeaderAppSubTitle>}
      shouldElevateOnScroll
      shouldFixed
      showMobileBurgerIcon
      onBurgerIconClick={() => dispatch(setOpenDrawer(!openDrawer))}
      avatarMenu={
        <div className="flex items-center">
          <ManagementSelector />
          <AvatarMenu />
        </div>
      }
    />
  );
}

export default memo(AppHeader);
