import { MenuItemInterface } from '@vs/ui/portal/menu';
import { DeployEnv } from '@vs/utils/envConfig';

import { deployEnv, isLocalDevMode } from '..';
import { ManagementTypes } from '../../redux/slices/global';
import corePaths from './corePaths';
import dmPaths from './dmPaths';
import systemPaths from './systemPaths';

type LazyView =
  | React.LazyExoticComponent<() => JSX.Element>
  | React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;

export type Path = MenuItemInterface<{
  path: string;
  View?: LazyView;
  activeEnv?: DeployEnv[];
}>;

const getActivePaths = (paths: Path[]): Path[] => {
  return paths
    .filter(
      ({ activeEnv }) =>
        !activeEnv || activeEnv.includes(deployEnv) || isLocalDevMode
    )
    .map(path => ({
      ...path,
      childViews: path.childViews ? getActivePaths(path.childViews) : undefined,
    }));
};

const flatPaths = (paths: Path[], parentPath: Path['path']): Path[] => {
  const result: Path[] = [];

  for (const path of paths) {
    const pathForNavigation = `${parentPath}${path.path}`;

    result.push({
      ...path,
      path: pathForNavigation,
    });

    if (path.childViews) {
      result.push(...flatPaths(path.childViews, pathForNavigation));
    }
  }

  return result;
};

type PathConfigs = {
  [key in ManagementTypes]?: Path[];
};

const pathConfigs: PathConfigs = {
  core: corePaths,
  system: systemPaths,
  dm: dmPaths,
};

export const getPathConfigs = (
  managementType: ManagementTypes,
  { flat }: { flat?: boolean } = {}
): Path[] => {
  const paths = getActivePaths(pathConfigs[managementType] ?? []);

  if (!flat) return paths;

  return flatPaths(paths, '');
};
