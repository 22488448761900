import {
  initSliceMeta as initSubscriptionSliceMeta,
  vsSubscriptionApiSlice,
} from '@react-mono/rtk/slices/api-vs-subscription';
import { configureStore } from '@reduxjs/toolkit';
import {
  initGraphqlSliceMeta as initDMAdminSliceMeta,
  vsDMAdminApiSlice,
} from '@vs/rtk/slices/apiDmAdmin';
import {
  initSliceMeta as initAccount2SliceMeta,
  vsAccount2ApiSlice,
} from '@vs/rtk/slices/apiVsAccount2';
import {
  initSliceMeta as initAdminSliceMeta,
  vsAdminApiSlice,
} from '@vs/rtk/slices/apiVsAdmin';
import {
  initSliceMeta as initAuthSliceMeta,
  vsAuthApiSlice,
} from '@vs/rtk/slices/apiVsAuth';
import {
  initSliceMeta as initAuthAdminSliceMeta,
  vsAuthAdminApiSlice,
} from '@vs/rtk/slices/apiVsAuthAdmin';
import {
  initSliceMeta as initCommonSliceMeta,
  vsCommonApiSlice,
} from '@vs/rtk/slices/apiVsCommon';
import {
  initSliceMeta as initEntitySliceMeta,
  vsEntityApiSlice,
} from '@vs/rtk/slices/apiVsEntity';

import { deployEnv, isLocalDevMode } from '../constants';
import getUserAccessToken from '../utils/getUserAccessToken';
import { rootReducer } from './reducer';

const meta = {
  deployEnv,
  getTokenFunc: getUserAccessToken,
};
initAuthSliceMeta(meta);
initCommonSliceMeta(meta);
initAccount2SliceMeta(meta);
initEntitySliceMeta(meta);
initAdminSliceMeta(meta);
initDMAdminSliceMeta(meta);
initAuthAdminSliceMeta(meta);
initSubscriptionSliceMeta(meta);

export const store = configureStore({
  devTools: isLocalDevMode,
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      vsAccount2ApiSlice.middleware,
      vsEntityApiSlice.middleware,
      vsAdminApiSlice.middleware,
      vsCommonApiSlice.middleware,
      vsAuthAdminApiSlice.middleware,
      vsSubscriptionApiSlice.middleware,
      vsDMAdminApiSlice.middleware,
      vsAuthApiSlice.middleware,
    ]),
});

if (isLocalDevMode && import.meta.hot) {
  import.meta.hot.accept('./reducer.ts', module =>
    store.replaceReducer(module?.rootReducer)
  );
}

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
