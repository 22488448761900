import LockPersonIcon from '@mui/icons-material/LockPerson';
import { lazy } from 'react';

import { Path } from '.';

const dmPaths: Path[] = [
  {
    path: '/capability',
    name: 'Capability',
    icon: <LockPersonIcon />,
    View: lazy(
      () =>
        import(
          '../../views/dm-capability' /* webpackChunkName: "view_device-management" */
        )
    ),
    childViews: [
      {
        name: 'Model Detail',
        hide: true,
        path: '/:modelId',
        View: lazy(
          () =>
            import(
              '../../views/dm-capability/ModelsDetails' /* webpackChunkName: "view_entity_signup_detail" */
            )
        ),
      },
    ],
  },
];

export default dmPaths;
