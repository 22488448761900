import { PortalFooterContainer } from '@vs/ui/portal/footer';
import { getEnvConfig } from '@vs/utils/envConfig';

import { deployEnv } from '../../constants';

export default function AppFooter() {
  const {
    appUrl: { legalDoc },
  } = getEnvConfig({ deployEnv });
  return (
    <PortalFooterContainer>
      <a
        href={`${legalDoc}/privacy-policy`}
        target="_blank"
        rel="noreferrer"
        className="hover:underline"
      >{`Privacy Policy`}</a>
      <a
        href={`${legalDoc}/terms-of-use`}
        target="_blank"
        rel="noreferrer"
        className="hover:underline"
      >{`Terms of Use`}</a>
    </PortalFooterContainer>
  );
}
